import { Action, createReducer, on } from '@ngrx/store';
import { updateGifts, updateGiftsFails, updateReceivedGifts, updateSentGifts } from './gifts.actions';
import { IGiftsState, initialGiftsState } from './gifts.state';

const reducer = createReducer(
  initialGiftsState,
  on(updateGifts, (state, { gifts }) => ({ ...state, gifts: { isLoaded: true, items: gifts } })),
  on(updateGiftsFails, (state) => ({ ...state, gifts: { ...state.gifts, isLoaded: true } })),
  on(updateReceivedGifts, (state, { receivedGifts }) => ({ ...state, receivedGifts })),
  on(updateSentGifts, (state, { sentGifts }) => ({ ...state, sentGifts })),
);

export const giftsReducer = (state: IGiftsState, action: Action) => reducer(state, action);
