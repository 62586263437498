/* eslint-disable @typescript-eslint/no-shadow */
import { createAction } from '@ngrx/store';

export enum ESpinnerActions {
  launchSpinner= '[Spinner] Running',
  haltSpinner= '[Spinner] Stopped',
}

export const launchSpinner = createAction(ESpinnerActions.launchSpinner);
export const haltSpinner = createAction(ESpinnerActions.haltSpinner);
