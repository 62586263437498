/* eslint-disable @typescript-eslint/member-ordering */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  deleteMyMediaPhoto,
  deleteMyMediaPhotoSuccess,
  deleteMyMediaVideo,
  deleteMyMediaVideoSuccess,
  editMediaPhoto,
  loadMyMediaPhotos,
  loadMyMediaVideos,
  loadMyMediaVideosSuccess,
  updateMediaPhotos, updateMediaVideos,
  uploadMediaPhoto, uploadMediaVideo
} from './media.actions';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { MediaService } from './media.service';
import { of } from 'rxjs';
import { appNotifierError, appNotifierSuccess } from '../app-notifier/app-notifier.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../app.state';
import { haltSpinner, launchSpinner } from '../spinner/spinner.actions';

@Injectable()
export class MediaEffects {
  constructor(
    private actions$: Actions,
    private mediaService: MediaService,
    private store: Store<IAppState>
  ) { }

  uploadMediaPhoto$ = createEffect(() => this.actions$.pipe(
    ofType(uploadMediaPhoto),
    tap(() => this.store.dispatch(launchSpinner())),
    mergeMap(({ blobPhoto }) => this.mediaService.uploadPhoto(blobPhoto).pipe(
      switchMap((_photos) => [
        haltSpinner(),
        loadMyMediaPhotos(),
        appNotifierSuccess('Photo saved successfully')
      ]),
      catchError(() => of(
        haltSpinner(),
        appNotifierError()
      ))
    )),
  ));

  uploadMediaVideo$ = createEffect(() => this.actions$.pipe(
    ofType(uploadMediaVideo),
    tap(() => this.store.dispatch(launchSpinner())),
    mergeMap(({ fileVideo }) => this.mediaService.uploadVideo(fileVideo).pipe(
      switchMap((videos) => [
        haltSpinner(),
        updateMediaVideos({ videos }),
        appNotifierSuccess('Video saved successfully')
      ]),
      catchError((error) => of(
        haltSpinner(),
        appNotifierError(error.msg)
      ))
    )),
  ));

  loadMyMediaPhotos$ = createEffect(() => this.actions$.pipe(
    ofType(loadMyMediaPhotos),
    switchMap(() => this.mediaService.getMyPhotos().pipe(
      map(({ items }) => updateMediaPhotos({ photos: items })),
      catchError(() => of(appNotifierError()))
    ))
  ));

  deleteMyMediaPhoto$ = createEffect(() => this.actions$.pipe(
    ofType(deleteMyMediaPhoto),
    mergeMap(({ id }) => this.mediaService.removePhoto(id).pipe(
      switchMap((photoId) => [
        deleteMyMediaPhotoSuccess({ id: photoId }),
        appNotifierSuccess('Photo removed successfully')
      ]),
      catchError(() => of(appNotifierError()))
    ))
  ));

  loadMyMediaVideos$ = createEffect(() => this.actions$.pipe(
    ofType(loadMyMediaVideos),
    switchMap(() => this.mediaService.getMyVideos().pipe(
      map((videos) => loadMyMediaVideosSuccess({ videos })),
      catchError(() => of(appNotifierError()))
    ))
  ));

  deleteMyMediaVideo$ = createEffect(() => this.actions$.pipe(
    ofType(deleteMyMediaVideo),
    mergeMap(({ id }) => this.mediaService.removeVideo(id).pipe(
      switchMap((videoId) => [
        deleteMyMediaVideoSuccess({ id: videoId }),
        appNotifierSuccess('Video removed successfully')
      ]),
      catchError(() => of(appNotifierError()))
    ))
  ));

  editMediaPhoto$ = createEffect(() => this.actions$.pipe(
    ofType(editMediaPhoto),
    mergeMap(({ file, name }) => this.mediaService.editPhoto(file, name).pipe(
      switchMap(() => [
        loadMyMediaPhotos(),
        appNotifierSuccess('Photo changed successfully')
      ]),
      catchError(() => of(appNotifierError()))
    )),
  ));
}
