import { createAction, props } from '@ngrx/store';
import { IMedia } from './media.state';

export const uploadMediaPhoto = createAction(
  '[Media] Upload photo to the gallery',
  props<{ blobPhoto: Blob }>()
);

export const uploadMediaVideo = createAction(
  '[Media] Upload video to the gallery',
  props<{ fileVideo: File }>()
);

export const updateMediaPhotos = createAction(
  '[Media] Update photos in gallery',
  props<{ photos: IMedia[] }>()
);

export const updateMediaVideos = createAction(
  '[Media] Update videos in gallery',
  props<{ videos: IMedia[] }>()
);

export const loadMyMediaPhotos = createAction(
  '[Media] Load my photos to gallery',
);

export const deleteMyMediaPhoto = createAction(
  '[Media] Delete my photo from gallery',
  props<{ id: string }>()
);

export const deleteMyMediaPhotoSuccess = createAction(
  '[Media] My photo has been deleted successfully',
  props<{ id: string }>()
);

export const loadMyMediaVideos = createAction(
  '[Media] Load my videos to gallery',
);

export const loadMyMediaVideosSuccess = createAction(
  '[Media] My videos loaded successfully',
  props<{ videos: IMedia[] }>()
);

export const deleteMyMediaVideo = createAction(
  '[Media] Delete my video from gallery',
  props<{ id: string }>()
);

export const deleteMyMediaVideoSuccess = createAction(
  '[Media] My video has been deleted successfully',
  props<{ id: string }>()
);

export const editMediaPhoto = createAction(
  '[Media] Edit photo to the gallery',
  props<{ file: Blob; name: string }>()
);
