import { createAction, props } from '@ngrx/store';
import { IPaymentHistory, ISubscription, IUserModel, IVirtualPayment } from './user.state';
import { IFavourites, ISuggestions } from '../../shared/models/IUser';
import { IProfileVerification } from '../profile/profile.state';


export const updateUser = createAction(
  '[User] Update User',
  props<{ user: IUserModel }>()
);

export const resetUser = createAction('[User] Reset User');

export const updatedAt = createAction(
  '[User] Updated At',
  props<{ updatedAt: number }>()
);

export const updateProfilePhoto = createAction(
  '[User] Update profile photo',
  props<{ profilePhoto: string }>()
);

export const uploadProfilePhoto = createAction(
  '[User] Upload profile photo to the photo service',
  props<{ blobPhoto: Blob }>()
);

export const updateProfileCoverPhoto = createAction(
  '[User] Update profile cover photo',
  props<{ profileCoverPhoto: string }>()
);

export const uploadProfileCoverPhoto = createAction(
  '[User] Upload profile cover photo to the photo service',
  props<{ blobPhoto: Blob }>()
);

export const getAllPaymentHistory = createAction('[User] Get All Payment history');

export const getPaymentHistory = createAction('[User] Get Payment history');

export const updatePaymentHistory = createAction(
  '[User] Update Payment history',
  props<{ history: IPaymentHistory[] }>()
);

export const getVirtualPurchaseHistory = createAction('[User] Get Virtual Payment history');

export const updateVirtualPurchaseHistory = createAction(
  '[User] Update Virtual Payment history',
  props<{ history }>()
);

export const updateUserFavourites = createAction(
  '[User] Update User favourites',
  props<{ favourites }>()
);

export const updateUserBlockeds = createAction(
  '[User] Update User blockeds',
  props<{ blockeds }>()
);

export const updateUserSuggestions = createAction(
  '[User] Update User suggestions',
  props<{ suggestions }>()
);

export const getUserFavourites = createAction('[User] Get User favourites');
export const getUserBlockeds = createAction('[User] Get User blockeds');
export const getUserSubscription = createAction('[User] Get User subscription');
export const getUserSuggestions = createAction('[User] Get User suggestions');

export const updateUserSubscription = createAction(
  '[User] Update User subscription',
  props<{ subscription }>()
);

export const updateIncognito = createAction(
  '[User] Update incognito',
  props<{ incognitoStatus: boolean }>()
);

export const toggleUserStatus = createAction(
  '[User] Toggle Status'
);

export const updateUserStatus = createAction(
  '[User] Update User Status',
  props<{ isOnline: boolean }>()
);

export const getUserStatus = createAction(
  '[User] Get Status'
);

export const loadProfileVerification = createAction(
  '[User] Load User Profile verification',
);

export const loadProfileVerificationSuccess = createAction(
  '[User API] Profile verification loaded successfully',
  props<{ verified: IProfileVerification }>()
);

export const updateProfileVerificationPersonLink = createAction(
  '[Profile] Update person link in profile verification',
  props<{ personLink: string }>()
);
