import { UserEffects } from './user/user.effects';
import { GiftsEffects } from './gifts/gifts.effects';
import { AppNotifierEffects } from './app-notifier/app-notifier.effects';
import { MediaEffects } from './media/media.effects';
import { NotificationEffects } from './notification/notification.effects';
import { ProfileEffects } from './profile/profile.effects';
import { ChatMessagesEffects } from './chat-messages/chat-messages.effects';

export const appEffects = [
  UserEffects,
  GiftsEffects,
  AppNotifierEffects,
  MediaEffects,
  NotificationEffects,
  ProfileEffects,
  ChatMessagesEffects,
];
