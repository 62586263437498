<div class="form-row">
  <label>{{ title }}</label>
  <div class="jq-selectbox jqselect dropdown  {{isSuccessBtn ? 'has-success-input' : ''}}">
    <input type="text" class="t-inp"
      [(ngModel)]="selectedAddress"
      (ngModelChange)="changeAddress($event)"

      [placeholder]="'Select ' + title"
      [disabled]="isDisabled"
      [readonly]="readonly"

      [class.success-input]="isSuccessBtn"
    />
    <div
      *ngIf="addresses.length > 0"
      class="jq-selectbox__dropdown dropdown-container"
    >
      <ul class="dropdown__ul">
        <li *ngFor="let address of addresses" (click)="onSelect(address)">
          {{address.placeName}}
        </li>
      </ul>
    </div>
  </div>
</div>
