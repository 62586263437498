<div class="loader app-root-loader" id="app_root_loader">
  <div class="loader-spinner"></div>
  <div class="loader-append flex-center">
    <div class="svg-logo-wrapper flex-center">
      <svg
        width="32"
        height="32"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6874 86.3643C6.12212 77.3492 0.129838 64.5909 0.00218635 50.4781V50.477C0.00218635 50.4639 0.00174801 50.4519 0.00174801 50.4387C0.00174801 50.4365 0.00174801 50.4365 0.00174801 50.4343C0.00174801 50.4234 0.00174759 50.4103 0.00130967 50.3982C0.00130967 50.396 0.00130967 50.3949 0.00130967 50.3916C0.00130967 50.3807 0.00130967 50.3686 0.00130967 50.3577C0.00130967 50.3544 0.00130967 50.3522 0.00130967 50.35C0.000871761 50.3391 0.000871334 50.3281 0.000871334 50.3172C0.000871334 50.3139 0.000871334 50.3106 0.000871334 50.3073C0.000871334 50.2975 0.000871334 50.2876 0.000871334 50.2767C0.000871334 50.2734 0.000871334 50.269 0.000871334 50.2657C0.000433422 50.2559 0.00043834 50.246 0.00043834 50.2373C0.00043834 50.2329 0.00043834 50.2285 0.00043834 50.2241C0.00043834 50.2143 0.00043834 50.2055 0.00043834 50.1957C0.00043834 50.1913 0.00043834 50.1869 0.00043834 50.1825C0.00043834 50.1738 0.00043834 50.1639 0.00043834 50.1552C0.00043834 50.1497 0 50.1464 0 50.1409C0 50.1322 0 50.1234 0 50.1136C0 50.1092 0 50.1048 0 50.0993C0 50.0906 0 50.0796 0 50.0709C0 50.0665 0 50.0632 0 50.0588C0 50.0446 0 50.0315 0 50.0172C0 22.4003 22.3652 4.13953e-06 49.9823 4.13953e-06C60.2436 4.13953e-06 69.8098 3.09495 77.7403 8.41777C72.6255 6.00597 66.8757 4.89806 61.0592 4.89806C58.4886 4.89806 55.905 5.11482 53.3574 5.53084C30.6447 9.07793 12.6965 28.0362 8.13914 49.8782C5.63505 62.0521 7.09516 76.7999 15.6874 86.3643Z"
          fill="url(#paint0_linear_5_42)"
        />
        <path
          d="M59.6169 90.1501C55.7025 90.1497 51.7637 89.6393 47.9316 88.6259C41.0791 86.8181 34.6792 83.3388 29.7049 78.2956C25.7045 74.226 22.8877 69.4598 21.1135 64.2769C26.3652 74.9213 37.3225 82.1905 49.9828 82.1905C49.992 82.1905 50.0011 82.1905 50.0103 82.1905C50.0144 82.1905 50.0194 82.1905 50.0235 82.1905C50.0286 82.1905 50.0337 82.1905 50.0388 82.1905C50.0438 82.1905 50.0499 82.1905 50.055 82.1905C50.0581 82.1905 50.0611 82.1905 50.0642 82.1905C50.0713 82.1905 50.0794 82.1905 50.0865 82.1905C50.0865 82.1905 50.0865 82.1905 50.0875 82.1905C52.6927 82.1821 55.1983 81.8706 57.6348 81.2874C73.6705 77.3903 86.4709 65.0433 90.3314 48.9049C93.3218 36.2783 90.7492 19.6866 78.5394 12.7655C79.8608 13.4252 81.1476 14.157 82.3999 14.9549C93.7742 22.365 99.9655 35.3391 99.9655 50.0169C99.9655 60.8351 95.9657 70.8513 88.9043 78.1905C88.8931 78.2016 88.8819 78.2127 88.8718 78.2242C81.1273 86.2292 70.4656 90.1497 59.62 90.1501C59.6189 90.1501 59.6179 90.1501 59.6169 90.1501Z"
          fill="url(#paint1_linear_5_42)"
        />
        <path
          d="M59.6167 90.1501C55.7018 90.1501 51.7652 89.6392 47.9315 88.6258C51.7643 89.6392 55.7026 90.1497 59.6167 90.1501ZM50.0387 82.1905C50.0438 82.1905 50.0498 82.1905 50.0553 82.1905C50.0498 82.1905 50.0442 82.1905 50.0387 82.1905ZM50.0647 82.1905C50.0723 82.1905 50.0787 82.1905 50.0864 82.1905C50.0791 82.1905 50.0719 82.1905 50.0647 82.1905ZM50.0876 82.1905C67.8142 82.1344 82.192 67.7561 82.192 50.0172C82.192 32.2082 67.7571 17.8091 49.9826 17.8091C37.6349 17.8091 26.8882 24.7651 21.4966 34.9914C26.2262 22.2264 36.87 13.1479 49.9136 9.91328C53.3316 9.05601 56.8552 8.62503 60.3731 8.62503C68.1328 8.62503 75.8674 10.7203 82.4 14.9552C81.1475 14.1566 79.8606 13.4253 78.5391 12.7654C90.7489 19.687 93.3224 36.278 90.3316 48.9047C86.4707 65.0435 73.6704 77.3903 57.6354 81.2873C55.1981 81.8706 52.693 82.182 50.0876 82.1905Z"
          fill="black"
        />
        <path
          d="M59.6163 90.15C55.702 90.15 51.7648 89.639 47.9318 88.6256C51.7648 89.639 55.7031 90.1495 59.6163 90.15ZM50.0385 82.1904C50.0437 82.1904 50.0499 82.1904 50.0551 82.1904C50.0499 82.1904 50.0447 82.1904 50.0385 82.1904ZM50.0645 82.1904C50.0728 82.1904 50.0791 82.1904 50.0864 82.1904C50.0791 82.1904 50.0718 82.1904 50.0645 82.1904ZM50.0874 82.1904C67.8138 82.1343 82.1926 67.756 82.1926 50.0171C82.1926 32.2085 67.7575 17.8088 49.9822 17.8088C37.635 17.8088 26.8882 24.7643 21.4966 34.9913C26.2262 22.2263 36.8701 13.1475 49.9134 9.9125C53.3318 9.05608 56.8554 8.62475 60.3727 8.62475C68.1326 8.62475 75.8674 10.7199 82.3999 14.9551C81.1476 14.156 79.8609 13.4246 78.5398 12.7651C90.7494 19.6863 93.3229 36.278 90.3317 48.9044C86.4705 65.0429 73.6701 77.3901 57.6358 81.2872C55.1978 81.8704 52.6931 82.1819 50.0874 82.1904Z"
          fill="url(#paint2_linear_5_42)"
        />
        <path
          d="M49.9826 100C22.5185 100 0.248703 77.8502 0.00237136 50.478C0.130004 64.5904 6.12237 77.349 15.6876 86.3641C7.09536 76.7997 5.63524 62.0522 8.1394 49.8781C12.6967 28.0359 30.6449 9.07812 53.3572 5.52992C31.8621 9.07812 16.1394 26.0176 15.2703 48.1389C14.3317 71.4434 28.87 88.0004 48.696 92.4518C52.1119 93.2176 55.9175 93.6388 59.8188 93.6388C69.0267 93.6388 78.7608 91.292 85.1135 85.6C76.069 94.5046 63.6521 100 49.9826 100ZM0.00237136 50.4767C0.00237136 50.4635 0.00194758 50.4516 0.00194758 50.438C0.00194758 50.4512 0.00237136 50.4639 0.00237136 50.4767ZM0.00194758 50.4337C0.00194758 50.4206 0.00194924 50.4116 0.00152379 50.398C0.00194924 50.4095 0.00194758 50.4227 0.00194758 50.4337ZM0.00152379 50.3916C0.00152379 50.3797 0.00152379 50.3686 0.00152379 50.3572C0.00152379 50.3682 0.00152379 50.3801 0.00152379 50.3916ZM0.00152379 50.3495C0.00109835 50.3376 0.00109586 50.3291 0.00109586 50.3172C0.00109586 50.3278 0.00109835 50.3393 0.00152379 50.3495ZM0.00109586 50.3074C0.00109586 50.2955 0.00109586 50.2882 0.00109586 50.2763C0.00109586 50.287 0.00109586 50.2968 0.00109586 50.3074ZM0.00109586 50.2657C0.000670415 50.2555 0.000672077 50.2466 0.000672077 50.2368C0.000672077 50.2461 0.000670415 50.2559 0.00109586 50.2657ZM0.000672077 50.2236C0.000672077 50.2138 0.000672077 50.2057 0.000672077 50.1955C0.000672077 50.2053 0.000672077 50.2142 0.000672077 50.2236ZM0.000672077 50.1823C0.000672077 50.1725 0.000672077 50.1649 0.000672077 50.1547C0.000672077 50.1632 0.000672077 50.1738 0.000672077 50.1823ZM0.000244141 50.141C0.000244141 50.1308 0.000244141 50.124 0.000244141 50.1138C0.000244141 50.1227 0.000244141 50.1321 0.000244141 50.141ZM0.000244141 50.0993C0.000244141 50.0908 0.000244141 50.0794 0.000244141 50.0708C0.000244141 50.0798 0.000244141 50.0908 0.000244141 50.0993ZM0.000244141 50.0585C0.000244141 50.0449 0.000244141 50.0304 0.000244141 50.0172C0.000244141 50.0313 0.000244141 50.0445 0.000244141 50.0585Z"
          fill="black"
        />
        <path
          d="M49.9824 99.9872C22.5186 99.9872 0.248698 77.8373 0.00236792 50.4656C0.130095 64.5771 6.12244 77.3362 15.6876 86.3513C7.09546 76.787 5.63529 62.0395 8.13944 49.8651C12.6968 28.0232 30.645 9.06568 53.357 5.51712C31.8622 9.06568 16.1395 26.0044 15.2703 48.1264C14.3318 71.4302 28.87 87.9876 48.6962 92.439C52.1116 93.2048 55.9179 93.626 59.8187 93.626C69.0269 93.626 78.7613 91.2792 85.1137 85.5872C76.0689 94.4918 63.6526 99.9872 49.9824 99.9872ZM0.00236792 50.4634C0.00236792 50.4507 0.00194317 50.439 0.00194317 50.4253C0.00194317 50.438 0.00236792 50.4507 0.00236792 50.4634ZM0.00194317 50.421C0.00194317 50.4072 0.00194275 50.3987 0.00151841 50.3849C0.00194275 50.3966 0.00194317 50.4093 0.00194317 50.421ZM0.00151841 50.3786C0.00151841 50.3669 0.00151841 50.3563 0.00151841 50.3446C0.00151841 50.3552 0.00151841 50.3669 0.00151841 50.3786ZM0.00151841 50.3361C0.00109407 50.3245 0.00109365 50.316 0.00109365 50.3043C0.00109365 50.3149 0.00109407 50.3266 0.00151841 50.3361ZM0.00109365 50.2948C0.00109365 50.2831 0.00109365 50.2757 0.00109365 50.264C0.00109365 50.2746 0.00109365 50.2842 0.00109365 50.2948ZM0.000668897 50.1696C0.000668897 50.16 0.000668897 50.1516 0.000668897 50.142C0.000668897 50.1505 0.000668897 50.1611 0.000668897 50.1696ZM0.000244141 50.1282C0.000244141 50.1176 0.000244141 50.1112 0.000244141 50.1006C0.000244141 50.1102 0.000244141 50.1197 0.000244141 50.1282ZM0.000244141 50.0455C0.000244141 50.0317 0.000244141 50.0179 0.000244141 50.0041C0.000244141 50.0189 0.000244141 50.0317 0.000244141 50.0455Z"
          fill="url(#paint3_linear_5_42)"
        />
        <path
          d="M49.9824 78.6423C34.1563 78.6423 21.3564 65.8088 21.3564 50.0172C21.3564 34.1907 34.1563 21.3556 49.9824 21.3556C65.8097 21.3556 78.6096 34.1907 78.6096 50.0172C78.6096 65.8088 65.8097 78.6423 49.9824 78.6423ZM49.9824 23.096C39.3395 23.096 30.1567 29.2518 25.8091 38.2257C37.288 52.1734 53.4617 62.0871 71.8953 65.5987C75.0265 61.1826 76.8695 55.8262 76.8695 50.0172C76.8695 35.1301 64.8355 23.096 49.9824 23.096Z"
          fill="black"
        />
        <path
          d="M49.9821 78.6426C34.1563 78.6426 21.3564 65.8092 21.3564 50.0169C21.3564 34.1909 34.1563 21.3555 49.9821 21.3555C65.81 21.3555 78.6098 34.1909 78.6098 50.0169C78.6098 65.8092 65.81 78.6426 49.9821 78.6426Z"
          fill="url(#paint4_radial_5_42)"
        />
        <path
          d="M94.2617 31.7564C95.5125 37.9823 95.3394 44.6252 94.0175 50.4699C92.6957 56.4508 90.0171 62.0522 86.3655 66.9907C86.4706 66.9907 86.5748 66.9907 86.679 66.9907C89.3223 66.9907 91.8967 66.8516 94.4702 66.6082C96.8356 61.4604 98.0175 55.7918 98.0175 50.0172C98.0175 43.6177 96.7999 37.3569 94.2617 31.7564Z"
          fill="url(#paint5_linear_5_42)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.1135 64.2768C26.3652 74.9213 37.3225 82.1905 49.9828 82.1905C52.6257 82.1905 55.1648 81.8782 57.6348 81.2874C73.6705 77.3903 86.4709 65.0433 90.3314 48.9049C93.3218 36.2783 90.7482 19.6866 78.5394 12.7655C79.443 13.2168 80.3141 13.7037 81.1832 14.2261C92.2088 21.2509 94.9573 37.3914 92.1397 50.0504C88.6258 65.8087 75.2694 78.8874 59.5834 82.9913C43.8616 87.0955 26.9574 79.8944 21.1135 64.2768Z"
          fill="url(#paint6_linear_5_42)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M78.5394 12.7654C89.2529 19.408 89.7388 35.6521 87.2007 46.574C85.7055 52.9737 82.9224 59.0603 79.0964 63.7909C74.992 72.4177 67.1997 78.9218 57.7395 81.2507C73.7397 77.3209 86.4709 65.0087 90.3314 48.9048C93.3219 36.2783 90.7482 19.6865 78.5394 12.7654Z"
          fill="url(#paint7_linear_5_42)"
        />
        <path
          d="M49.9825 1.94684C35.5821 1.94684 22.678 8.27786 13.8445 18.3302C14.5745 20.0337 15.3403 21.7393 16.1742 23.4083C25.1481 11.9996 38.1569 3.86091 52.7661 2.0515C52.7661 2.01703 52.7661 2.01704 52.7661 2.01704C51.8268 1.98173 50.9223 1.94684 49.9825 1.94684Z"
          fill="url(#paint8_linear_5_42)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.13911 49.8784C12.6965 28.0365 30.6446 9.07796 53.357 5.53009C61.6688 4.17406 70.365 4.93985 77.7397 8.41771C70.295 4.10513 61.4954 2.88696 53.0102 3.9651C30.0179 6.85165 11.0951 26.0181 6.50455 48.4168C3.75662 61.7391 5.77365 76.5221 15.6873 86.3643C7.09511 76.7998 5.63503 62.0525 8.13911 49.8784Z"
          fill="url(#paint9_linear_5_42)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.6875 86.364C4.41711 72.5566 9.98321 47.0951 17.7752 34.6778C23.166 19.3389 36.4875 8.31238 53.3569 5.52976C30.6449 9.07832 12.6967 28.0358 8.13934 49.8778C5.63519 62.0521 7.09535 76.7996 15.6875 86.364Z"
          fill="url(#paint10_linear_5_42)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5_42"
            x1="-17"
            y1="90.3333"
            x2="51.69"
            y2="-18.2504"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0882B9" />
            <stop offset="0.34375" stop-color="#5DB7BD" />
            <stop offset="0.710807" stop-color="#D5EDCC" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5_42"
            x1="3.87045"
            y1="93.7065"
            x2="61.883"
            y2="-10.1032"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0882B9" />
            <stop offset="0.34375" stop-color="#5DB7BD" />
            <stop offset="0.710807" stop-color="#D5EDCC" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5_42"
            x1="110.609"
            y1="65.9647"
            x2="61.961"
            y2="34.8774"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C00081" />
            <stop offset="1" stop-color="#E9387E" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_5_42"
            x1="78.4888"
            y1="55.511"
            x2="0.0300149"
            y2="28.334"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B8CFC" />
            <stop offset="1" stop-color="#40C5F1" />
          </linearGradient>
          <radialGradient
            id="paint4_radial_5_42"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(39.9999 58.5714) rotate(-50.0074) scale(48.2928 48.2643)"
          >
            <stop stop-color="#D5EDCC" />
            <stop offset="0.647431" stop-color="#5DB7BD" />
            <stop offset="1" stop-color="#0882B9" />
          </radialGradient>
          <linearGradient
            id="paint5_linear_5_42"
            x1="103.63"
            y1="50.9889"
            x2="85.7938"
            y2="50.4859"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E3F2D0" />
            <stop offset="1" stop-color="#F8FFEF" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_5_42"
            x1="-2.50297"
            y1="75.1908"
            x2="81.5936"
            y2="8.82248"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0882B9" />
            <stop offset="0.34375" stop-color="#5DB7BD" />
            <stop offset="1" stop-color="#D5EDCC" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_5_42"
            x1="62.6518"
            y1="56.01"
            x2="94.5367"
            y2="62.1757"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.548506" stop-color="#AB0073" />
            <stop offset="1" stop-color="#E9387E" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_5_42"
            x1="71.5134"
            y1="13.6615"
            x2="13.28"
            y2="4.65676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E3F2D0" />
            <stop offset="1" stop-color="#F8FFEF" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_5_42"
            x1="-18.3334"
            y1="76"
            x2="75.0948"
            y2="12.1632"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0882B9" />
            <stop offset="0.34375" stop-color="#5DB7BD" />
            <stop offset="1" stop-color="#D5EDCC" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_5_42"
            x1="49.7516"
            y1="48.3075"
            x2="-68.1176"
            y2="34.3381"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B8CFC" />
            <stop offset="1" stop-color="#40C5F1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="loader-text">{{ loadingText }}</div>
  </div>
</div>
