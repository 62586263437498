import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { MainComponent } from './modules/main/main.component';
import { BasePageComponent } from './modules/pages/base-page.component';
import { IsLoginedResolver } from './modules/profile-page/resolvers/profile.resolver';
import { AuthorisationGuard } from './shared/guards';

const pages = [
  'about', 'contact',
  'terms-and-conditions',
  'privacy-policy',
  'cookies-policy'
];

const matcherPages = (url: UrlSegment[]) =>
  url.length === 1 && pages.includes(url[0].path) ? { consumed: url, posParams: { path: url[0] }} : null;

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    resolve: { user: IsLoginedResolver }
  },
  {
    path: 'verifyemail',
    pathMatch: 'full',
    redirectTo: 'page/verifyemail',
  },
  {
    path: 'reset-password/:code',
    pathMatch: 'full',
    redirectTo: 'page/reset-password/:code'
  },
  {
    path: 'change-email/:code',
    pathMatch: 'full',
    redirectTo: 'page/change-email/:code'
  },
  {
    matcher: matcherPages,
    component: BasePageComponent
  },
  {
    path: 'page',
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login-page/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/registration-page/registration-page.module').then(m => m.RegistrationPageModule)
  },
  {
    path: 'my-profile',
    canActivate: [AuthorisationGuard],
    canLoad: [AuthorisationGuard],
    loadChildren: () => import('./modules/profile-page/profile-page.module').then(m => m.ProfilePageRootModule)
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [IsLoginedResolver],
  exports: [RouterModule]
})
export class AppRoutingModule {}
