import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPhotos } from '../interfaces';
import { IFavourites, ISuggestions } from '../interfaces';

@Injectable()
export class MyProfileService {

  constructor(private http: HttpClient) { }

  getMyProfile() {
    return this.http.get('/api/profile/me');
  }

  getMyLikes(): Observable<IPhotos[]> {
    return this.http.get<IPhotos[]>('/api/profile/me/likes');
  }

  getMyVisitors() {
    return this.http.get('/api/profile/me/visitors');
  }

  getMyFavourites(): Observable<IFavourites[]> {
    return this.http.get<IFavourites[]>('/api/profile/me/favourites');
  }

  getMyBlockedUsers(): Observable<IFavourites[]> {
    return this.http.get<IFavourites[]>('/api/profile/me/blocked');
  }

  getMySuggestions(): Observable<ISuggestions[]> {
    return this.http.get<ISuggestions[]>('/api/profiles/me/suggestions');
  }

  acceptSuggestions({ _id }) {
    return this.http.post(`/api/profiles/${ _id }/accepted`, { type: 'favourite' });
  }

  declineFavorite({ _id }) {
    return this.http.post(`/api/profiles/${ _id }/decline`, null);
  }

  getFavouritedBy() {
    return this.http.get('/api/profile/me/favourited');
  }

  toggleFavourite(id: string) {
    return this.http.post(`/api/profiles/${ id }/favourite`, null);
  }

  changeVisible(visible: boolean) {
    return this.http.post('/api/profile/me/change-visible', { visible });
  }

  changeStatus() {
    return this.http.post<{ isOnline: boolean }>(`/api/profile/me/change-status/${true}`, null);
  }

  checkStatus() {
    return this.http.get<{ isOnline: boolean }>('/api/profile/me/check-status');
  }

  // changeIncognito(status: boolean, endDate: Date | null, startDate: Date | null, incognitoMin: number) {
  //   return this.httpClient.post('/api/profile/me/change-incognito', { status, endDate, startDate, incognitoMin });
  // }

  savePersonal(data) {
    return this.http.post('/api/profile/me/personal', data);
  }

  updatePassword(data) {
    return this.http.post('/api/profile/me/password', data);
  }

  saveLocation(data) {
    return this.http.post('/api/profile/me/location', data);
  }

  saveRelationship(data) {
    return this.http.post('/api/profile/me/relationship', data);
  }

  saveProfession(data) {
    return this.http.post('/api/profile/me/profession', data);
  }

  saveHabits(data) {
    return this.http.post('/api/profile/me/habits', data);
  }

  saveLifePosition(data) {
    return this.http.post('/api/profile/me/lifeposition', data);
  }

  saveInterests(data) {
    return this.http.post('/api/profile/me/interests', data);
  }

  savePets(data) {
    return this.http.post('/api/profile/me/pets', data);
  }

  pauseAccount(email: string, password: string, reason: string) {
    return this.http.post('/api/profile/me/pause', { email, password, reason });
  }

  closeAccount(email: string, password: string, reason: string) {
    return this.http.post('/api/users/me/retire', { email, password, reason });
  }

  deleteAccount(_id: string) {
    return this.http.post('/api/users/me/try', { _id });
  }

  getMySettings() {
    return this.http.get<any>('/api/settings/me');
  }

  saveSettings(settings) {
    return this.http.post<number>('/api/settings/me', settings);
  }
}
