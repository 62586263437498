/* eslint-disable no-underscore-dangle */
import { Action, createReducer, on } from '@ngrx/store';
import {
  initialNotificationState,
  INotificationState,
} from './notification.state';
import {
  loadUserNotificationsSuccess,
  loadSummaryNotificationSuccess,
  deleteUserNotificationSuccess,
  updateUserNotification,
} from './notification.actions';

const reducer = createReducer(
  initialNotificationState,
  on(loadSummaryNotificationSuccess, (state, { summary }) => ({ ...state, summary })),
  on(loadUserNotificationsSuccess, (state, { userNotifications }) => ({ ...state, userNotifications })),
  on(updateUserNotification, (state, { notification }) => ({
    ...state, userNotifications: state.userNotifications.map(ntf =>
      notification.type === ntf.type && notification.user._id === ntf.user._id ? notification : ntf)
  })),
  on(deleteUserNotificationSuccess, (state, { date }) => ({
    ...state, userNotifications: state.userNotifications.filter(ntf => ntf.date !== date)
  })),
);

export const notificationReducer = (state: INotificationState, action: Action) => reducer(state, action);
