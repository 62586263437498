/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ContentService {
  constructor(private httpClient: HttpClient) {}

  getLatestBlogArticles() {
    return this.httpClient.get('/api/articles/latest');
  }

  getBlogArticles(page: number, sort: number) {
    return this.httpClient.get(`/api/articles?page=${  page  }&sort=${  sort}`);
  }

  getPage(slug: string) {
    return this.httpClient.get(`/api/pages/${slug}`).pipe(catchError(() => of(true)));
  }

  getHome(category) {
    return this.httpClient.get(`/api/pages/category/${category}`);
  }
}
