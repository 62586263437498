import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { appNotifierError } from '../../store/app-notifier/app-notifier.actions';
import { Store } from '@ngrx/store';
import { ActiveUserService } from '../services';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private activeUserService: ActiveUserService
  ) {}

  isBlobError(err: any) {
    return err instanceof HttpErrorResponse && err.error instanceof Blob;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          const httpResponse = event.body;
          if (httpResponse?.code === 200) {
            if (httpResponse?.pagination) {
              return event.clone({
                body: {
                  data: httpResponse.data,
                  pagination: httpResponse.pagination,
                },
              });
            }

            return event.clone({
              body: httpResponse.data,
            });
          } else {
            observableThrowError(httpResponse);
          }
        }
        return event;
      }),

      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (typeof error.error.msg !== 'undefined') {
            this.store.dispatch(appNotifierError(error.error.msg));
            return observableThrowError(error.error);
          } else if (typeof error.error.message !== 'undefined') {
            this.store.dispatch(appNotifierError(error.error.message));
            return observableThrowError(error.error);
          } else if (typeof error.error === 'string') {
            const doc = new DOMParser().parseFromString(
              error.error,
              'text/html'
            );
            const head = doc.head.getElementsByTagName('title');
            if (head.length) {
              this.store.dispatch(appNotifierError(`${head[0].outerText}`));
              return observableThrowError({ msg: head[0].outerText });
            }
            this.store.dispatch(
              appNotifierError(
                error?.statusText?.length
                  ? error.statusText
                  : 'Invalid response from server'
              )
            );
            return observableThrowError(error.error);
          } else if (this.isBlobError(error)) {
            console.log('Failed to get meida file');
            return observableThrowError(error.error);
          } else {
            this.store.dispatch(
              appNotifierError(`Internal error ${error.status}`)
            );
            return observableThrowError({
              msg: error.statusText,
              code: error.status,
              data: error.message,
            });
          }
        }

        return observableThrowError({
          msg: error?.statusText?.length
            ? error.statusText
            : 'Invalid response from server',
          data: null,
          code: 500,
          error,
        });
      })
    );
  }
}
