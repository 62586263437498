/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGetMessages } from './chat-messages.state';

@Injectable({ providedIn: 'root' })
export class ChatMessagesService {
  constructor(private httpClient: HttpClient) { }

  getMyMessages = () => this.httpClient.get('/api/messages');
  deleteMySingleMessage = (chatId: string, message_id: string, userB: string) =>
    this.httpClient.post('/api/messages/deleteSingleMessage', { chatId, message_id, userB });

  getMessages = (id: string) => this.httpClient.get<IGetMessages>(`/api/messages/${ id }`);
  deleteMessages = (userId: string, declined?: boolean) => this.httpClient.post(`/api/messages/deleteChat/${ userId }`, { declined });

  createMessage = (userId: string): Observable<string> => this.httpClient.post<string>(`/api/messages/new/${ userId }`, null);
  acceptDialog = (_id: string) => this.httpClient.post('/api/messages/accept', { _id });

  newChat = (id: string, message: string) => this.httpClient.put(`/api/messages/${ id }`, { message });
  markRead = (chatId: string, messageId: string = null) => this.httpClient.post(`/api/messages/${ chatId }/markread`, { messageId });
}
