import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IProfileVerification } from '../profile/profile.state';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) {
  }

  // getProgress() {
  //   return this.httpClient.get<any>('/api/users/me/progress');
  // }

  loadProfileVerification() {
    return this.httpClient.get<{ verified: IProfileVerification }>('/api/users/me/verification');
  }

  changeIncognito(status: boolean, endDate: Date | null, startDate: Date | null, incognitoMin: number) {
    return this.httpClient.post('/api/profile/me/change-incognito', { status, endDate, startDate, incognitoMin });
  }
}
