export const MEDIA_NODE = 'media';

export interface IMedia {
  _id: string;
  modTime: Date;
  url: string;
  caption: string;
  liked: boolean;
  likes?: Array<{
    userId: string;
    date: Date;
  }>;
  approved?: boolean;
  rejected?: boolean;
}

export interface IMediaState {
  photos: IMedia[];
  videos: IMedia[];
}

export const initialMediaState: IMediaState = {
  photos: [],
  videos: []
};
