import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-lang-switch',
  templateUrl: './lang-switch.component.html',
  styleUrls: ['./lang-switch.component.scss']
})
export class LangSwitchComponent {
  @Output() lang = new EventEmitter<string>();
  languages = {
    english: 'eng',
    dutch: 'dutch'
  };

  selectedLanguage = 'eng';

  changeLang(newLang: string = 'eng') {
    this.selectedLanguage = newLang;
    this.lang.emit(newLang);
  }
}
