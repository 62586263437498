/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { SecureImageService } from '../../services/sequre-image.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-secure-image',
  templateUrl: './secure-image.component.html',
  styleUrls: ['./secure-image.component.scss']
})
export class SecureImageComponent implements OnInit {
  @Input() set src(source: string) { this.src$.next(source); }
  @Input() useStyle: object;
  @Input() fullScreen = false;

  private src$ = new BehaviorSubject<string>(null);
  dataUrl$: Observable<string>;

  constructor(
    private secureImageService: SecureImageService
  ) { }

  ngOnInit() {
    this.dataUrl$ = this.src$.pipe(
      filter(v => !!v),
      switchMap(url => this.fullScreen ?
        this.secureImageService.loadPhotoLazy(url):
        this.secureImageService.loadCompressPhoto(url)
      )
    );
  }
}
