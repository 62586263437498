/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { token } from './user.token';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { IAppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { resetUser, updateUser } from '../../store/user/user.actions';
import {
  initialUserState,
  IUserModel,
  Register,
} from '../../store/user/user.state';
import { updateProfileVerification } from '../../store/profile/profile.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

const guest: IUserModel = initialUserState;

interface LoginForm {
  email: string;
  password: string;
  remember: boolean;
}

@Injectable({ providedIn: 'root' })
export class ActiveUserService {
  private _user: IUserModel;
  public user$: BehaviorSubject<IUserModel>;
  registerForm: FormGroup;

  get guest() {
    return !this._user._id;
  }

  get user() {
    return this._user;
  }

  set user(user: IUserModel) {
    this._user = user;
    token.set(this._user.token);
    this.user$.next(this._user);
  }

  set personal(t) {
    this.registerForm.get('personal').patchValue(t);
  }
  get personal() {
    return this.registerForm.get('personal');
  }

  set stepTwo(t) {
    this.registerForm.get('stepTwo').patchValue(t);
  }
  get stepTwo() {
    return this.registerForm.get('stepTwo');
  }

  set relationship(t) {
    this.registerForm.get('relationship').patchValue(t);
  }
  get relationship() {
    return this.registerForm.get('relationship');
  }

  set location(t) {
    this.registerForm.get('location').patchValue(t);
  }
  get location() {
    return this.registerForm.get('location');
  }

  set profilePhoto(t) {
    this.registerForm.get('profilePhoto').patchValue(t);
  }
  get profilePhoto() {
    return this.registerForm.get('profilePhoto');
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<IAppState>,
    private formBuilder: FormBuilder
  ) {
    this._user = guest;
    this.user$ = new BehaviorSubject(this._user);
    this.regFormInit();
  }

  regFormInit() {
    this.registerForm = this.formBuilder.group({
      personal: this.formBuilder.group({
        username: [
          null,
          [Validators.required, Validators.min(6), Validators.email],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{6,20}'
            ),
          ],
        ],
        terms: [true, Validators.required],
      }),
      stepTwo: this.formBuilder.group({
        gender: ['male', [Validators.required]],
        birthday: [null, Validators.required],
        name: ['', [Validators.required, Validators.minLength(3)]],
      }),
      relationship: this.formBuilder.group({
        relationshipStatus: [null, [Validators.required]],
      }),
      location: this.formBuilder.group({
        geo: this.formBuilder.group({
          lat: [null, [Validators.required]],
          lon: [null, [Validators.required]],
        }),
        country: [null, Validators.required],
        state: [{ value: null, disabled: false }, Validators.required],
        city: [{ value: null, disabled: false }, Validators.required],
        shortCode: [
          null,
          [Validators.required, Validators.pattern(/^[A-Za-z]{2}$/)],
        ],
      }),
      profilePhoto: ['', Validators.required],
    });
  }

  init() {
    return this.getToken().pipe(
      switchMap(() => this.getUser()),
      catchError((err) => {
        console.error('ERROR DURING INIT', err);
        return of(err);
        // return throwError(err);
      })
    );
  }

  getToken() {
    return this.http.get('/api/token', { responseType: 'text' });
  }

  getUser(): Observable<IUserModel> {
    return this.http.get<IUserModel>('/api/users/me').pipe(
      tap((user) => {
        this.user = user;
        this.store.dispatch(updateUser({ user }));
        this.store.dispatch(
          updateProfileVerification({ verification: user?.verified })
        );
      })
    );
  }

  login(values: LoginForm) {
    return this.http.post<IUserModel>('/api/session', values).pipe(
      tap((user) => {
        this.user = user;
        this.store.dispatch(updateUser({ user }));
        this.store.dispatch(
          updateProfileVerification({ verification: user?.verified })
        );
      })
    );
  }

  checkEmailAlreadyUse(email: string) {
    return this.http.post<any>('/api/register/checkEmailUse', { email });
  }

  logout() {
    // this.store.dispatch(resetUser());
    // token.set('');
    // this.user$.next(guest);
    // this.router.navigate(['/'], { replaceUrl: true });
    return this.http.post('/api/session/logout', null).pipe(
      switchMap(() => this.getToken()),
      // TODO remove commented code
      tap(() =>
        this.router.navigate(['/'], { replaceUrl: true }).then(() => {
          this.store.dispatch(resetUser());
          token.set('');
          this.user$.next(guest);
        })
      ),
      catchError((error) => {
        console.error('LOGOUT ERROR', error);
        return throwError(error);
      })
    );
  }

  reSendEmailConfirmLetter(email: string, password: string) {
    return this.http.post<any>('/api/verify/reSendEmailConfirmLetter', {
      email,
      password,
    });
  }

  verifyEmail(code: string) {
    return this.http.post('/api/verify/email', { code });
  }

  verifyPerson(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        accept: 'application/json',
      }),
    };
    return this.http.post(environment.cdn.verification, formData, httpOptions);
  }

  getPersonCode(): Observable<any> {
    return this.http.get('/api/verify/person/regenerate');
  }

  register(values: Register) {
    return this.http.post<any>('/api/register', values);
  }

  registerTempPhoto(file: Blob) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(environment.cdn.temporary, formData, {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        accept: 'application/json',
        isMobile: 'true',
      }),
    });
  }
}
