import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-valid',
  templateUrl: './info-valid.component.html',
  styleUrls: ['./info-valid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoValidComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) { }

  goLink() {
    this.router.navigate([this.data.linkRedirect]);
  }
}
