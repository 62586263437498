import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { ActiveUserService } from '../services/active-user.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private activeUserService: ActiveUserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Uncoment for mobile build
    if (!environment.production) {
      if (req.url.match('/api') && !req.url.match('mapbox')) {
        req = req.clone({
          url: environment.mobileUrl + req.url,
        });
      }
    }

    req = req.clone({
      setHeaders: {
        isMobile: 'true',
      },
    });

    const token = this.activeUserService.user.token;
    if (token) {
      const authReq = req.clone({
        headers: req.headers.set('x-access-token', token),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
