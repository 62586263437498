import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items, term) {
    if (!term || !items) {
      return items;
    }
    return this.filter(items, term);
  }

  filter(items, term) {
    const toCompare = term.toLowerCase();

    const checkInside = (item) => {
      for (const property in item) {
        if (item[property] === null || item[property] === undefined) {
          continue;
        }
        if (typeof item[property] === 'object') {
          if (checkInside(item[property])) {
            return true;
          }
        }
        if (item[property].toString().toLowerCase().includes(toCompare)) {
          return true;
        }
      }
      return false;
    };
    return items.filter((item) => checkInside(item));
  }
}
