<app-header
  [style.position]="'absolute'"
  [style.width]="'100%'"
  title="Gallery" [useStyle]="{'background-color': '#ebf7fd'}"
 ></app-header>

<div
  [style.margin]="0"
  [style.marginTop.px]="80"
  [style.padding]="0"
  [style.maxHeight.vh]="100"
  [style.width.vw]="100"
  mat-dialog-content
>
  <app-carousel
    (like)="onLike($event)"
    (pauseVideo)="pauseVideo()"
    [setActiveSlide]="data.mediaIdx"
    [slides]="data.mediaScope.length"
    [media]="data"
    class="black-theme"
  >
    <app-carousel-slide
      *ngFor="let media of data.mediaScope; index as i; trackBy: trackByFn"
      class="full-slide"
    >
      <ng-container *ngIf="data.type === 'photo'; else videoContent">
        <app-secure-image
          [src]="media.url"
          [useStyle]="innerPhotoStyle"
          [fullScreen]="true"
        ></app-secure-image>
      </ng-container>

      <ng-template #videoContent>
        <ng-container *ngIf="media?.url">
          <video
            #videoElement
            [style]="innerVideoStyle"
            (loadedmetadata)="getFrame($event)"
            (playing)="onPlayingVideo($event)"
            (pause)="pauseVideo()"
            class="video-bg pointer"
          >
          <!-- controls -->
          <source [src]="media.url" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <mat-icon *ngIf="!playerState" svgIcon="internal:play" (click)="playVideo(videoElement)" class="pointer"></mat-icon>
        </ng-container>
      </ng-template>

    </app-carousel-slide>
  </app-carousel>
</div>
