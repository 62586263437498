import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserServicesService {
  constructor(private httpClient: HttpClient) {
  }

  toggleFavourite(userId: string) {
    return this.httpClient.post(`/api/profiles/${  userId  }/favourite`, null);
  }

  toggleBlockUser(userId: string, reason?: string) {
    return this.httpClient.post(`/api/profiles/${  userId  }/block`, { reason });
  }

  toggleDisableNotifications(_id: string) {
    return this.httpClient.post(`/api/profiles/${  _id  }/disabled`, null);
  }
}
