/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ActiveUserService } from '../services/active-user.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private activeUserService: ActiveUserService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.activeUserService.user.token;
    if (token) {
      const clone = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return next.handle(clone);
    }
    return next.handle(req);
  }
}
