<div id="sidebar-wrapper">
  <div
    class="user-info pt-16 relative"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      *ngIf="
        user.profilePhoto && user.profilePhotoApproved;
        else noProfilePhoto
      "
      [class.active]="showCover"
      [class.active-del-cover]="showDelCover"
      class="user-photo user-cover"
    >
      <app-secure-image
        appHoverEffect
        *ngIf="user.profilePhoto"
        [src]="user.profilePhoto"
        [useStyle]="{
          'width.px': isSidebar ? 80 : 130,
          'height.px': isSidebar ? 80 : 130,
          'border-radius.px': isSidebar ? 40 : 63,
          position: 'relative',
          'overflow-y': 'hidden'
        }"
      ></app-secure-image>

      <div class="edit-photo" (click)="changePhoto()">
        <mat-icon
          class="change-photo-icon"
          svgIcon="internal:change-photo-icon"
          aria-hidden="false"
        ></mat-icon>
      </div>
    </div>

    <!-- <div class="verification-user" *ngIf="!showVerify && isSidebar"></div> -->

    <div class="user-name">{{ user.name }}</div>

    <div class="user-years-location">
      {{ user.age }} year, {{ user.location }}
    </div>

    <div class="user-incognito" *ngIf="isSidebar">
      <button
        [class.green-incognito]="user.subscription?.incognitoMin > 0"
        [class.active-incognito]="user.incognitoStatus"
        [class.unlimited]="adminAccess"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon
          class="incognito-icon"
          svgIcon="internal:invisible-icon"
          aria-hidden="false"
        ></mat-icon>
        {{ user.incognitoStatus ? "Incognito On" : "Incognito Off" }}
      </button>

      <mat-menu class="menu-incognito" #menu="matMenu">
        <div class="wrapper-incognito">
          <div class="have-incognito" *ngIf="user.subscription?.incognitoMin">
            <div class="switch-friends" (click)="$event.stopPropagation()">
              <label>
                Start off/on,&nbsp;Time left:
                <countdown
                  #cd
                  *ngIf="!adminAccess && config"
                  [config]="config"
                  (event)="handleEvent($event)"
                ></countdown>
              </label>
              <label class="switch">
                <input
                  type="checkbox"
                  [checked]="user.incognitoStatus"
                  (change)="changeVisible(user.incognitoStatus)"
                  role="switch"
                />
                <span class="slider"></span>
              </label>
            </div>
          </div>

          <div
            class="not-have-coins"
            *ngIf="user?.coins === 0 && !user.subscription?.incognitoMin"
          >
            <div class="text">
              You need coins for our service <br />
              to be invisible for other Clients
            </div>
            <button
              class="primary-round-button"
              routerLink="/my-profile/wallet"
            >
              Add coins
            </button>
          </div>

          <div
            class="not-have-coins"
            *ngIf="user?.coins !== 0 && !user.subscription?.incognitoMin"
          >
            <div class="text text-center">
              You need buy invisible <br />
              to be invisible for other Clients
            </div>
            <button
              class="primary-round-button"
              routerLink="/my-profile/wallet"
            >
              Buy invisible
            </button>
          </div>
        </div>
      </mat-menu>
    </div>

    <app-profile-progress-bar [isMyProfile]="true"></app-profile-progress-bar>
  </div>

  <div class="menu-info" *ngIf="isSidebar">
    <!-- <div class="discover" fxLayoutAlign="center center">
      <button
        mat-flat-button
        appHoverEffect
        routerLink="/new-search"
        fxLayoutAlign="center center"
      >
        <mat-icon svgIcon="internal:new-search-icon"></mat-icon>
        <span>Discover</span>
      </button>
    </div> -->

    <mat-divider></mat-divider>

    <div class="balance">
      <div class="state">
        Balance
        <mat-icon
          class="coins-icon"
          svgIcon="internal:coins-icon"
          aria-hidden="false"
        ></mat-icon>
        {{ subscription.balance ? subscription.balance : 0 }} coins
      </div>
      <a routerLink="/my-profile/wallet">Charge</a>
    </div>

    <mat-divider></mat-divider>

    <div class="sidebar-menu">
      <ul>
        <ng-container
          *ngIf="verificationWithPhoto$ | async as verificationWithPhoto"
        >
          <div
            class="verify"
            *ngIf="!verificationWithPhoto?.person || !user.profilePhoto"
          >
            <a routerLink="/my-profile/edit/settings">
              <span>Verify your <br />account</span>
              <mat-icon
                class="verify-icon"
                svgIcon="internal:verify-icon"
                aria-hidden="false"
              ></mat-icon>
            </a>
          </div>
        </ng-container>

        <!-- <li *ngIf="user.showVerify">
          <a routerLink="/my-profile/settings/verification" class="sidebar-link" routerLinkActive="active-sidebar-link">
            <mat-icon
              [style.height.px]="22"
              [style.width.px]="22"
              svgIcon="internal:verification-icon"
              aria-hidden="false"
            ></mat-icon>

            <span>Verification</span>
          </a>
        </li> -->

        <li *ngFor="let menu of menuItems">
          <a
            [routerLink]="menu.routerLink"
            class="sidebar-link"
            routerLinkActive="active-sidebar-link"
          >
            <mat-icon
              [svgIcon]="menu.svgIcon"
              [style.height.px]="menu.h"
              [style.width.px]="menu.w"
              aria-hidden="false"
            ></mat-icon>

            <span>{{ menu.title }}</span>
          </a>
          <div class="count" *ngIf="menu.count">+{{ menu.count }}</div>
        </li>

        <li class="profile-state">
          <div class="sidebar-link link" routerLinkActive="active-sidebar-link">
            <div class="title" routerLink="/my-profile/edit/settings/">
              <mat-icon
                class="new-user-icon"
                svgIcon="internal:new-user-icon"
                aria-hidden="false"
              ></mat-icon>
              <span>Profile Online</span>
            </div>
            <label (click)="$event.stopPropagation()" class="switch new-switch">
              <input
                #checkbox
                type="checkbox"
                role="switch"
                [checked]="isOnline"
                (change)="toggleStatus()"
              />
              <span class="slider"></span>
            </label>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="upgrade-account">
      <a routerLink="/my-profile/wallet">
        <span>Let's upgrade <br>your account</span>
        <mat-icon class="upgrade-icon" svgIcon="internal:upgrade-icon" aria-hidden="false"></mat-icon>
      </a>
    </div> -->

    <mat-divider></mat-divider>
    <div class="logout">
      <div
        (click)="logOut()"
        class="sidebar-link link"
        routerLinkActive="active-sidebar-link"
        [class.pointer-none]="isLogoutLoading"
      >
        <mat-icon svgIcon="internal:log-out-icon"></mat-icon>
        <span *ngIf="!isLogoutLoading">
          Log out
        </span>
        <app-loader-spinner
          *ngIf="isLogoutLoading"
          [size]="'md'"
          [colorMode]="'dark'"
        />
      </div>
    </div>
  </div>

  <ng-template #noProfilePhoto>
    <div class="no-profile-photo">
      <app-gender-icon
        [gender]="user.gender"
        [useStyle]="{ 'width.px': 130, 'height.px': 130 }"
      ></app-gender-icon>

      <div class="edit-photo" (click)="changePhoto()">
        <mat-icon
          class="change-photo-icon"
          svgIcon="internal:change-photo-icon"
          aria-hidden="false"
        ></mat-icon>
      </div>
    </div>
  </ng-template>
</div>
