<div class="content-modal">
  <div mat-dialog-content>
    <div class="profile-default-photo">
      <ng-container *ngIf="data.image; else noProfilePhoto">
        <app-secure-image
          [src]="data.image"
          [useStyle]="{
            'width.px': 60,
            'height.px': 60,
            'border-radius.%': 50
          }"
        ></app-secure-image>
      </ng-container>

      <ng-template #noProfilePhoto>
        <app-gender-icon
          *ngIf="!data.image"
          [gender]="'male'"
          [useStyle]="{
            'width.px': 60,
            'height.px': 60,
            'border-radius.%': 50
          }"
        ></app-gender-icon>
      </ng-template>
    </div>
    <h3 style="font-weight: 500" class="title-modal">
      {{ data.title }} <b>{{ data.name }}</b>
      <span *ngIf="data.favorited"> as favourite</span>?
    </h3>
  </div>

  <mat-form-field
    fxFlexFill
    class="reason"
    *ngIf="data.blocked && data.type === 'blocked'"
    appearance="fill"
  >
    <mat-label>Select block user reason</mat-label>
    <mat-select
      #formValue
      [ngModel]="selected"
      (ngModelChange)="changeDisable(formValue.value)"
      [disableOptionCentering]="true"
    >
      <mat-option
        *ngFor="let opt of blockOptions"
        [style.height.em]="1.3"
        [style.padding-top.px]="0"
        [value]="opt"
      >
        {{ opt | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="delete-options" *ngIf="data.type === 'chat'">
    <app-radio-button-group
      [radioGroupDataObjects]="deleteOptions"
      (dataObjectSelected)="onDeleteOptionSelected($event)"
    ></app-radio-button-group>

    <!-- // TODO remove debug -->
    <!-- <div class="">
      isBlockSecondUserAfterChatDelete :
      {{ blockCheckBoxFormGroup.get('isBlockSecondUserAfterChatDelete').value + '' }}
    </div> -->

    <form [formGroup]="blockCheckBoxFormGroup" class="mt-25">
      <div class="checkbox-common">
        <label class="checkbox-common-container">
          <input
            type="checkbox"
            formControlName="isBlockSecondUserAfterChatDelete"
          />
          <span class="checkbox-common-checkmark"></span>

          <span class="checkbox-common-text">Block second chat participant</span>
        </label>

        <!-- TODO fix overlay display, now it dsiplays under block-user modal -->
        <!-- <mat-icon
          class="info-icon"
          svgIcon="internal:info-icon"
          (click)="showInfo()"
        ></mat-icon> -->
      </div>
    </form>
  </div>

  <div mat-dialog-actions>
    <div
      class="btns"
      style="display: flex; flex-direction: row"
      [class.mt-top]="!data.blocked"
      *ngIf="data.type === 'blocked'"
    >
      <button class="secondary-button" mat-dialog-close>
        <span>
          {{ data.blocked ? "Don`t block " : "No" }}
        </span>
      </button>
      <button
        #blockBtn
        [disabled]="data.blocked"
        class="primary-round-button"
        style="border-radius: 6px"
        [mat-dialog-close]="{ value: true, reason: selected }"
      >
        {{ data.blocked ? "Yes block" : "Yes unblock" }}
      </button>
    </div>

    <div
      class="btns"
      style="display: flex; flex-direction: row"
      [style.margin-top.px]="15"
      *ngIf="data.type === 'favorite'"
    >
      <button class="secondary-button" mat-dialog-close>
        <span>
          {{ data.favorited ? "Don`t add" : "Don`t remove" }}
        </span>
      </button>
      <button
        class="primary-round-button"
        style="border-radius: 6px"
        [mat-dialog-close]="{ value: true }"
      >
        {{ data.favorited ? "Yes add" : "Yes remove" }}
      </button>
    </div>

    <div
      class="btns"
      style="display: flex; flex-direction: row"
      [style.margin-top.px]="15"
      *ngIf="data.type === 'chat'"
    >
      <button
        class="secondary-button"
        style="border-radius: 6px"
        mat-dialog-close
      >
        <span>No</span>
      </button>
      <!-- <button class="primary-round-button" [mat-dialog-close]="{ value: true }"> -->
      <!-- <button class="primary-round-button" [mat-dialog-close]="{ value: true, selectedDeleteOptionId: selectedDeleteOptionId }"> -->
      <button
        class="primary-round-button"
        [mat-dialog-close]="{
          isAgreedByUser: true,
          selectedDeleteOptionId: selectedDeleteOptionId,
          isBlockSecondUserAfterChatDelete :  blockCheckBoxFormGroup.get('isBlockSecondUserAfterChatDelete').value
        }"
      >
        Yes
      </button>
    </div>
  </div>
</div>
