<div fxLayout="column">
  <app-close-dialog-button mat-dialog-close></app-close-dialog-button>

  <div class="dialog-title" fxLayoutAlign="center center">
    <div class="color-icon-container" fxLayoutAlign="center center">
      <mat-icon
        svgIcon="internal:verify-icon"
        aria-hidden="false"
        color="accent"
      ></mat-icon>
    </div>
  </div>

  <div mat-dialog-content fxLayout="column" fxLayoutGap="15px" *ngIf="(subscription$ | async) as subscription">
    <mat-label class="mat-dialog-content-title">
      Your virtual gifts has sent successful
    </mat-label>

    <h4>Remain virtual gifts</h4>

    <h2 *ngIf="!subscription.adminAccess; else unlimited">{{ subscription.gifts > 0 ? subscription.gifts : subscription.balance}}
      <mat-icon *ngIf="subscription.gifts === 0"
        svgIcon="internal:coins-icon"
      ></mat-icon>
    </h2>
  </div>

  <div
    mat-dialog-actions
    fxLayout="column"
    fxLayoutGap="10px"
    class="py-20"
  >
    <button
      mat-dialog-close
      mat-flat-button
      appHoverEffect
      color="primary"
      class="primary-round-button"
      tabindex="-1"
      type="button"
    >
      Thanks
    </button>

<!--    <button-->
<!--      mat-dialog-close-->
<!--      mat-button-->
<!--      color="primary"-->
<!--      class="secondary-button"-->
<!--    >-->
<!--      No, thanks-->
<!--    </button>-->
  </div>
</div>

<ng-template #unlimited>
  <h2>Unlimited</h2>
</ng-template>
