/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadProfileById, updateProfile } from './profile.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { appNotifierError } from '../app-notifier/app-notifier.actions';
import { ProfileService } from './profile.service';
import { of } from 'rxjs';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService
  ) {
  }

  loadProfileById$ = createEffect(() => this.actions$.pipe(
    ofType(loadProfileById),
    switchMap(({ id }) => this.profileService.getProfile(id).pipe(
      map((profile) => updateProfile({ profile })),
      catchError(() => of(appNotifierError()))
    )),
  ));
}
