/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-underscore-dangle */
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IAppState } from '../../../../store/app.state';
import {
  appNotifierError,
  appNotifierInfo,
  appNotifierSuccess,
} from '../../../../store/app-notifier/app-notifier.actions';
import { BlockUserModalComponent } from '../../../../shared/modals/block-user/block-user.component';
import {
  MessagesService,
  UserServicesService,
} from '../../../../shared/services';

@Component({
  selector: 'app-min-profile-card',
  templateUrl: './min-profile-card.component.html',
  styleUrls: ['./min-profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinProfileCardComponent implements OnDestroy {
  @Input() favorites: boolean;
  @Input() isBlocked: boolean;
  @Input() visitor: boolean;
  @Input() sentRequestToFavorites: boolean;

  // TODO create interface or reuse existed
  @Input() userData: any;

  @Output() unBlockedUser = new EventEmitter<Boolean>();
  destroyed$ = new ReplaySubject(1);

  constructor(
    private messagesService: MessagesService,
    private router: Router,
    private store: Store<IAppState>,
    private userService: UserServicesService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  sendMessage() {
    this.messagesService
      .createMessage(this.userData.user._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) =>
          this.router
            .navigate(['/my-profile', 'chat', response], { replaceUrl: true })
            .then(),
        (err) => this.store.dispatch(appNotifierError(err.msg))
      );
  }

  addFavourite() {
    if (this.userData.user.visible) {
      this.dialog
        .open(BlockUserModalComponent, {
          data: {
            image: this.userData.user.profilePhoto,
            name: this.userData.user.name,
            gender: this.userData.user.gender,
            title: 'Add user',
            blocked: false,
            type: 'favorite',
            favorited: true,
          },
        })
        .afterClosed()
        .pipe(
          filter((v) => !!v),
          switchMap(() =>
            this.userService.toggleFavourite(this.userData.user._id)
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (res) => {
            // res should be boolean, true or false
            // console.log('addFavourite : res : ', res);
            // TODO debug api make response with the same response data interface, in different cases api res as true or { data: true }

            if (res) {
              this.sentRequestToFavorites = true;
              this.isBlocked = false;
              this.store.dispatch(
                appNotifierSuccess('You send request to favourites!')
              );
              this.cdr.detectChanges();
            }
          },
          (error) => this.store.dispatch(appNotifierInfo(error?.msg))
        );
    } else {
      this.store.dispatch(
        appNotifierInfo(
          !this.userData.user.visible
            ? 'This user was hidden his profile'
            : 'You are blocked by an user'
        )
      );
    }
  }

  unBlock() {
    this.dialog
      .open(BlockUserModalComponent, {
        data: {
          image: this.userData.user.profilePhoto,
          name: this.userData.user.name,
          gender: this.userData.user.gender,
          title: 'Unblock the user',
          blocked: false,
          type: 'blocked',
        },
      })
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        switchMap((e) =>
          this.userService.toggleBlockUser(this.userData.user._id, e?.reason)
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (res) => {
          this.isBlocked = false;
          this.cdr.detectChanges();
          this.unBlockedUser.emit(this.userData.user);
        },
        (err) => console.error(err)
      );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
