import { UserNotification } from '../../shared/models/user/core/notification';

export const NOTIFICATION_NODE = 'notification';

export interface ISummary {
  gifts: number;
  invites: number;
  likedPhoto: number;
  messages: number;
  other: number;
  addPhoto: number;
  friends: number;
  visitors: number;
  favourites: number;
  importantMessages: number;
}

export interface INotificationState {
  summary: ISummary;
  userNotifications: UserNotification[];
}

export const initialNotificationState: INotificationState = {
  summary: {
    gifts: 0,
    invites: 0,
    likedPhoto: 0,
    messages: 0,
    other: 0,
    addPhoto: 0,
    friends: 0,
    visitors: 0,
    favourites: 0,
    importantMessages: 0,
  },
  userNotifications: []
};
