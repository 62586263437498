import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ResetPasswordService {
  constructor(private httpClient: HttpClient) { }

  resetPassword(email: string) {
    return this.httpClient.post('/api/forgot', { email });
  }

  verifyCode(code: string) {
    return this.httpClient.get(`/api/forgot/${ code }`);
  }

  changePassword(code: string, password1: string, password2: string) {
    return this.httpClient.post(`/api/forgot/${ code }`, { password1, password2 });
  }
}
