import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(duration: number): string {
    const date = new Date(null);
    date.setSeconds(duration ?? 0); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
  }
}
