import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { withCache } from '@ngneat/cashew';
import { environment } from '../../../../environments/environment';

export interface UploadedPhoto {
  url: string;
  modTime: Date;
  format: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfilePhotoService {
  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer
  ) { }

  uploadProfilePhoto(file, photoType: 'avatar' | 'cover', previousPhoto?: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    if (previousPhoto) {
      formData.append('previousPhoto', previousPhoto);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        enctype:  'multipart/form-data',
        accept: 'application/json'
      })
    };
    return photoType === 'avatar'
      ? this.httpClient.post<UploadedPhoto>(environment.cdn.avatarUrl, formData, httpOptions)
      : this.httpClient.post<UploadedPhoto>(environment.cdn.photoCoverUrl, formData, httpOptions);
  }

  saveProfilePhoto(photoUrl: string, photoType: 'avatar' | 'cover') {
    return photoType === 'avatar'
      ? this.httpClient.post<string>('/api/profile-photo/', { url: photoUrl })
      : this.httpClient.post<string>('/api/profile-photo/cover', { url: photoUrl });
  }

  removeProfilePhoto() {
    return this.httpClient.delete<boolean>('/api/profile-photo/');
  }

  loadPhoto(photoUrl: string): Observable<any> {
    return this.httpClient.get(photoUrl, { responseType: 'blob', ...withCache() });
  }

  loadPhotoAsObjectUrl(url: string) {
    if (url) {
      return this.loadPhoto(url).pipe(
        map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)))
      );
    }
    return of(false);
  }

  changeStatus(status: string) {
    return this.httpClient.post(`/api/profile/me/change-status/${status}`, null);
  }
}
