import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from './notification.service';
import {
  loadUserNotifications,
  loadUserNotificationsSuccess,
  loadSummaryNotification,
  loadSummaryNotificationSuccess,
  deleteUserNotification,
  deleteUserNotificationSuccess,
  markNotificationsRead
} from './notification.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { appNotifierError, appNotifierSuccess } from '../app-notifier/app-notifier.actions';

@Injectable()
export class NotificationEffects {

  loadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(loadUserNotifications),
    switchMap(() => this.notificationService.getMyNotifications().pipe(
      map(userNotifications => loadUserNotificationsSuccess({ userNotifications })),
      catchError(() => EMPTY)
    ))
  ));

  loadSummaryNotification$ = createEffect(() => this.actions$.pipe(
    ofType(loadSummaryNotification),
    switchMap(() => this.notificationService.getSummaryNotification().pipe(
      map(summary => loadSummaryNotificationSuccess({ summary })),
      catchError(() => EMPTY)
    ))
  ));

  deleteUserNotification$ = createEffect(() => this.actions$.pipe(
    ofType(deleteUserNotification),
    mergeMap(({ userId, ntfType, date }) => this.notificationService.deleteMyNotification(userId, ntfType).pipe(
      switchMap(() => [
        // appNotifierSuccess('Notification removed'),
        deleteUserNotificationSuccess({ date })
      ]),
      catchError(() => of(appNotifierError()))
    ))
  ));

  markNotificationsRead$ = createEffect(() => this.actions$.pipe(
    ofType(markNotificationsRead),
    mergeMap(({ ntfMarkReadType }) => this.notificationService.markNotificationsRead(ntfMarkReadType).pipe(
      switchMap(() => [
        // appNotifierSuccess(`${ntfMarkReadType} notification marked as read`),
        loadSummaryNotification(),
        loadUserNotifications()
      ]),
      catchError(() => of(appNotifierError()))
    ))
  ));

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) { }

}
