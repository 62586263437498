import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoaderService } from './shared/services/loader.service';
// import { fromEvent } from 'rxjs';
// import { NavController } from '@ionic/angular';

// TODO remove app-root-loader after ion-router-outlet git firts content items
@Component({
  selector: 'app-root',
  template: `<ion-app>
    <app-root-loader *ngIf="!isFirstPartOfPageContentLoaded" [loadingText]="'BurningHearts'"></app-root-loader>
    <ion-router-outlet>
    </ion-router-outlet>
  </ion-app>`,
})
// export class AppComponent implements AfterViewInit {
export class AppComponent implements OnInit {
  isFirstPartOfPageContentLoaded = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private loaderService: LoaderService
    // public navCtrl: NavController
  ) {
    // Uncomment for mobile app build
    // const event = fromEvent(document, 'ionBackButton');
    // event.subscribe(async () => {
    //   this.navCtrl.back();
    // });
    if (this.deviceService.getDeviceInfo().deviceType === 'desktop') {
      window.location.href = 'https://burninghearts.online';
    }
  }

  ngOnInit(): void {
    this.loaderService
      .FirstPartOfPageContentLoaded
      .subscribe((isFirstPartOfPageContentLoaded: boolean) => {
        this.isFirstPartOfPageContentLoaded = isFirstPartOfPageContentLoaded;
      });
  }

  // ngAfterViewInit(): void {
  //   this.removeLoader();
  // }

  // private removeLoader() {
  //   const loaderRef: HTMLElement = document.getElementById('app_loader');
  //   if (!loaderRef) {
  //     console.log('no loader element');
  //     return;
  //   }

  //   loaderRef.style.display = 'none';
  // }
}
