export const environment = {
  production: true,
  apiUrl: 'https://burninghearts.online',
  mobileUrl: 'https://burninghearts.online/mobile',
  // stripeKey: 'pk_test_3Ne7XziuTKPTN9OvWTfG3B1W',
  stripeKey: 'pk_live_51AoE9eJ2c6tiF41n79IE81cc2b67ZkC3KF3YpF9b9rxCFcE61xPYsJxMrvuNffFqXmbPsBbdgrki4VmEBxZ0OEKg00TXrqKXyu',
  apiServerUrl: 'https://burninghearts.online',
  wsServerUrl: 'https://burninghearts.online',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZmFjYWZhY2E1NyIsImEiOiJjbDd2cXR5a2IwZTZjM3dscGJyNnBqZ2Z1In0.8WZCkjGaiu7l6irpI6CnSg',
    url: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  },
  ipData: {
    url: 'https://api.ipdata.co/',
  },
  cdn: {
    photoUrl: 'https://burninghearts.online/images/photos',
    avatarUrl: 'https://burninghearts.online/images/avatars',
    photoCoverUrl: 'https://burninghearts.online/images/covers',
    videoUrl: 'https://burninghearts.online/videos',
    blogUrl: 'https://burninghearts.online/images/blog',
    gifts: 'https://burninghearts.online/images/gifts',
    verification: 'https://burninghearts.online/verification',
    temporary: 'https://burninghearts.online/images/temporary',
  },
};

