import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { delay, takeUntil, repeat } from 'rxjs/operators';

@Directive({
  selector: '[appLongclick]',
})
export class LongclickDirective implements AfterViewInit {
  @Output() appLongclick = new EventEmitter();
  @Input() clickDelayMs = 500;
  mouseDown$;
  mouseUp$;
  touchStart$;
  touchEnd$;
  touchmove$;

  constructor(private hostElement: ElementRef<HTMLElement>) { }

  ngAfterViewInit() {
    this.mouseDown$ = fromEvent(this.hostElement.nativeElement, 'mousedown');
    this.mouseUp$ = fromEvent(this.hostElement.nativeElement, 'mouseup');
    this.touchStart$ = fromEvent(this.hostElement.nativeElement, 'touchstart');
    this.touchEnd$ = fromEvent(this.hostElement.nativeElement, 'touchend');
    this.touchmove$ = fromEvent(this.hostElement.nativeElement, 'touchmove');

    merge(this.mouseDown$, this.touchStart$).pipe(
      delay(this.clickDelayMs),
      takeUntil(merge(this.mouseUp$, this.touchEnd$, this.touchmove$)),
      repeat()
    ).subscribe(val => this.appLongclick.emit(val));
  }
}
