import { createAction, props } from '@ngrx/store';
import { UserNotification } from '../../shared/models/user/core/notification';
import { ISummary } from './notification.state';
import { NotificationMarkReadType, NotificationType } from '../../shared/models/shared/notification';

export const loadUserNotifications = createAction(
  '[Notification] Load user notifications'
);

export const loadUserNotificationsSuccess = createAction(
  '[Notification] User notifications has been loaded successfully',
  props<{ userNotifications: UserNotification[] }>()
);

export const loadSummaryNotification = createAction(
  '[Notification] Load summary notification'
);

export const loadSummaryNotificationSuccess = createAction(
  '[Notification] User summary notification has been loaded successfully',
  props<{ summary: ISummary }>()
);

export const updateUserNotification = createAction(
  '[Notification] Update notification',
  props<{ notification: UserNotification }>()
);

export const deleteUserNotification = createAction(
  '[Notification] Delete user notification',
  props<{ userId: string; ntfType: NotificationType; date: Date }>()
);

export const deleteUserNotificationSuccess = createAction(
  '[Notification] User notification deleted successfully',
  props<{ date: Date }>()
);

export const markNotificationsRead = createAction(
  '[Notification] Mark notifications as read',
  props<{ ntfMarkReadType: NotificationMarkReadType }>()
);
