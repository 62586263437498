import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAppState } from '../../../store/app.state';
import { selectSummaryNotification } from '../../../store/notification/notification.selectors';
import { NavController } from '@ionic/angular';
import { ActiveUserService } from '../../services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isSideMenuOpened: boolean;
  @Input() title: string = null;
  @Input() useStyle;
  @Input() onlyLogo = false;

  visitors = 0;
  likes = 0;
  messages = 0;
  favourites = 0;
  gifts = 0;
  invites = 0;
  other = 0;
  addPhoto = 0;

  destroyed$ = new ReplaySubject(1);
  lastroute: string[] = [];

  constructor(
    private store: Store<IAppState>,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public menu: MenuController,
    public navCtrl: NavController,
    public activeUserService: ActiveUserService,
    public dialog: MatDialog
  ) {}

  get total() {
    return (
      this.visitors +
      this.likes +
      this.messages +
      this.gifts +
      this.invites +
      this.favourites +
      this.addPhoto +
      this.other
    );
  }

  ngOnInit() {
    this.store
      .select(selectSummaryNotification)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((summary) => {
        this.visitors = summary.visitors;
        this.likes = summary.likedPhoto;
        this.messages = summary.messages;
        this.gifts = summary.gifts;
        this.favourites = summary.favourites;
        this.invites = summary.invites;
        this.addPhoto = summary.addPhoto;
        this.other = summary.other;
        this.cdr.detectChanges();
      });

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((route) => {
      if (route instanceof NavigationEnd) {
        if (this.menu.isOpen('sidemenu')) {
          this.menu.close('sidemenu');
        }
        this.lastroute.push(route.url);
        if (this.lastroute.length > 2) {
          this.lastroute.shift();
        }
      }
    });
  }

  goBack() {
    this.menu.isOpen('chatmenu').then((state) => {
      if (state) {
        this.menu.close('chatmenu');
      } else if (this.dialog.openDialogs.length) {
        this.dialog.closeAll();
      } else if (this.lastroute.length > 1) {
        this.navCtrl.navigateBack(this.lastroute[0]);
        this.lastroute.shift();
      } else {
        this.navCtrl.back();
      }
    });
  }

  openSideMenu() {
    this.menu.toggle('sidemenu');
  }
}
