<mat-dialog-content class="content-uploader">
  <div class="left-content">
    <div class="title-upload">Upload personal photo</div>

    <app-image-cropper-wrapper
      (onSave)="onSaveAction($event)"
      [cropConfig]="cropConfig"
    ></app-image-cropper-wrapper>
  </div>

  <div class="right-content">
    <div class="rules">
      <div class="rules-title">Rules to upload photos:</div>
      <div class="rules-item">
        Photo in good quality <br />
        File format must be JPG, PNG, GIF or BMP <br />
        Minimum size {{ cropConfig.width }}x{{ cropConfig.height }}px<br />
        Maximum size 2000x2000px (5mb)<br />
        <!-- Minimum {{ cropConfig.minSize / 1000 }}KB <br> -->
      </div>
    </div>

    <div class="banned">
      <div class="rules-title">
        What banned upload:
        <div class="banned-list">
          <div class="banned-item">
            <div class="banned-item-icon">
              <mat-icon
                class="banned-icon-1"
                svgIcon="internal:banned-icon-1"
                aria-hidden="false"
              ></mat-icon>
            </div>

            <div class="banned-item-text">Too for away</div>
          </div>

          <div class="banned-item">
            <div class="banned-item-icon">
              <mat-icon
                class="banned-icon-3"
                svgIcon="internal:banned-icon-3"
                aria-hidden="false"
              ></mat-icon>
            </div>

            <div class="banned-item-text">In profile</div>
          </div>

          <div class="banned-item">
            <div class="banned-item-icon">
              <mat-icon
                class="banned-icon-5"
                svgIcon="internal:banned-icon-5"
                aria-hidden="false"
              ></mat-icon>
            </div>

            <div class="banned-item-text">Provocative photos</div>
          </div>

          <div class="banned-item">
            <div class="banned-item-icon">
              <mat-icon
                class="banned-icon-2"
                svgIcon="internal:banned-icon-2"
                aria-hidden="false"
              ></mat-icon>
            </div>

            <div class="banned-item-text">More than 1 person</div>
          </div>

          <div class="banned-item">
            <div class="banned-item-icon">
              <mat-icon
                class="banned-icon-4"
                svgIcon="internal:banned-icon-4"
                aria-hidden="false"
              ></mat-icon>
            </div>

            <div class="banned-item-text">Erotic</div>
          </div>

          <div class="banned-item">
            <div class="banned-item-icon">
              <mat-icon
                class="banned-icon-6"
                svgIcon="internal:banned-icon-6"
                aria-hidden="false"
              ></mat-icon>
            </div>

            <div class="banned-item-text">Dark photos</div>
          </div>
        </div>
      </div>
    </div>

    <div class="instructions">
      <div class="rules-title">Crop your photo and save</div>
      <div class="instructions">
        <div class="instructions-item">
          <mat-icon
            class="instructions-icon-1"
            svgIcon="internal:instructions-icon-1"
            aria-hidden="false"
          ></mat-icon>
          Move photo that change position
        </div>

        <div class="instructions-item">
          <mat-icon
            class="instructions-icon-2"
            svgIcon="internal:instructions-icon-2"
            aria-hidden="false"
          ></mat-icon>
          Move the slider that to scale photo
        </div>
      </div>
    </div>
  </div>

  <app-close-dialog-button mat-dialog-close></app-close-dialog-button>
</mat-dialog-content>
