export const MESSAGE_SENT = 'MESSAGE_SENT';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const MESSAGE_SUMMARY_UPDATED = 'MESSAGE_SUMMARY_UPDATED';
export const MESSAGE_WAS_READ = 'MESSAGE_WAS_READ';
export const MESSAGE_READ = 'MESSAGE_READ';
export const MESSAGE_TYPING = 'MESSAGE_TYPING';
export const DELETED = 'DELETED';
export const MESSAGES_EXPIRED = 'MESSAGES_EXPIRED';
export const SEND_ERROR = 'SEND_ERROR';
export const TRANSLATE = 'TRANSLATE';
export const NOTIFICATION_SWITCH = 'NOTIFICATION_SWITCH';
export const GET_MESSAGES_USER = 'GET_MESSAGES_USER';
export const GET_MESSAGES = 'GET_MESSAGES';
export const DELETE_MANY_MESSAGES = 'DELETE_MANY_MESSAGES';
export const DELETE_SINGLE_MESSAGE = 'DELETE_SINGLE_MESSAGE';
export const MARK_CHAT_READ = 'MARK_CHAT_READ';
export const JOIN_ROOM = 'JOIN_ROOM';
export const LEAVE_ROOM = 'LEAVE_ROOM';
