/* eslint-disable @typescript-eslint/member-ordering */
import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DualSpinnerService {
  private renderer: Renderer2;
  private templateRef: ElementRef;
  private loading$ = new BehaviorSubject(false);
  isLoading$ = this.loading$.asObservable();

  constructor(rendererFactory2: RendererFactory2) {
    this.renderer = rendererFactory2.createRenderer(null, null);
  }

  startLoading() {
    this.loading$.next(true);
  }

  stopLoading() {
    this.loading$.next(false);
  }

  setLocalSpinnerTemplate(template: ElementRef) {
    this.templateRef = template;
  }

  startComponentLoading(component: ElementRef) {
    const element: HTMLElement = component.nativeElement;
    element.setAttribute('style', 'position: relative');
    element.insertAdjacentHTML('beforeend', this.templateRef.nativeElement.innerHTML);
  }

  finishComponentLoading(component: ElementRef) {
    const element: HTMLElement = component.nativeElement;
    const loadingComponentTemplate = element.querySelector('#component-spinner');
    this.renderer.removeChild(element, loadingComponentTemplate);
  }
}
