/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { sendGift, sendGiftSuccess } from '../../../store/gifts/gifts.actions';
import { IAppState } from '../../../store/app.state';
import { IGift, IReceiveGift, ISentGift } from '../../../store/gifts/gifts.state';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// import { RemainOfGiftsComponent } from '../../../modals/remain-of-gifts/remain-of-gifts.component';
// import { SendGiftComponent } from '../../../shared/modals/send-gift/send-gift.component';
import { GiftsService } from '../../../store/gifts/gifts.service';
import { catchError, switchMap } from 'rxjs/operators';
import { appNotifierError } from '../../../store/app-notifier/app-notifier.actions';
import { ActiveUserService } from '../../services';
import { RemainOfGiftsComponent } from '../../modals/remain-of-gifts/remain-of-gifts.component';
import { SendGiftComponent } from '../../modals/send-gift/send-gift.component';

@Component({
  selector: 'app-gift-item',
  templateUrl: './gift-item.component.html',
  styleUrls: ['./gift-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GiftItemComponent {
  @Input() canSend = true;
  @Input() gift: IGift | ISentGift | IReceiveGift;
  @Input() direction: 'from' | 'to' | 'none' = 'none';
  @Input() recipient: string;

  get directionLabel() {
    if (this.direction !== 'none') {
      return this.direction;
    }
    return undefined;
  }

  get directionName(): string | undefined {
    if (this.direction && this.gift[this.direction]?.name) {
      return this.gift[this.direction].name;
    }
    return undefined;
  }

  get directionLink(): string[] {
    return this.gift[this.direction]?._id
      ? ['/my-profile', 'view', this.gift[this.direction]._id, 'about']
      : ['/'];
  }

  constructor(
    private store: Store<IAppState>,
    private dialog: MatDialog,
    private giftsService: GiftsService,
    private userService: ActiveUserService
  ) { }

  sendGift() {
    if ('_id' in this.gift && !this.directionLabel) {
      this.giftsService.sendGift(this.recipient, this.gift._id).pipe(
        switchMap(() => [this.store.dispatch(sendGiftSuccess()), this.remainingGifts()]),
        catchError((err) => [this.store.dispatch(appNotifierError(err.msg))])
      ).subscribe(() => this.userService.getUser().pipe().subscribe());
    } else {
      this.dialog.open(SendGiftComponent, {
        data: { userId: this.gift[this.directionLabel]._id },
        panelClass: 'gift-send-dialog',
        minWidth: '100vw',
        maxWidth: '100vw',
        height: '100vh'
      });
    }
  }

   remainingGifts(){
    this.dialog.open(RemainOfGiftsComponent, {
      maxWidth: '100vw'
    });
   }
}
