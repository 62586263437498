import { createAction, props } from '@ngrx/store';
import { IGetMessages } from 'src/app/shared/models/messages/i-get-messages';
import { ChatMessage } from './chat-messages.state';

export const createMessageAndNavigate = createAction(
  '[Chat-messages] Create new chat message and navigate to it',
  props<{ profileId: string }>()
);

export const loadChat = createAction(
  '[Chat-messages] Load Chat',
  props<{ _id: string }>()
);

export const updateChat = createAction(
  '[Chat-messages] Update Chat',
  props<{ chat: IGetMessages }>()
);

export const changeNotifications = createAction(
  '[Chat-messages] Toggle Notifications',
  props<{disabled: boolean}>()
);

export const updateMessages = createAction(
  '[Chat-messages] Update Messages',
  props<{ messages: ChatMessage[] }>()
);
