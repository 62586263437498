import { Currency } from  '../../shared/models/IUser';
import { IProfileVerification } from '../profile/profile.state';
import { IFavourites, ISuggestions } from '../../shared/models/IUser';
import { Product } from '../../shared/models/products/product';

export const USER_NODE = 'user';

export interface IUserModel {
  _id: string;
  userID: number;
  name: string;
  email: string;
  dob: string;
  age: number;
  profilePhoto: string;
  profilePhotoApproved: boolean;
  gender: EGenderType;
  location: string;
  visible: boolean;
  incognitoStatus: boolean;
  profileCoverPhoto?: string;
  profileCoverPhotoApproved?: boolean;
  coins: number;
  incognitoHistory?: {
    startDate: Date;
    endDate: Date;
    totalTime: any;
  };
  showVerify: boolean;
  access: {
    fav: boolean;
    vis: boolean;
    add: number;
  };
  valid: boolean;
  token: string;
  paymentHistory: IPaymentHistory[];
  virtualPurchaseHistory: IVirtualPayment[];
  subscription: ISubscription;
  favourites: IFavourites[];
  blockeds: IFavourites[];
  suggestions: ISuggestions[];
  verified: IProfileVerification;
  isOnline: boolean;
  inActive: boolean;
  _csrf?: string;
  blocked: boolean; // blocked by admin
}

// eslint-disable-next-line no-shadow
export enum EGenderType {
  male = 'male',
  female = 'female'
}

export interface IPaymentHistory extends IVirtualPayment {
  _id: string;
  type: 'Stripe' | 'Paypal';
  receipt?: string;
}

export interface IVirtualPayment {
  _id?: string;
  amount: number;
  currency: Currency;
  date: Date;
  description: string;
  plan?: Product;
}

export interface ISubscription {
  title: string;
  balance: number;
  gifts: number;
  contacts: number | 'unlimited';
  boostingMin?: number;
  incognitoMin: number;
  messages: number | 'unlimited';
  additionalPhotos: number;
  additionalVideos: number;
  viewVisitors: number;
  viewVisitorsEndDate?: Date;
  viewFavouritedBy: number;
  endDate: Date;
  highlightProfile: boolean;
  adminAccess?: boolean;
}

export const initialUserState: IUserModel = {
  _id: null,
  userID: 0,
  name: 'Guest',
  email: 'guest@guest.com',
  dob: null,
  age: null,
  profilePhoto: null,
  profilePhotoApproved: false,
  gender: EGenderType.male,
  showVerify: true,
  location: null,
  visible: false,
  access: {
    fav: false,
    vis: false,
    add: 0
  },
  valid: false,
  token: null,
  coins: null,
  incognitoStatus: false,
  incognitoHistory: null,
  paymentHistory: [],
  favourites: [],
  blockeds: [],
  suggestions: [],
  virtualPurchaseHistory: [],
  subscription: {
    title: 'Guest subscription',
    incognitoMin: 0,
    balance: 0,
    contacts: null,
    gifts: null,
    messages: 0,
    additionalPhotos: null,
    additionalVideos: null,
    viewVisitors: null,
    viewFavouritedBy: null,
    highlightProfile: false,
    endDate: null
  },
  verified: {
    approved: false,
    complete: false,
    email: false,
    person: false,
    personLink: null,
    phone: false,
    photos: false,
    profileCoverPhoto: false,
    profilePhoto: false,
    purchases: false,
    verifiedPhotos: false,
    videos: false,
  },
  isOnline: false,
  inActive: false,
  blocked: false // blocked by admin
};

export interface Register {
  ADMpath?: string;
  name: string;
  email: string;
  password1: string;
  password2: string;
  dob: Date;
  phone: string;
}
