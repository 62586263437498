/* eslint-disable @typescript-eslint/member-ordering */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createMessageAndNavigate, loadChat, updateChat } from './chat-messages.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChatMessagesService } from './chat-messages.service';
import { Router } from '@angular/router';
import { appNotifierError } from '../app-notifier/app-notifier.actions';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class ChatMessagesEffects {
  constructor(
    private actions$: Actions,
    private chatMessagesService: ChatMessagesService,
    private router: Router,
  ) { }

  createMessageAndNavigate$ = createEffect(() => this.actions$.pipe(
    ofType(createMessageAndNavigate),
    switchMap(({ profileId }) => this.chatMessagesService.createMessage(profileId).pipe(
      map(response => this.router.navigate(['/my-profile', 'chat', response])), //, { replaceUrl: true }
      catchError(err => of(appNotifierError(err)))
    ))
  ), { dispatch: false });

  loadChat$ = createEffect(() => this.actions$.pipe(
    ofType(loadChat),
    switchMap(({_id}) => this.chatMessagesService.getMessages(_id).pipe(
      map((chat) => updateChat({ chat })),
      catchError(() => of(appNotifierError()))
    ))
  ));
}
