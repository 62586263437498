/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: 'payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
})
export class PaymentDialogPage {
  id: string;
  message = 'Thank you for your order!';
  state = 'Payment was successfully';

  url: string;
  destroyed$ = new ReplaySubject(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { id: string; receipt_url: string; state: string },
  ) {
    if(data){
      this.id = data.id;
      this.url = data.receipt_url;
      this.state = data.state ? data.state : this.state;
    }
  }

  openRecept(e){
    e.preventDefault();
    window.open(this.url);
  }
}
