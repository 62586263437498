import { Component, ChangeDetectionStrategy, Output, EventEmitter, HostListener, Input, } from '@angular/core';

@Component({
  selector: 'app-close-dialog-button',
  templateUrl: './close-dialog-button.component.html',
  styleUrls: ['./close-dialog-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseDialogButtonComponent {
  @Output() clicked = new EventEmitter();
  @Input() useStyle;

  @HostListener('click')
  onClick() {
    this.clicked.emit();
  }
}
