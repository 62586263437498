import { Action, createReducer, on } from '@ngrx/store';
import { initialSpinnerState, ISpinnerState } from './spinner.state';
import { haltSpinner, launchSpinner } from './spinner.actions';

const reducer = createReducer(
  initialSpinnerState,
  on(launchSpinner, () => ({ launch: true })),
  on(haltSpinner, () => ({ launch: false })),
);

export const spinnerReducer = (state: ISpinnerState, action: Action) => reducer(state, action);
