<div *ngIf="radioGroupDataObjects?.length < 1" class="radio-group no-options">
  No options here
</div>

<div *ngIf="radioGroupDataObjects" class="radio-group">
  <div
    *ngFor="let radioGroupDataObject of radioGroupDataObjects"
    class="radio-button"
  >
    <input
      class="radio-button_control"
      type="radio"
      [id]="radioGroupDataObject.radioButtonId"
      [name]="radioGroupDataObjects[0].groupName"
      [checked]="radioGroupDataObject.selected"
      (click)="onDataObjectSelected(radioGroupDataObject)"
      [disabled]="radioGroupDataObject.disabled"
    />
    <!--
    [value]="radioGroupDataObject.selected"
    [(ngModel)]="radioGroupDataObject.selected"
    -->
    <label class="radio-button_label" [for]="radioGroupDataObject.radioButtonId">
      {{ radioGroupDataObject.title }}
    </label>
  </div>
</div>
