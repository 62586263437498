import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IReceiveGift, ISentGift } from './gifts.state';

export interface Gift {
  _id: string;
  title: string;
  type: string;
  image: string;
  amount: number;
}

@Injectable({
  providedIn: 'root'
})
export class GiftsService {
  constructor(private httpClient: HttpClient) {}

  loadGifts(giftsType?: string) {
    let params = new HttpParams();
    if (giftsType) {
      params = params.append('type', giftsType);
    }
    return this.httpClient.get<Gift[]>('/api/gifts', { params });
  }

  loadReceivedGifts() {
    return this.httpClient.get<IReceiveGift[]>('/api/gifts/me/received');
  }

  loadSentGifts() {
    return this.httpClient.get<ISentGift[]>('/api/gifts/me/sent');
  }

  sendGift(userId: string, giftId: string) {
    const body = {
      user: userId,
      gift: giftId
    };
    return this.httpClient.post('/api/gifts/send', body);
  }
}
