import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-root-loader',
  templateUrl: './root-loader.component.html',
  styleUrls: ['./root-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RootLoaderComponent {
  @Input() loadingText = 'Loading';

  ngOnInit() {
    // console.log('\n---\nRootLoaderComponent : on inited\n---\n');
  }
}
