import {
  Component,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-media-uploader',
  templateUrl: './media-uploader.component.html',
  styleUrls: ['./media-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaUploaderComponent {
  @Input() type: 'photo' | 'video';

  get isPhoto(): boolean {
    return this.type === 'photo';
  }
}
