<div
  #carouselContainer
  [style.height]="!maintainAspectRatio ? '100%' : 'auto'"
  class="carousel"
  tabindex="0"
>
  <!-- (ionSlideDoubleTap)="media.type === 'photo' ? like.emit(setActiveSlide):null" -->
  <ion-slides
    #carouselList
    (ionSlideTap)="media.type === 'video' ? pauseVideo.emit():null"
    (ionSlidePrevStart)="slideChange(carouselList)"
    (ionSlideNextStart)="slideChange(carouselList)"
    [options]="slideOpt"
    [style.height]="!maintainAspectRatio ? '100%' : 'auto'"
    class="carousel-list"
    role="listbox"
  >
    <ion-slide
      #carouselSlide
      *ngFor="let slide of slidesList"
      [style.padding-bottom]="maintainAspectRatio && proportion ? proportion + '%': '0px'"
      [style.height]="!maintainAspectRatio && slideHeight ? slideHeight : '0px'"
      class="carousel-slide"
      role="option"
    >
      <ng-container [ngTemplateOutlet]="slide.templateRef"></ng-container>
    </ion-slide>
  </ion-slides>

  <div class="user-data" *ngIf="!canLike && user$ | async as user">
   {{user.name}}
    <span class="user-info">
      {{user.age}} year, {{user.location.city}}, {{user.location.country}}
    </span>
  </div>

  <div class="mini-galerry">
    <div class="photo-item"
      *ngFor="let photo of media.mediaScope; index as i;"
      [class.photo-item-active]="setActiveSlide === i"
      (click)="carouselList.slideTo(slidesLoop?i+1:i);"
    >
      <app-secure-image
        [src]="photo.url" *ngIf="media.type === 'photo'; else isVideo"
        [useStyle]="photoStyle"
      ></app-secure-image>
      <ng-template #isVideo>
        <video #videoElement class="video-bg" (loadedmetadata)="getFrame($event)">
          <source [src]="photo.url" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </ng-template>
    </div>
  </div>

  <div class="footerSlide">
    <button (click)="carouselList.slidePrev()">
      <mat-icon svgIcon="internal:left-slide"></mat-icon>
    </button>
    <div class="likeIcon"
      *ngIf="media.type === 'photo' && !canLike"
      [class.liked]="media.mediaScope[setActiveSlide]?.liked"
      (click)="like.emit(setActiveSlide)"
    >
      <mat-icon svgIcon="internal:heart"></mat-icon>
    </div>
    <button (click)="carouselList.slideNext()">
      <mat-icon svgIcon="internal:right-slide"></mat-icon>
    </button>
  </div>
</div>
