/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MessagesService {
  constructor(private httpClient: HttpClient) {}

  getMyMessages() {
    return this.httpClient.get('/api/messages');
  }

  deleteMySingleMessage(chatId: string, message_id: string, userB: string) {
    return this.httpClient.post('/api/messages/deleteSingleMessage', { chatId, message_id, userB });
  }

  getMessages(id: string) {
    return this.httpClient.get(`/api/messages/${id}`);
  }

  deleteChat(userId: string, selectedDeleteOptionId?: string, isBlockSecondUserAfterChatDelete?: boolean, declined?: boolean) {
    return this.httpClient.post(`/api/messages/deleteChat/${userId}`, {
      selectedDeleteOptionId,
      isBlockSecondUserAfterChatDelete,
      declined,
    });
  }

  createMessage(userId: string): Observable<string> {
    return this.httpClient.post<string>(`/api/messages/new/${userId}`, null);
  }

  acceptDialog(_id: string) {
    return this.httpClient.post('/api/messages/accept', { _id });
  }

  newChat(id: string, message: string) {
    return this.httpClient.put(`/api/messages/${id}`, { message });
  }

  markRead(chatId: string, messageId: string = null) {
    return this.httpClient.post(`/api/messages/${chatId}/markread`, { messageId });
  }
}
