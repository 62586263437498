<div *ngIf="isLoading$ | async">
  <div class="overlay global"></div>
  <div class="spinner global">
    <mat-spinner
      color="primary"
      diameter="80"
      strokeWidth="6"
    ></mat-spinner>
  </div>
</div>

<div #componentSpinner>
  <div id="component-spinner">
    <div class="overlay local"></div>
    <div class="spinner local">
      <mat-spinner
        color="primary"
        diameter="80"
        strokeWidth="6"
      ></mat-spinner>
    </div>
  </div>
</div>
