import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISummary } from './notification.state';
import { UserNotification } from '../../shared/models/user/core/notification';
import { NotificationMarkReadType } from '../../shared/models/shared/notification';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private httpClient: HttpClient) { }

  getSummaryNotification() {
    return this.httpClient.get<ISummary>('/api/notifications/me/summary').pipe();
  }

  getMyNotifications(): Observable<UserNotification[]>{
    return this.httpClient.get<UserNotification[]>('/api/notifications/me').pipe();
  }

  deleteMyNotification(_id: string, type: string) {
    return this.httpClient.post<boolean>('/api/notifications/me/delete', { _id, type });
  }

  markNotificationsRead(type: NotificationMarkReadType) {
    return this.httpClient.post('/api/notifications/me/markread', { type });
  }
}
