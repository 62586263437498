import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { IonicModule } from '@ionic/angular';
// import { MatCarouselComponent } from './carousel.component';
import { MatCarouselSlideComponent } from './carousel-slide/carousel-slide.component';
// import {
//   HammerGestureConfig,
//   HAMMER_GESTURE_CONFIG
// } from '@angular/platform-browser';
// import { ModuleWithProviders } from '@angular/compiler/src/core';
// import { SharedModule } from 'src/app/shared/shared.module';

// https://github.com/angular/angular/issues/10541#issuecomment-300761387
// export class MatCarouselHammerConfig extends HammerGestureConfig {
//   overrides = {
//     pinch: { enable: false },
//     rotate: { enable: false }
//   };
// }

@NgModule({
  declarations: [MatCarouselSlideComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, IonicModule],
  exports: [MatCarouselSlideComponent]
})
export class CarouselModule {
  // static forRoot(): ModuleWithProviders {
  //   return {
  //     ngModule: CarouselModule,
  //     providers: [
  //       // { provide: HAMMER_GESTURE_CONFIG, useClass: MatCarouselHammerConfig }
  //     ]
  //   };
  // }
}
