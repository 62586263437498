/* eslint-disable no-underscore-dangle */
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild, ElementRef
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SafeUrl } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { PhotoModel } from '../../models/photo.model';
import { VideoModel } from '../../models/video.model';
import { ReplaySubject } from 'rxjs';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/app.state';
import { appNotifierError } from '../../../store/app-notifier/app-notifier.actions';
import { ProfileMediaService } from '../../../modules/profile-page/services/profile-media.service';

interface InputData {
  current: SafeUrl;
  userId: string;
  mediaIdx: number;
  mediaScope: PhotoModel[] | VideoModel[];
  type: 'photo' | 'video';
}

@Component({
  selector: 'app-dialog-image-box',
  templateUrl: './image-box.component.html',
  styleUrls: ['./image-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageBoxComponent implements OnDestroy {
  @ViewChild('videoElement') videoElement: ElementRef;
  currentPlayingVideo: HTMLVideoElement;
  onConfirm: boolean;
  faUser = faUser;
  playerState = false;
  innerPhotoStyle = {
    'object-fit': 'cover',
    'border-radius': '30px 30px 0 0',
    height: '90vh'
  };

  innerVideoStyle = {
    ...this.innerPhotoStyle,
    'object-fit': 'contain',
    width: '100%',
    height: '100%',
    'max-height': '100vh',
    'border-radius': 'none',
    'margin-bottom': '10vh'
  };

  private destroyed$ = new ReplaySubject(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    private mediaService: ProfileMediaService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store<IAppState>
  ) {
    this.onConfirm = ['/my-profile/photo-on-confirmation', '/my-profile/photo-on-confirmation?type=Video'].includes(this.router.url);
  }

  getFrame(event: Event) {
    const video = event.target as HTMLVideoElement;
    video.currentTime = video.duration * 0.01;
  }

  onPlayingVideo(event) {
    event.preventDefault();
    if (this.currentPlayingVideo === undefined) {
      this.currentPlayingVideo = event.target;
      this.currentPlayingVideo.play();
      this.playerState = true;
    } else {
      if (event.target !== this.currentPlayingVideo) {
        this.currentPlayingVideo.pause();
        this.currentPlayingVideo = event.target;
        this.currentPlayingVideo.play();
      }
      this.playerState = true;
    }
  }

  pauseVideo(){
    if (this.currentPlayingVideo && this.data.type === 'video') {
      this.currentPlayingVideo.pause();
      this.playerState = false;
    }
  }

  playVideo(data: HTMLVideoElement) {
    data.play();
    this.playerState = true;
  }

  onLike(idx: number) {
    this.mediaService.likePhoto(this.data.userId, this.data.mediaScope[idx]._id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (likeData: { liked: boolean; likes: [{ userId: string; date: string }] }) => {
          const isLiked = likeData.liked;
          this.data.mediaScope[idx].liked = likeData.liked;
          this.data.mediaScope[idx].likes = likeData.likes;
          // this.store.dispatch(isLiked
          //   ? appNotifierSuccess('Liked photo')
          //   : appNotifierInfo('Unliked photo')
          // );
          this.cdr.markForCheck();
        },
        error => {
          this.store.dispatch(appNotifierError(error.msg));
        });
  }

  trackByFn(index: number): number {
    return index;
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
