import { APP_INITIALIZER, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NgxStripeModule } from 'ngx-stripe';
import { EffectsModule } from '@ngrx/effects';
import { AppComponent } from './app.component';
import { appEffects } from './store/app.effects';
import { appReducers } from './store/app.reducers';
import { RouteReuseStrategy } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { MainComponent } from './modules/main/main.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { IconsSharedModule } from './core/modules/icon-shared.module';
import { httpInterceptors } from './shared/interseptors/interceptors';
import { AuthorisationGuard, ValidProfileGuard } from './shared/guards';
import { MaterialSharedModule } from './core/modules/material-shared.module';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NotificationModule } from './core/modules/notification/notification.module';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import {
  ActiveUserService,
  MessagesService,
  MyProfileService,
  ResetPasswordService,
  SecureImageService,
  SocketIoService,
  UserServicesService,
  ContentService
} from './shared/services';
import { LoaderService } from './shared/services/loader.service';

@NgModule({
  declarations: [ AppComponent, MainComponent ],
  exports: [ SharedModule ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'mobileApp' }),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({ cookieName: '_csrf', headerName: 'x-csrf-token' }),
    HttpCacheInterceptorModule.forRoot(),
    StoreModule.forRoot(appReducers, {
      runtimeChecks: { strictActionImmutability: true, strictStateImmutability: true },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot(appEffects),
    NgxStripeModule.forRoot(environment.stripeKey),
    StoreRouterConnectingModule.forRoot(),
    IonicModule.forRoot(),
    SuperTabsModule.forRoot(),
    LazyLoadImageModule,
    SharedModule,
    MaterialSharedModule,
    IconsSharedModule,
    AppRoutingModule,
    NotificationModule,
  ],
  providers: [
    httpInterceptors,
    {
      provide: APP_INITIALIZER,
      useFactory: (sockets: SocketIoService) => () => sockets.socketInit(),
      deps: [SocketIoService], multi: true
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthorisationGuard,
    ValidProfileGuard,
    MyProfileService,
    SecureImageService,
    ResetPasswordService,
    MessagesService,
    ActiveUserService,
    SocketIoService,
    UserServicesService,
    ContentService,
    LoaderService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
