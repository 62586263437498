import { Action, createReducer, on } from '@ngrx/store';
import { initialMessagesState, IImportantMessagesState } from './important-messages.state';
import { uploadMessages, deleteMessages } from './important-messages.actions';

const reducer = createReducer(
  initialMessagesState,
  on(uploadMessages, (state) => ({ ...state })),
  on(deleteMessages, (state, { id }) => ({ ...state, messages: state.messages.filter(message => message._id !== id) })),
);

export const messagesReducer = (state: IImportantMessagesState, action: Action) => reducer(state, action);
