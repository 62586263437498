export const IMPORTANT_MESSAGES_NODE = 'important-messages';

export interface IImportantMessages {
  _id: string;
  text: string;
  date: string;
  time: string;
}

export interface IImportantMessagesState {
  messages: IImportantMessages[];
}

export const initialMessagesState: IImportantMessagesState = {
  messages: [
    {
      _id: '124',
      date: '01.09.2021',
      time: '08:32 PM',
      text: 'Today the site will be undergoing technical work from 12:00 to 22:00. As a result of technical work, the site may not work correctly. These are temporary inconveniences. We are sorry for that.'
    },
    { _id: '125', date: '22.09.2021', time: '12:15 AM', text: 'Technical work from 08:00 to 10:00.' }
  ]
};
