import { Drinking, Education, Occupation, RelationshipStatus, Religion, Smoking } from '../../shared/models/profile.model';
import { EGenderType } from '../user/user.state';
import { IFavourites, ISuggestions } from '../../shared/models/IUser';

export const PROFILE_NODE = 'profile';

export interface IProfile {
  _id: string;
  userID: number;
  name: string;
  age: number;
  gender: EGenderType;
  profilePhoto: string;
  location: {
    country: string;
    city: string;
    distance: number;
  };
  height?: number;
  description?: string;
  relationshipStatus?: RelationshipStatus;
  children?: boolean;
  education?: Education;
  occupation?: Occupation;
  drinking?: Drinking;
  smoking?: Smoking;
  religion?: Religion;
  visible: boolean;
  favourites?: IFavourites[];
  suggestions?: ISuggestions[];
  languages?: string[];
  interests?: string[];
  pets?: string[];
  verified: IProfileVerification;
  online: boolean;
  status: string;
  isFavourite?: boolean;
  isBlocked?: boolean;
  inActive?: boolean;
  blockedBy?: any;
  unreadMessages?: number;
  profileCoverPhoto?: string;
  blocked: boolean; // blocked by admin
}

export interface IProfileVerification {
  approved: boolean;
  complete: boolean;
  email: boolean;
  person: boolean;
  personLink: string;
  phone: boolean;
  photos: boolean;
  profileCoverPhoto: boolean;
  profilePhoto: boolean;
  purchases: boolean;
  verifiedPhotos: boolean;
  videos: boolean;
}

export const initialProfileState: IProfile = {
  _id: null,
  userID: 0,
  name: 'Guest',
  age: 0,
  gender: EGenderType.male,
  profilePhoto: null,
  location: null,
  visible: false,
  verified: {
    approved: false,
    complete: false,
    email: false,
    person: false,
    personLink: null,
    phone: false,
    photos: false,
    profileCoverPhoto: false,
    profilePhoto: false,
    purchases: false,
    verifiedPhotos: false,
    videos: false,
  },
  online: false,
  inActive: false,
  status: 'offline',
  isBlocked: true,
  blocked: false // blocked by admin
};
