import { IProfileVerification } from '../../store/profile/profile.state';

export const calculateProfileProgress = (verification: IProfileVerification): number => {
  /**
   10 - аватарка загружена и подтверждена
   10 - обложка загружена и подтверждена
   5 - введен и подтвержден мобильный (смс верификации еще нет)
   0 - подтверждена почта
   10 - Services to the Agreements
   20 - верификация пройдена
   10 - загруженные фотографии (мин 1 шт) подтверждены
   15 - загруженные видео (1 шт) подтверждены
   20 - заполнен профиль
   **/
  let percent = 0;
  if (verification.profilePhoto) {
    percent += 10;
  }

  if (verification.profileCoverPhoto) {
    percent += 10;
  }

  if (verification.phone) {
    percent += 5;
  }

  if (verification.purchases) {
    percent += 10;
  }

  if (verification.person) {
    percent += 20;
  }

  if (verification.verifiedPhotos) {
    percent += 10;
  }

  if (verification.videos) {
    percent += 15;
  }

  if (verification.complete) {
    percent += 20;
  }
  return percent;
};
