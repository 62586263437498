import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ActiveUserService, MyProfileService } from 'src/app/shared/services';
import { NotificationService } from 'src/app/store/notification/notification.service';
import { ProfileService } from 'src/app/store/profile/profile.service';

@Injectable()
export class ProfileResolver implements Resolve<any> {
  constructor(
    private profileService: ProfileService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if(this.profileService.myID === route.params.id) {
      this.router.navigate(['/my-profile', 'activity', 'about']);
      return of(null);
    }
    return this.profileService.getProfile(route.params.id).pipe(
      catchError(_ => {
        // this.router.navigate(['/404']);
        return of(null);
      })
    );
  }
}

@Injectable()
export class MyFavouritesResolver implements Resolve<any> {
  constructor(
    private profileService: MyProfileService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.profileService.getMyFavourites();
  }
}

// @Injectable()
// export class MyBlockedsResolver implements Resolve<any> {
//   constructor(private profileService: MyProfileService) { }

//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     return this.profileService.getMyBlockedUsers();
//   }
// }

@Injectable()
export class MyActivityResolver implements Resolve<any> {
  constructor( private notificationService: NotificationService ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.notificationService.getMyNotifications();
  }
}

@Injectable()
export class EditMyProfileResolver implements Resolve<any> {
  constructor(private profileService: MyProfileService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.profileService.getMyProfile();
  }
}

@Injectable()
export class IsLoginedResolver implements Resolve<any> {
  constructor(private user: ActiveUserService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.user.getUser().pipe(
      tap(user => {
        // eslint-disable-next-line no-underscore-dangle
        if (user._id) {
          // this.router.navigate(['/my-profile']); // , { replaceUrl: true }
        }
      }),
      catchError(err => of(null))
    );
  }
}
