export const GIFTS_NODE = 'gifts';

export interface IGift {
  _id: string;
  title: string;
  type: string;
  image: string;
  amount: number;
}

export interface ISentGift {
  to: {
    _id: string;
    name: string;
  };
  date: string;
  title: string;
  image: string;
  type: string;
}

export interface IReceiveGift {
  from: {
    _id: string;
    name: string;
  };
  date: string;
  title: string;
  image: string;
  type: string;
}

export interface IGiftsState {
  gifts: {
    items: IGift[];
    isLoaded: boolean;
  };
  receivedGifts: IReceiveGift[];
  sentGifts: ISentGift[];
}

export const initialGiftsState: IGiftsState = {
  gifts: {
    items: [],
    isLoaded: false
  },
  receivedGifts: [],
  sentGifts: [],
};
