/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadGifts,
  loadReceivedGifts,
  loadSentGifts,
  sendGift,
  sendGiftSuccess,
  updateGifts, updateGiftsFails,
  updateReceivedGifts,
  updateSentGifts
} from './gifts.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GiftsService } from './gifts.service';
import { appNotifierSuccess, appNotifierError } from '../app-notifier/app-notifier.actions';
import { EMPTY, of } from 'rxjs';

@Injectable()
export class GiftsEffects {
  constructor(
    private actions$: Actions,
    private giftsService: GiftsService,
  ) {
  }

  loadGifts$ = createEffect(() => this.actions$.pipe(
    ofType(loadGifts),
    switchMap(({ giftsType }) => this.giftsService.loadGifts(giftsType).pipe(
      map(gifts => updateGifts({ gifts })),
      catchError(() => of(updateGiftsFails()))
    )),
  ));

  loadReceivedGifts$ = createEffect(() => this.actions$.pipe(
    ofType(loadReceivedGifts),
    switchMap(() => this.giftsService.loadReceivedGifts().pipe(
      map(receivedGifts => updateReceivedGifts({ receivedGifts })),
      catchError(() => EMPTY)
    )),
  ));

  loadSentGifts$ = createEffect(() => this.actions$.pipe(
    ofType(loadSentGifts),
    switchMap(() => this.giftsService.loadSentGifts().pipe(
      map(sentGifts => updateSentGifts({ sentGifts })),
      catchError(() => EMPTY)
    )),
  ));

  sendGift$ = createEffect(() => this.actions$.pipe(
    ofType(sendGift),
    switchMap(({ userId, giftId }) => this.giftsService.sendGift(userId, giftId).pipe(
      switchMap(() => [sendGiftSuccess(), appNotifierSuccess()]),
      catchError((err) => [appNotifierError(err.msg)])
    )),
  ));
}
