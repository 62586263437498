import { createReducer, on, Action } from '@ngrx/store';
import { IGetMessages } from 'src/app/shared/models/messages/i-get-messages';
import { initialChatState } from './chat-messages.state';
import { updateChat, updateMessages, changeNotifications} from './chat-messages.actions';

const reducer = createReducer(
  initialChatState,
  on(updateChat, (state, { chat }) => ({ ...chat })),
  on(updateMessages, (state, { messages }) => ({ ...state, messages })),
  on(changeNotifications, (state, { disabled }) => ({ ...state, disabled }))
);

export const chatReducer = (state: IGetMessages, action: Action) => reducer(state, action);
