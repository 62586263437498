import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BLOCK_OPTIONS } from '../../models/profile.model';
import { IRadioButtonOption } from '../../models/radio-button-group';
import { AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';

interface IModalWindowData {
  name: string;
  image: string;
  gender: string;
  title: string;
  blocked?: boolean;
  favorited?: boolean;
  type: string;
}

@Component({
  selector: 'app-block-user-modal',
  templateUrl: './block-user.component.html',
  styleUrls: ['./block-user.component.scss'],
})
export class BlockUserModalComponent {
  @ViewChild('blockBtn') blockBtn: ElementRef;
  deleteOptions: IRadioButtonOption[] = [
    {
      radioButtonId: 'id_delete_for_you',
      groupName: 'deleteOptionsGroup',
      title: 'Delete for me',
      selected: true,
    },
    {
      radioButtonId: 'id_delete_chat_completely',
      groupName: 'deleteOptionsGroup',
      title: 'Delete for both completely',
      selected: false,
    },
    {
      radioButtonId: 'id_delete_for_your_match',
      groupName: 'deleteOptionsGroup',
      title: 'Delete for your match',
      selected: false,
    },
  ];

  selectedDeleteOptionId = '';

  selected: string;
  blockOptions = BLOCK_OPTIONS;

  blockCheckBoxFormGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IModalWindowData,
    private cdr: ChangeDetectorRef,
    private alert: AlertController,
    private formBuilder: FormBuilder
  ) {
    this.selectedDeleteOptionId = this.deleteOptions[0].radioButtonId;
  }


  ngOnInit() {
    this.blockCheckBoxFormGroup = this.formBuilder.group({
      isBlockSecondUserAfterChatDelete: [false],
    });
  }

  changeDisable(value) {
    this.blockBtn.nativeElement.disabled = false;
    this.selected = value;
  }

  onDeleteOptionSelected(selectedOptionObject: IRadioButtonOption) {
    // todo remove log
    // console.log(
    //   'delete chat action -> BlockUserModalComponent : onDeleteOptionSelected : selectedOptionObject ',
    //   selectedOptionObject
    // );

    this.selectedDeleteOptionId = selectedOptionObject.radioButtonId;
    this.cdr.detectChanges();
  }

  async showInfo() {
    const alert = await this.alert.create({
      header: 'Block second chat participant',
      message: `The second chat participant will be blocked after you 
      delete the chat with any option lested above, and he/she won't be able to send you messages.`,
      backdropDismiss: true,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
