/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MapboxPlacesTypes } from '../../interfaces/mapbox-places-types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { GeocodingService } from '../../services/geocoding.service';

export interface AddressType {
  placeName: string;
  longitude: number;
  latitude: number;
  shortCode: string;
}

@Component({
  selector: 'app-geocoding-lookup-inline',
  templateUrl: './geocoding-lookup-inline.component.html',
  styleUrls: ['./geocoding-lookup-inline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GeocodingLookupInlineComponent),
    multi: true
  }]
})
export class GeocodingLookupInlineComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Output() selectAddress = new EventEmitter<AddressType>();
  @Input() geocodingType: MapboxPlacesTypes;
  @Input() shortCode: string;
  @Input() width = 180;
  @Input() selectedAddress = '';

  private destroy = new ReplaySubject(1);

  get title() {
    switch (this.geocodingType) {
      case MapboxPlacesTypes.COUNTRY: {
        return 'Country';
      }
      case MapboxPlacesTypes.CITY: {
        return 'City';
      }
      case MapboxPlacesTypes.POSTCODE: {
        return 'Postcode';
      }
    }
  }

  selectedAddress$ = new Subject<string>();
  addresses: Array<AddressType> = [];
  isDisabled = false;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    private geocodingService: GeocodingService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.selectedAddress$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe(address => {
        this.search(address);
      });

    if (this.geocodingType === MapboxPlacesTypes.COUNTRY) {
      this.shortCode = '';
    }
  }

  search(searchTerm: string) {
    if (searchTerm) {
      this.geocodingService.forwardGeocoding(searchTerm, this.geocodingType, this.shortCode)
        .pipe(takeUntil(this.destroy))
        .subscribe(res => {
          this.addresses = res.map(place => ({
            placeName: place.text,
            longitude: place.center[0],
            latitude: place.center[1],
            shortCode: place.properties.short_code,
          }));
          this.cdr.detectChanges();
        });
    }
    else {
      this.addresses = [];
      this.cdr.detectChanges();
    }
  }

  writeValue(value: any): void {
    this.selectedAddress = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(address: AddressType) {
    this.selectedAddress = address.placeName;
    this.selectAddress.emit(address);
    this.addresses = [];
    this.cdr.detectChanges();
  }

  changeAddress(address: string) {
    this.selectedAddress$.next(address);
    this.selectedAddress = address;
    this.onChange(address);
    this.onTouched();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.cdr.detach();
  }
}
