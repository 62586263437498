import { createAction, props } from '@ngrx/store';
import { IProfile, IProfileVerification } from './profile.state';

export const updateProfile = createAction(
  '[Profile] Update profile',
  props<{ profile: IProfile }>()
);

export const updateProfileVerification = createAction(
  '[Profile] Update profile verification',
  props<{ verification: IProfileVerification }>()
);

export const updateProfileVerificationPersonLink = createAction(
  '[Profile] Update person link in profile verification',
  props<{ personLink: string }>()
);

export const updateProfilePhoto = createAction(
  '[Profile] Update profile photo',
  props<{ profilePhoto: string } >()
);

export const loadProfileById = createAction(
  '[Profile] Load profile by user ID',
  props<{ id: string }>()
);

