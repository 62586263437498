import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ServiceProduct } from '../../models/products/service';
import { shadeColor } from '../../helpers/shadeColor.function';

@Component({
  selector: 'app-buy-service',
  templateUrl: './buy-service.component.html',
  styleUrls: ['./buy-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyServiceComponent {
  styles = {
    'height.px': '30',
    'width.px': '30',
    background: '#f2f4f5',
    color: '#2e2e30'
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: ServiceProduct) { }

  get darkColor(): string {
    return shadeColor(this.data.params?.activeColor, -60);
  }
}
