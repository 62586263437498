import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './components/logo/logo.component';
import { InfoValidComponent } from './modals/info-valid/info-valid.component';
import { MaterialSharedModule } from '../core/modules/material-shared.module';
import { GeocodingLookupComponent } from './components/geocoding-lookup/geocoding-lookup.component';
import { ImageCropperWrapperComponent } from './components/image-cropper-wrapper/image-cropper-wrapper.component';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SecureImageComponent } from './components/secure-image/secure-image.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { DualSpinnerComponent } from './components/dual-spinner/dual-spinner.component';
import { IonicModule } from '@ionic/angular';
import { AcceptDeclineButtonComponent } from './components/accept-decline-button/accept-decline-button.component';
import { MinProfileCardComponent } from '../modules/profile-page/profile-shared/min-profile-card/min-profile-card.component';
import { GenderIconComponent } from './components/gender-icon/gender-icon.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfileProgressBarComponent } from './components/profile-progress-bar/profile-progress-bar.component';
import { CountdownModule } from 'ngx-countdown';
import { BlockUserModalComponent } from './modals/block-user/block-user.component';
import { TimeagoPipe, DurationPipe, TextcutPipe, SearchPipe, FilterPipe } from './pipes';
import {
  HoverEffectDirective,
  LazyImageDirective,
  LongclickDirective,
  SwaperDirective,
} from './directives';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { CloseDialogButtonComponent } from './components/close-dialog-button/close-dialog-button.component';
import { FormsModule } from '@angular/forms';
import { MediaUploaderComponent } from './components/media-uploader/media-uploader.component';
import { ImageBoxComponent } from './modals/image-box/image-box.component';
import { CarouselModule } from '../core/modules/carousel/carousel.module';
import { NewPhotoUploaderComponent } from './components/new-photo-uploader/new-photo-uploader.component';
import { LikesComponent } from './components/likes/likes.component';
import { GiftItemComponent } from './components/gift-item/gift-item.component';
import { RemainOfGiftsComponent } from './modals/remain-of-gifts/remain-of-gifts.component';
import { SendGiftComponent } from './modals/send-gift/send-gift.component';
import { GeocodingLookupInlineComponent } from './components/geocoding-lookup-inline/geocoding-lookup-inline.component';
import { InvitesDialogPage } from './modals/invites-dialog/invites-dialog.component';
import { BuyServiceComponent } from './modals/buy-service/buy-service.component';
import { PaymentDialogPage } from './modals/payment-dialog/payment-dialog.component';
import { SpinnerComponent } from './modals/spinner/spinner.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatCarouselComponent } from '../core/modules/carousel/carousel.component';
import { LangSwitchComponent } from './components/lang-switch/lang-switch.component';
import { MatTelInputComponent } from './components/mat-tel-input/mat-tel-input.component';
import { LoaderSpinner } from './components/loader-spinner/loader-spinner.component';
import { RootLoaderComponent } from './components/root-loader/root-loader.component';
import { RadioButtonGroupStandaloneComponent } from './components/radio-button-group/radio-button-group.component';
import { AppDataSyncService } from './services/app-data-sync.service';

// TODO to much shared components provided via the same module (SharedModule)
// it's bad for performance and speed of the app loading
// in future, separate this components (enumarated in `const declarations`)
// to different modules or standalone components,
// and all of the page components should import only that components, that they are using

const declarations = [
  HoverEffectDirective,
  LazyImageDirective,
  LongclickDirective,
  SwaperDirective,
  MatCarouselComponent,
  LogoComponent,
  GeocodingLookupComponent,
  GeocodingLookupInlineComponent,
  InfoValidComponent,
  ImageCropperWrapperComponent,
  SecureImageComponent,
  DualSpinnerComponent,
  AcceptDeclineButtonComponent,
  MinProfileCardComponent,
  GenderIconComponent,
  SidebarComponent,
  ProfileProgressBarComponent,
  BlockUserModalComponent,
  TimeagoPipe,
  TextcutPipe,
  DurationPipe,
  FilterPipe,
  SearchPipe,
  HeaderComponent,
  CloseDialogButtonComponent,
  MediaUploaderComponent,
  ImageBoxComponent,
  NewPhotoUploaderComponent,
  LikesComponent,
  GiftItemComponent,
  RemainOfGiftsComponent,
  SendGiftComponent,
  InvitesDialogPage,
  PaymentDialogPage,
  BuyServiceComponent,
  SpinnerComponent,
  LangSwitchComponent,
  MatTelInputComponent,
  LoaderSpinner,
  RootLoaderComponent,
];

@NgModule({
  declarations,
  imports: [
    RouterModule,
    CommonModule,
    MaterialSharedModule,
    MatSliderModule,
    MatIconModule,
    IonicModule,
    LyImageCropperModule,
    ImageCropperModule,
    FlexModule,
    CountdownModule,
    FormsModule,
    CarouselModule,
    LazyLoadImageModule,
    RadioButtonGroupStandaloneComponent,
  ],
  exports: declarations,
  providers: [AppDataSyncService],
})
export class SharedModule {}
