
export const SPINNER_NODE = 'spinner';

export interface ISpinnerState {
  launch: boolean;
}

export const initialSpinnerState: ISpinnerState = {
  launch: false
};
