import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js/min';
export const phoneNumberValidator = (control) => {
    const error = { validatePhoneNumber: true };
    let numberInstance;
    if (control.value) {
        try {
            numberInstance = parsePhoneNumber(control.value);
        } catch (e) {
            control.setValue(null);
            return error;
        }
        if (numberInstance && !numberInstance.isValid()) {
            control.setValue(null);
            if (!control.touched) {
                control.markAsTouched();
            }
            return error;
        }
    }
    return null;
};

export const parsePhone = (phone, code) => parsePhoneNumberFromString(phone, code);
