import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textcut'
})
export class TextcutPipe implements PipeTransform {

  transform(value: string, len: number): string {
    return value.length < len ? value : `${ value.slice(0, len) }...`;
  }

}
