import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { of, ReplaySubject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { IAppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { haltSpinner } from '../../../store/spinner/spinner.actions';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, OnDestroy {
  destroyed$ = new ReplaySubject(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data = { seconds: 30 },
    private store: Store<IAppState>
  ) { }

  ngOnInit(): void {
    of(true)
      .pipe(
        delay(this.data.seconds * 1000),
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.store.dispatch(haltSpinner());
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
