/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ChangeDetectionStrategy, OnDestroy, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/app.state';
import { loadGifts } from '../../../store/gifts/gifts.actions';
import { selectGifts, selectGiftsIsLoaded } from '../../../store/gifts/gifts.selectors';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { DualSpinnerService } from '../../components/dual-spinner/dual-spinner.service';

@Component({
  selector: 'app-send-gift',
  templateUrl: './send-gift.component.html',
  styleUrls: ['./send-gift.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SendGiftComponent implements OnDestroy, AfterViewInit {
  @ViewChild('spinnedTemplate') spinnedTemplate: ElementRef<HTMLElement>;
  typeGifts = ['All', 'Christmas', 'Drink', 'Flowers', 'Food', 'Jewelry', 'Lifestyle', 'Romantic', 'Sport', 'Toys', 'Transport'];
  selectedTypeGift = this.typeGifts[0];
  gifts$ = this.store.select(selectGifts);

  destroyed$ = new ReplaySubject(1);

  get recipientId() {
    return this.data?.userId ?? undefined;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: string },
    private store: Store<IAppState>,
    private dualSpinnerService: DualSpinnerService,
  ) {
  }

  ngAfterViewInit() {
    this.store.dispatch(loadGifts({}));
    this.dualSpinnerService.startComponentLoading(this.spinnedTemplate);

    this.store.select(selectGiftsIsLoaded)
      .pipe(
        filter(Boolean),
        delay(1000),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.dualSpinnerService.finishComponentLoading(this.spinnedTemplate);
      });
  }

  changeTypeGifts({ value }: MatSelectChange) {
    this.store.dispatch(loadGifts({ giftsType: value }));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
