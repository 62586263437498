<div class="icon-logo" *ngIf="!isSideMenuOpened; else iconpng" routerLink="/" [replaceUrl]="true">
  <!-- fix logo triubles on mobile -->
  <!-- <mat-icon
    svgIcon="internal:logo-new"
    [style.width.px]="iconSize"
    [style.height.px]="iconSize"
  ></mat-icon> -->

  <img src="app/assets/icon/logo.png" alt="burning hearts logo"
    [style.width.px]="iconSize"
    [style.height.px]="iconSize"
  />

  <span [style.font-size.px]="textSize" [style.font-weight]="weidth" *ngIf="isOnlyLogo">
    BurningHearts
  </span>
</div>

<ng-template #iconpng>
  <div class="icon-logo">
    <div class="logo-png" [style.width.px]="iconSize" [style.height.px]="iconSize"></div>
  </div>
</ng-template>
