import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({ })
export class IconsSharedModule {
  icons = [
    'ok',
    'arrow-upward',
    // 'header-user-icon',
    'verification-icon',
    'activity-icon',
    'visitors-icon',
    'gifts-icon',
    'photos-icon',
    'videos-icon',
    // 'likes-icon',
    'wallet-icon',
    'favorites-icon',
    'msg-icon',
    'msg-service',
    'bell-icon',
    'gift-image',
    // 'main-logo',
    'menu',
    'facebook-login-icon',
    'new-user-icon',
    // 'new-man-icon',
    // 'new-woman-icon',
    'new-password-icon',
    'new-location-icon',
    'new-relationship-icon',
    'new-profession-icon',
    'new-life-icon',
    'new-habits-icon',
    'new-interests-icon',
    'new-pets-icon',
    'animals-icon',
    'art-icon',
    'films-icon',
    'music-icon',
    'nature-icon',
    'photo-icon',
    'sport-icon',
    'transportation-icon',
    'travel-icon',
    'add-friends-icon',
    'remove-friends-icon',
    'are-friends-icon',
    // 'accepted-chat-icon',
    'coins-icon',
    // 'upgrade-icon',
    'verify-icon',
    'invisible-icon',
    // 'icon-true',
    'boosting-icon',
    'new-videos-icon',
    'send-msg-icon',
    // 'drop-down-icon',
    // 'burning-hearts-logo',
    'info-icon',
    'resend-icon',
    // 'main-logo-normal',
    'new-search-icon',
    // 'new-search-icon-gray',
    // 'new-plus-icon',
    'remove-icon',
    // 'search-details-icon',
    // 'drop-mat-icon',
    // 'new-discover-icon',
    'new-icon-religion',
    // 'favorites-control',
    'delete-dialog-icon',
    'turn-notifications-icon',
    'new-accept-icon',
    'new-decline-icon',
    'settings-icon',
    'read-msg-icon',
    'smiles-icon',
    'send-message-icon',
    'purchase',
    'upload-icon',
    'banned-icon-1',
    'banned-icon-2',
    'banned-icon-3',
    'banned-icon-4',
    'banned-icon-5',
    'banned-icon-6',
    'instructions-icon',
    'instructions-icon-1',
    'instructions-icon-2',
    'change-photo-icon',
    'log-out-icon',
    'icon-sm-fav',
    'icon-sm-gift',
    'icon-sm-like',
    'icon-sm-chat',
    'icon-sm-visitor',
    'accept-invite-chat',
    'decline-invite-chat',
    // 'delete-msg-icon',
    // 'help-icon',
    'plus-v',
    'okey-v',
    // 'info',
    'info-material',
    'quotes',
    'logo-new',
    // 'verify-user',
    'pen',
    'heart',
    'right-slide',
    'left-slide',
    'left',
    'play',
    // 'info-1',
    'delete-1',
    'more-1',
    // 'search',
    'translate',
    'alert-triangle'
  ];

  constructor(
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer
  ) {
    this.icons.forEach(iconName => iconRegistry.addSvgIconInNamespace(
      'internal',
      iconName,
      sanitizer.bypassSecurityTrustResourceUrl(`app/assets/svg/${iconName}.svg`)
    ));

    iconRegistry.addSvgIconInNamespace(
      'notification',
      'check',
      sanitizer.bypassSecurityTrustResourceUrl('app/assets/svg/notification/check.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'notification',
      'cancel',
      sanitizer.bypassSecurityTrustResourceUrl('app/assets/svg/notification/cancel.svg')
    );
  }
}
