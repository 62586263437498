/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as UsersAction from './user.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ProfilesAction from '../profile/profile.actions';
import { EMPTY, forkJoin, of, throwError } from 'rxjs';
import { IAppState } from '../app.state';
import { Store } from '@ngrx/store';
import { selectUserProfileCoverPhoto, selectUserProfilePhoto } from './user.selectors';
import { MyProfileService } from '../../shared/services';
import { appNotifierError, appNotifierSuccess } from '../app-notifier/app-notifier.actions';
import { UserService } from './user.service';
import { ProfilePhotoService } from '../../modules/profile-page/services/profile-photo.service';
import { NewWalletService } from 'src/app/modules/profile-page/my-profile/wallet/new-wallet.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private newWalletService: NewWalletService,
    private profilePhotoService: ProfilePhotoService,
    private myProfileService: MyProfileService,
    private store: Store<IAppState>,
    private userService: UserService,
  ) {
  }

  resetProfilePhoto$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.updateProfilePhoto),
    switchMap(({ profilePhoto }) => [ProfilesAction.updateProfilePhoto({ profilePhoto })]),
    catchError(err => throwError(err))
  ));

  uploadProfilePhoto$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.uploadProfilePhoto),
    concatLatestFrom(() => this.store.select(selectUserProfilePhoto)),
    switchMap(([{ blobPhoto }, previousAvatar]) =>
      this.profilePhotoService.uploadProfilePhoto(blobPhoto, 'avatar', previousAvatar)
    ),
    switchMap(({ url }) => [
      appNotifierSuccess('Avatar changed successfully'),
      UsersAction.updateProfilePhoto({ profilePhoto: url })
    ]),
    catchError(err => of(appNotifierError(err.data ?? 'Error uploading avatar')))
  ));

  uploadProfileCoverPhoto$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.uploadProfileCoverPhoto),
    concatLatestFrom(() => this.store.select(selectUserProfileCoverPhoto)),
    switchMap(([{ blobPhoto }, previousCoverPhoto]) =>
      this.profilePhotoService.uploadProfilePhoto(blobPhoto, 'cover', previousCoverPhoto)
    ),
    switchMap(({ url }) => [
      appNotifierSuccess('Cover photo changed successfully'),
      UsersAction.updateProfileCoverPhoto({ profileCoverPhoto: url })
    ]),
    catchError(err => of(appNotifierError(err.data ?? 'Error uploading cover photo')))
  ));

  getUserPaymentHistory$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getPaymentHistory),
    switchMap(() => this.newWalletService.getPaymentsHistory()),
    map(history => UsersAction.updatePaymentHistory({ history }))
  ));

  getUserVirtualPurchaseHistory$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getVirtualPurchaseHistory),
    switchMap(() => this.newWalletService.getVirtualPaymentsHistory()),
    map(history => UsersAction.updateVirtualPurchaseHistory({ history }))
  ));

  getAllPaymentHistory$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getAllPaymentHistory),
    switchMap(() => this.newWalletService.getAllPaymentHistory()),
    map((history) => UsersAction.updatePaymentHistory({ history }))
  ));

  getUserFavourites$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getUserFavourites),
    switchMap(() => this.myProfileService.getMyFavourites()),
    map(favourites => UsersAction.updateUserFavourites({ favourites }))
  ));

  getUserSuggestions$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getUserSuggestions),
    switchMap(() => this.myProfileService.getMySuggestions()),
    map(suggestions => UsersAction.updateUserSuggestions({ suggestions }))
  ));

  getUserBlockeds$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getUserBlockeds),
    switchMap(() => this.myProfileService.getMyBlockedUsers()),
    map(blockeds => UsersAction.updateUserBlockeds({ blockeds }))
  ));

  getUserSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getUserSubscription),
    switchMap(() => this.newWalletService.getUserSubscription()),
    map(subscription => UsersAction.updateUserSubscription({ subscription }))
  ));

  loadProfileVerification$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.loadProfileVerification),
    switchMap(() => this.userService.loadProfileVerification().pipe(
      map(({ verified }) => UsersAction.loadProfileVerificationSuccess({ verified })),
      catchError(() => EMPTY)
    ))
  ));

  toggleUserStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.toggleUserStatus),
    switchMap(() => this.myProfileService.changeStatus()),
    switchMap(({ isOnline }) => [
      appNotifierSuccess(`Now you are ${isOnline ? 'Online' : 'Offline'}`),
      UsersAction.updateUserStatus({ isOnline })
    ]),
    catchError(err => of(appNotifierError(err.data ?? 'Error switch status')))
  ));

  getUserStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UsersAction.getUserStatus),
    switchMap(() => this.myProfileService.checkStatus()),
    map(({ isOnline }) => UsersAction.updateUserStatus({ isOnline }))
  ));
}
