import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-gender-icon',
  templateUrl: './gender-icon.component.html',
  styleUrls: ['./gender-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenderIconComponent {
  @Input() gender: string;
  @Input() useStyle: object;
  constructor() { }
}
