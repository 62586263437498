<div class="langs-right">
  <a class="pointer"
     (click)="changeLang(languages.english)"
     [class.active]="selectedLanguage === languages.english"
  >
    English
  </a>
  &nbsp;&nbsp;
  <a class="pointer"
     (click)="changeLang(languages.dutch)"
     [class.active]="selectedLanguage === languages.dutch"
  >
    Nederlands
  </a>
</div>
