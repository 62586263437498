/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import {
  DELETE_MANY_MESSAGES,
  DELETE_SINGLE_MESSAGE,
  GET_MESSAGES,
  GET_MESSAGES_USER,
  GET_NOTIFICATION,
  MARK_CHAT_READ,
  NEW_INVITES,
  NEW_NOTIFICATION,
  PROFILE_UPDATED,
  USER_ONLINE
} from '../constants';
import * as socketio from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { ActiveUserService } from './active-user.service';
import { IUser } from '../entities';
import { isPlatformBrowser } from '@angular/common';
import { ReplaySubject, EMPTY, Observable } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { IAppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { updateChat } from '../../store/chat-messages/chat-messages.actions';
import {
  loadSummaryNotificationSuccess,
  loadUserNotifications,
  loadUserNotificationsSuccess
} from '../../store/notification/notification.actions';
import { ISummary } from '../../store/notification/notification.state';
import { getUserStatus } from '../../store/user/user.actions';
import { IGetMessages } from '../models/messages/i-get-messages';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InvitesDialogPage } from '../modals/invites-dialog/invites-dialog.component';
import { IUserModel } from 'src/app/store/user/user.state';

export enum ESocketEvents {
  authenticated = 'authenticated',
  unauthorized = 'unauthorized',
  connect = 'connect',
  disconnect = 'disconnect'
}

@Injectable()
export class SocketIoService implements OnDestroy {
  socket;
  user: IUser;
  authenticated = false;
  destroyed$ = new ReplaySubject(1);
  platform;

  constructor(@Inject(PLATFORM_ID) platformId: Object,
    private activeUser: ActiveUserService,
    private store: Store<IAppState>,
    private dialog: MatDialog
  ) {
    this.platform = platformId;
  }

  socketInit() {
    if (isPlatformBrowser(this.platform)) {
      this.user = this.activeUser.user;
      this.socket = socketio.connect(`${environment.wsServerUrl}/authenticated`);
      this.socket.on(ESocketEvents.connect, () => {
        if (this.user._id) {
          this.authenticate();
        }
      });

      this.activeUser.user$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(user => {
          this.user = user;
          if (user._id) {
            this.authenticate();
          } else {
            this.authenticated = false;

            if (this.socket) {
              this.socket.disconnect();
            }
          }
        });

      this.listen(ESocketEvents.disconnect)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.authenticated = false);

      this.listen(PROFILE_UPDATED)
        .pipe(
          switchMap((profile: { _id: string }) => {
            if (this.user._id && this.user._id === profile._id) {
              return this.activeUser.getUser();
            } else {
              return EMPTY;
            }
          }),
          takeUntil(this.destroyed$)
        ).subscribe();

      this.listen(NEW_NOTIFICATION)
        .pipe(
          filter(v => !!v),
          takeUntil(this.destroyed$)
        ).subscribe((ntf: ISummary) => {
          const newNotify = Object.values(ntf).filter(v => v > 0).length;
          if (newNotify) {
            if(ntf.messages > 0 || ntf.invites > 0) {
              this.getMyMessages();
            }
            // this.store.dispatch(loadUserNotifications());
            this.getMyNotifications();
          }
          this.store.dispatch(loadSummaryNotificationSuccess({ summary: ntf }));
        });

      this.listen(GET_NOTIFICATION)
        .pipe(
          filter(v => !!v),
          takeUntil(this.destroyed$)
        ).subscribe((userNotifications: []) => {
          this.store.dispatch(loadUserNotificationsSuccess({ userNotifications }));
        });

      this.listen(GET_MESSAGES_USER)
        .pipe(
          filter(v => !!v),
          takeUntil(this.destroyed$)
        ).subscribe((data: {chat: IGetMessages}) => {
          this.store.dispatch(updateChat(data));
        });

      this.listen(USER_ONLINE)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.store.dispatch(getUserStatus()));

      this.listen(NEW_INVITES)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data: { user: IUserModel }) => {
          this.dialog.open(InvitesDialogPage, {
            panelClass: 'invites-dialog-container',
            data: { me: this.activeUser.user, user: data.user }
          }).afterClosed().subscribe(() => {
            this.getMyMessages();
          });
        });
    }
  }

  authenticate() {
    if (!this.authenticated) {
      this.authenticated = true;

      if (this.socket.disconnected) {
        this.socket.connect();
      }

      this.socket.emit('authenticate', { token: this.user.token })
        .on(ESocketEvents.authenticated, () => { })
        .on(ESocketEvents.unauthorized, () => { });
    }
  }

  getMyNotifications() {
    this.socket.emit(GET_NOTIFICATION, { _id: this.user._id });
  }

  getMyMessages() {
    this.socket.emit(GET_MESSAGES, { _id: this.user._id });
  }

  getMessagesUser(_id) {
    this.socket.emit(GET_MESSAGES_USER, { _id });
  }

  deleteManyMessages(id: string, msg: any) {
    this.socket.emit(DELETE_MANY_MESSAGES, { chatId: id, messages: msg });
  }

  deleteSingleMessage(id: string, message_id: any) {
    this.socket.emit(DELETE_SINGLE_MESSAGE, { chatId: id, message: message_id });
  }

  markChatRead(chatId: string, messageId: string) {
    this.socket.emit(MARK_CHAT_READ, { chatId, messageId });
  }

  listen(eventName: string) {
    return new Observable(subscriber => {
      this.socket.on(eventName, data => {
        subscriber.next(data);
      });
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
