import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActiveUserService } from '../services';
import { of } from 'rxjs';
import { IAppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { selectUserAuth } from '../../store/user/user.selectors';

@Injectable()
export class AuthorisationGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private activeUserService: ActiveUserService,
    private store: Store<IAppState>,
  ) { }

  canActivate() {
    return this.store.select(selectUserAuth).pipe(
      switchMap((isAuth: boolean) => isAuth
        ? of(true)
        : this.activeUserService.getUser()
          .pipe(map(user => {
            // eslint-disable-next-line no-underscore-dangle
            if (user._id) {
              return true;
            }
            this.router.navigate(['/']).then();
            return false;
          }))
      ),
      catchError(err => {
        this.router.navigate(['/']).then();
        console.error('There was an error on loading the user', err);
        return of(false);
      })
    );
  }

  canLoad() {
    return this.canActivate();
  }
}
