import { getAllPaymentHistory } from '../../../../store/user/user.actions';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CoinProduct } from 'src/app/shared/models/products/coin';
import { ServiceProduct } from 'src/app/shared/models/products/service';
import { IAppState } from 'src/app/store/app.state';
import { updateUserSubscription } from 'src/app/store/user/user.actions';
import { ISubscription, IPaymentHistory, IVirtualPayment } from 'src/app/store/user/user.state';
import { Product } from 'src/app/shared/models/products/product';

@Injectable({
  providedIn: 'root'
})
export class NewWalletService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<IAppState>
  ) { }

  getProductById(productId: string): Observable<Product>{
    return this.httpClient.get<any>(`/api/products/${productId}`);
  }

  getCoinProducts(): Observable<CoinProduct[]> {
    return this.httpClient.get<any[]>('/api/products/coins');
  }

  getServicesProducts(): Observable<ServiceProduct[]> {
    return this.httpClient.get<any[]>('/api/products/services');
  }

  purchaseProduct(productId: string) {
    return this.httpClient.post<ISubscription>('/api/purchase', { productId }).pipe(
      tap(subscription => this.store.dispatch(updateUserSubscription({ subscription })))
    );
  }

  getUserSubscription() {
    return this.httpClient.get<ISubscription>('/api/subscriptions/me');
  }

  getPaymentsHistory() {
    return this.httpClient.get<IPaymentHistory[]>('/api/subscriptions/me/payments');
  }

  getVirtualPaymentsHistory() {
    return this.httpClient.get<IVirtualPayment[]>('/api/subscriptions/me/virtual-payments');
  }

  getAllPaymentHistory() {
    return this.httpClient.get<IPaymentHistory[]>('/api/subscriptions/me/all-payments');
  }
}
