/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectorRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Component,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-base-page',
  templateUrl: 'base-page.component.html',
  styleUrls: ['base-page.component.scss'],
})
export class BasePageComponent implements OnInit {
  article: any;
  articleEnglish: any;
  articleDutch: any;
  selectedLanguage = 'eng';

  get html() {
    if (this.selectedLanguage === 'dutch') {
      return this.articleDutch.html ? this.articleDutch.html : '';
    } else {
      return this.articleEnglish.html ? this.articleEnglish.html : '';
    }
  }

  get title() {
    return this.selectedLanguage === 'dutch'
      ? this.articleDutch.title
      : this.articleEnglish.title;
  }

  constructor(
    @Inject(PLATFORM_ID) protected platformId: Object,
    protected route: ActivatedRoute,
    protected cdr: ChangeDetectorRef,
    protected router: Router
  ) // private loaderService: LoaderService
  {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((params) => params.get('path')),
        filter((v) => !!v)
      )
      .subscribe((url) => {
        this.router.navigate(['/page', url], { replaceUrl: true });
      });

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.route.data.subscribe(
      (data) => {
        if (data && data.page) {
          this.articleEnglish = data.page[0];
          this.articleDutch = data.page[1];
          this.cdr.detectChanges();
        }
      },
      (err) => console.error(err)
    );
  }

  // ngAfterViewInit(): void {
  //   this.loaderService.FirstPartOfPageContentLoaded.emit(true);
  // }

  changeLang(newLang: string = 'eng') {
    this.selectedLanguage = newLang;
    this.cdr.detectChanges();
  }

  goHome() {
    this.router.navigate(['/'], { replaceUrl: true });
  }
}
