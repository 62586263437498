import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DualSpinnerService } from './dual-spinner.service';

@Component({
  selector: 'app-dual-spinner',
  templateUrl: './dual-spinner.component.html',
  styleUrls: ['./dual-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DualSpinnerComponent implements AfterViewInit {
  @ViewChild('componentSpinner') componentSpinner: ElementRef<HTMLElement>;
  isLoading$ = this.dualSpinnerService.isLoading$;

  constructor(private dualSpinnerService: DualSpinnerService) {}

  ngAfterViewInit() {
    this.componentSpinner.nativeElement.setAttribute('style', 'display: none');
    this.dualSpinnerService.setLocalSpinnerTemplate(this.componentSpinner);
  }
}
