import { createAction, props } from '@ngrx/store';
import { IGift, IReceiveGift, ISentGift } from './gifts.state';

export const loadGifts = createAction(
  '[Gifts] Load Gifts',
  props<{ giftsType?: string }>()
);
export const loadReceivedGifts = createAction('[Gifts] Load Received Gifts');
export const loadSentGifts = createAction('[Gifts] Load Sent Gifts');

export const updateGifts = createAction('[Gifts] Update Gifts', props<{ gifts: IGift[] }>());
export const updateGiftsFails = createAction('[Gifts] Update Gifts Fails');

export const updateReceivedGifts = createAction('[Gifts] Update Received Gifts', props<{ receivedGifts: IReceiveGift[] }>());
export const updateSentGifts = createAction('[Gifts] Update Sent Gifts', props<{ sentGifts: ISentGift[] }>());

export const sendGift = createAction('[Gifts] Send gift to user', props<{ userId: string; giftId: string }>());
export const sendGiftSuccess = createAction('[Gifts] Gift sent successfully');
