export const CHAT_NODE = 'chat-messages';

import { IShortUser } from 'src/app/shared/models/IUser';

export interface IGetMessages {
  _id: string;
  user: IShortUser;
  favourited: boolean;
  blocked: boolean;
  accepted: boolean;
  messages: ChatMessage[];
  blockedByMe: boolean;
  disabled: boolean;
}

export interface ChatMessage {
  _id: string;
  author: string;
  date: Date;
  content: string;
  read: boolean;
  translate: string;
}

export const initialChatState: IGetMessages = {
  _id: '',
  user: null,
  favourited: false,
  blocked: false,
  accepted: false,
  messages: [],
  blockedByMe: false,
  disabled: false,
};
