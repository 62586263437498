import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INotificationState, NOTIFICATION_NODE } from './notification.state';

const selectNotificationFeature = createFeatureSelector<INotificationState>(NOTIFICATION_NODE);

export const selectSummaryNotification = createSelector(
  selectNotificationFeature,
  (state) => state.summary
);

export const selectUserNotifications = createSelector(
  selectNotificationFeature,
  (state) => state.userNotifications
);
