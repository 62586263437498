<div
  class="upload-wrapper pb-24"
  fxLayout="column"
  fxLayoutAlign="space-between"
  #spinnedTemp
>
  <div>
    <input
      #_fileInput
      (change)="fileChangeEvent($event)"
      type="file"
      id="upload-input"
      accept="image/*"
      hidden
    />

    <div *ngIf="cropperReady" fxLayout="row" fxLayoutAlign="center center">
      <button mat-icon-button (click)="zoomIn()">
        <mat-icon matTooltip="Zoom In">zoom_in</mat-icon>
      </button>

      <button mat-icon-button (click)="zoomOut()">
        <mat-icon matTooltip="Zoom Out">zoom_out</mat-icon>
      </button>

      <button mat-icon-button (click)="rotateLeft()">
        <mat-icon matTooltip="Rotate Left">rotate_left</mat-icon>
      </button>

      <button mat-icon-button (click)="rotateRight()">
        <mat-icon matTooltip="Rotate Right">rotate_right</mat-icon>
      </button>

      <button mat-icon-button (click)="flipHorizontal()">
        <mat-icon matTooltip="Flip Horizontal">flip</mat-icon>
      </button>

      <button mat-icon-button (click)="flipVertical()">
        <mat-icon class="flip-vertical" matTooltip="Flip Vertical"
          >flip</mat-icon
        >
      </button>

      <button mat-icon-button (click)="resetImage()">
        <mat-icon matTooltip="Clear All Layers">layers_clear</mat-icon>
      </button>
    </div>

    <image-cropper
      #cropper
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="onCropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [imageURL]="photoUrl"
      [transform]="transform"
      [onlyScaleDown]="false"
      [autoCrop]="true"
      [resizeToHeight]="cropConfig.cropperMaxWidth"
      [resizeToWidth]="cropConfig.cropperMaxWidth"
      [containWithinAspectRatio]="cropConfig.containWithinAspectRatio"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="cropConfig.maintainAspectRatio"
      [aspectRatio]="cropConfig.width / cropConfig.height"
      [roundCropper]="cropConfig.roundCropper"
      [style.display]="cropperReady ? null : 'none'"
      alignImage="center"
      format="png"
      [imageQuality]="100"
      class="cropper m-auto"
    ></image-cropper>
  </div>

  <!--
  [style.height.px]="cropConfig.height || 400"
  [style.width.px]="cropConfig.width || 400"
-->

  <div class="custom-droparea" *ngIf="!cropperReady">
    <div class="droparea-icon" (click)="_fileInput.click()">
      <mat-icon
        class="upload-icon"
        svgIcon="internal:upload-icon"
        aria-hidden="false"
      ></mat-icon>
    </div>

    <div class="text">
      <!-- Post here your photo<br> -->
      <!-- <span><a (click)="_fileInput.click()">or browse</a> in a file for uploading</span> -->
      <span
        ><a (click)="_fileInput.click()">Browse</a> in a file for
        uploading</span
      >
    </div>
  </div>

  <div>
    <h5 class="warning-item aligncenter" *ngIf="errorMsg">
      {{ errorMsg }}
    </h5>

    <div
      *ngIf="cropperReady"
      class="layout-slider"
      fxLayoutAlign="center center"
    >
      <mat-slider
        (input)="scaleChanged($event)"
        [(value)]="scale"
        [max]="maxScale"
        [min]="minScale"
        color="primary"
        aria-label="unit(s)"
        step="0.1"
      ></mat-slider>
    </div>

    <div
      class="options"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
    >
      <button
        #savePhoto
        (click)="onSavePhoto()"
        [disabled]="!cropperReady"
        class="bh-primary-corner"
        mat-button
        [class.pointer-none]="isSaveLoading"
      >
        <span *ngIf="!isSaveLoading">
          Save changes
        </span>
        <app-loader-spinner
          *ngIf="isSaveLoading"
          class="load-spin-container"
          [size]="'md'"
          [colorMode]="'white'"
        />
      </button>

      <button
        *ngIf="!photoUrl"
        (click)="_fileInput.click()"
        mat-button
        class="bh-ghost"
      >
        Upload new file
      </button>

    </div>
  </div>
</div>

<app-dual-spinner></app-dual-spinner>
