
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppNotifierService } from './app-notifier.service';
import { appNotifierError, appNotifierInfo, appNotifierSuccess } from './app-notifier.actions';
import { debounceTime, tap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class AppNotifierEffects {

  appNotifierSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(appNotifierSuccess),
    tap(() => this.snackBar.dismiss()),
    debounceTime(1000),
    tap(({ msg }) => this.appNotificationService.success(msg))
  ), { dispatch: false });

  appNotifierError$ = createEffect(() => this.actions$.pipe(
    ofType(appNotifierError),
    tap(() => this.snackBar.dismiss()),
    debounceTime(1000),
    tap(({ msg }) => this.appNotificationService.error(msg))
  ), { dispatch: false });

  appNotifierInfo$ = createEffect(() => this.actions$.pipe(
    ofType(appNotifierInfo),
    tap(() => this.snackBar.dismiss()),
    debounceTime(1000),
    tap(({ msg }) => this.appNotificationService.info(msg))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private appNotificationService: AppNotifierService,
    private snackBar: MatSnackBar
  ) { }

}
