import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
  public FirstPartOfPageContentLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dummyServiceFunc(initiator: string): void {
    console.log('initiator : ', initiator);
  }
}

