import '@stripe/stripe-js/pure';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { APP_BASE_HREF } from '@angular/common';

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/app/' },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (activeUserService: ActiveUserService) => () => activeUserService.init(),
    //   deps: [ActiveUserService],
    //   multi: true
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule { }
