import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileMediaService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getPhotos(_id: string) {
    return this.httpClient.get<any>(`/api/photos/${  _id}`);
  }

  likePhoto(userId: string, photoId: string) {
    return this.httpClient.post(`/api/photos/${ userId }/like/${ photoId }`, null);
  }

  getVideos(_id: string) {
    return this.httpClient.get<any>(`/api/videos/${ _id }`);
  }
}
