import { Action, ActionReducerMap } from '@ngrx/store';
import { IAppState } from './app.state';
import { ROUTER_NODE } from './router/router.state';
import { routerReducer } from '@ngrx/router-store';
import { USER_NODE } from './user/user.state';
import { userReducer } from './user/user.reducers';
import { PROFILE_NODE } from './profile/profile.state';
import { profileReducer } from './profile/profile.reducers';
import { SPINNER_NODE } from './spinner/spinner.state';
import { spinnerReducer } from './spinner/spinner.reducer';
import { GIFTS_NODE } from './gifts/gifts.state';
import { giftsReducer } from './gifts/gifts.reducers';
import { MEDIA_NODE } from './media/media.state';
import { mediaReducer } from './media/media.reducers';
import { NOTIFICATION_NODE } from './notification/notification.state';
import { notificationReducer } from './notification/notification.reducers';
import { IMPORTANT_MESSAGES_NODE } from './important-messages/important-messages.state';
import { messagesReducer } from './important-messages/important-messages.reducers';
import { chatReducer } from './chat-messages/chat-messages.reducers';
import { CHAT_NODE } from './chat-messages/chat-messages.state';

export const appReducers: ActionReducerMap<IAppState, Action> = {
  [ROUTER_NODE]: routerReducer,
  [USER_NODE]: userReducer,
  [PROFILE_NODE]: profileReducer,
  [SPINNER_NODE]: spinnerReducer,
  [GIFTS_NODE]: giftsReducer,
  [MEDIA_NODE]: mediaReducer,
  [NOTIFICATION_NODE]: notificationReducer,
  [IMPORTANT_MESSAGES_NODE]: messagesReducer,
  [CHAT_NODE]: chatReducer,
};
