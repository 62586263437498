/* eslint-disable no-underscore-dangle */
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';
import { ProfileService } from 'src/app/store/profile/profile.service';
import { IUserModel } from 'src/app/store/user/user.state';
import { MessagesService } from '../../services';
import { BlockUserModalComponent } from '../block-user/block-user.component';

@Component({
  selector: 'app-invites-dialog',
  templateUrl: 'invites-dialog.component.html',
  styleUrls: ['./invites-dialog.component.scss'],
})
export class InvitesDialogPage {
  invites: IUserModel;
  me: IUserModel;
  message = 'Invite you in chat';
  url: string;
  destroyed$ = new ReplaySubject(1);

  innerStyle = {
    width: '130px',
    height: '130px',
    position: 'absolute',
    top:'0',
    border: '6px solid #fff',
    'border-radius': '70px',
    'background-color': '#fff'
  };

  meStyle = {
    ...this.innerStyle,
    left: '19%',
  };

  invitesStyle = {
    ...this.innerStyle,
    right: '17%'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { me: IUserModel; user: IUserModel },
    private dialogRef: MatDialogRef<InvitesDialogPage>,
    private messagesService: MessagesService,
    private router: Router,
    private profileService: ProfileService,
    private dialog: MatDialog
  ) {
    if(data){
      this.me = data.me;
      this.invites = data.user;
    }
  }

  acceptDialog() {
    this.messagesService.acceptDialog(this.invites._id).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        //this.router.navigateByUrl(`my-profile/chat/${msgID}`).then();
        this.dialogRef.close(res);
      }
    });
  }

  declineInvite() {
    this.dialog.open(BlockUserModalComponent, {
      data: {
        image: this.invites.profilePhoto, name: this.invites.name, gender: this.invites.gender, title: 'Declined the chat with ',
        blocked: false,
        type: 'chat'
      }
    }).afterClosed().pipe(
        filter(v => !!v),
        switchMap(_ => this.profileService.blockProfile(this.invites._id, true, 'declined invite')),
        filter(v => !!v),
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.dialogRef.close(false);
        this.router.navigateByUrl('my-profile/chat').then();
      });
  }
}
