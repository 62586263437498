import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { IAppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { selectUserProfileProgress } from '../../../store/user/user.selectors';
import { selectProfileVerificationProgress } from '../../../store/profile/profile.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile-progress-bar',
  templateUrl: './profile-progress-bar.component.html',
  styleUrls: ['./profile-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileProgressBarComponent implements OnInit {
  @Input() isMyProfile = true;

  profilePercent$: Observable<number>;

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
    this.profilePercent$ = this.isMyProfile
      ? this.store.select(selectUserProfileProgress)
      : this.store.select(selectProfileVerificationProgress);
  }
}
