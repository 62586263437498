export  const USER_REGISTERED_FRONTEND = 'USER_REGISTERED';
export  const USER_CREATED = 'USER_CREATED';
export  const USER_UPDATED = 'USER_UPDATED';
export  const USER_PASSWORD_CHANGED = 'USER_PASSWORD_CHANGED';
export  const USER_DELETED = 'USER_DELETED';
export  const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export  const USER_PASSWORD_RESET_COMPLETED = 'USER_PASSWORD_RESET_COMPLETED';
export  const USER_LOGIN_SUCCESSFUL = 'USER_LOGIN_SUCCESSFUL';
export  const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export  const USER_LOGIN_ATTEMPT = 'USER_LOGIN_ATTEMPT';
export  const USER_ONLINE = 'USER_ONLINE';
