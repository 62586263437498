import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ServiceProduct } from '../../models/products/service';
import { IAppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { selectUserSubscription } from '../../../store/user/user.selectors';
import { getUserSubscription } from '../../../store/user/user.actions';

@Component({
  selector: 'app-remain-of-gifts',
  templateUrl: './remain-of-gifts.component.html',
  styleUrls: ['./remain-of-gifts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemainOfGiftsComponent implements OnInit {
  subscription$ = this.store.select(selectUserSubscription);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ServiceProduct,
    private store: Store<IAppState>
  ) { }

  ngOnInit() {
    this.store.dispatch(getUserSubscription());
  }
}
