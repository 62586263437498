import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { HttpCacheManager, withCache } from '@ngneat/cashew';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IMedia } from './media.state';

@Injectable({ providedIn: 'root' })
export class MediaService {
  httpUploadOptions = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
      accept: 'application/json',
    })
  };

  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private manager: HttpCacheManager
  ) { }

  getMyPhotos = () => this.httpClient.get<{ items: IMedia[]; userId: string}>('/api/photos/me');
  getMyVideos = () => this.httpClient.get<IMedia[]>('/api/videos/me');

  uploadPhoto(file: Blob): Observable<IMedia[]> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<IMedia[]>(environment.cdn.photoUrl, formData, this.httpUploadOptions);
  }

  uploadVideo(file: File): Observable<IMedia[]> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<IMedia[]>(environment.cdn.videoUrl, formData, this.httpUploadOptions);
  }

  loadPhoto = (photoUrl: string): Observable<Blob> =>
    this.httpClient.get(photoUrl, {
      responseType: 'blob',
      ...withCache({
        cache$: true,
        key$: this.getPhotoNameFromUrl(photoUrl)
      })
    });

  loadVideo = (videoUr: string): Observable<Blob> =>
    this.httpClient.get(videoUr, {
      responseType: 'blob',
      ...withCache({
        cache$: true,
        key$: this.getPhotoNameFromUrl(videoUr)
      })
    });

  loadPhotoAsObjectUrl(url: string) {
    return url
      ? this.loadPhoto(url).pipe(
        map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)))
      ) : of(false);
  }

  loadVideoAsObjectUrl(url: string) {
    return url
      ? this.loadVideo(url).pipe(
        map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)))
      ) : of(null);
  }

  savePhoto = (photoUrl: string): Observable<IMedia[]> => this.httpClient.post<IMedia[]>('/api/photos/', { url: photoUrl });
  saveVideo = (videoUrl: string) => this.httpClient.post<boolean>('/api/videos/', { url: videoUrl });
  removePhoto = (id: string) => this.httpClient.delete<string>(`/api/photos/${id}`);
  removeVideo = (id: string) => this.httpClient.delete<string>(`/api/videos/${id}`);

  editPhoto(file: Blob, name: string): Observable<boolean> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.put<boolean>(`${environment.cdn.photoUrl}/${ name }`, formData, this.httpUploadOptions).pipe(
      tap(() => this.manager.delete(name))
    );
  }

  getGuestPhotos = (_id: string) => this.httpClient.get<any>(`/api/photos/${  _id}`);
  getGuestVideos = (_id: string) => this.httpClient.get<any>(`/api/videos/${ _id }`);

  likeGuestPhoto = (userId: string, photoId: string) => this.httpClient.post(`/api/photos/${ userId }/like/${ photoId }`, null);

  getPhotoNameFromUrl = (photoUrl: string): string => photoUrl.split('/').pop();

  loadingProfilesPhoto(likedUsers: Array<string>): Observable<Array<{userId: string; profilePhoto: string}>> {
    return this.httpClient.post('/api/users/profilesPhoto', { users: likedUsers })
      .pipe(map((data: Array<{userId: string; profilePhoto: string}>) => data));
  }

}
