<section *ngIf="profilePercent$ | async as profilePercent" class="progress-div">
  <div class="info">
    <div class="title">{{ isMyProfile ? 'Your Progress' : 'Progress' }}</div>

    <div class="percent">
      {{ profilePercent }}%
    </div>
  </div>
  <div class="progress-bar-custom progress-bar-container">
    <progress max="100" [value]="profilePercent" class="css3 progress-bar"></progress>
  </div>
</section>
