<div class="payment-dialog">
  <app-close-dialog-button
    appHoverEffect
    mat-dialog-close
  ></app-close-dialog-button>

	<mat-dialog-content>
    <div class="payment-img-content"></div>
    <div class="payment-info">
      <p class="title">{{ message }}</p>
      <p *ngIf="id" class="payment-id">Your order #id{{id.slice(id.length-7, id.length)}}</p>
      <div class="payment-state"
        [style.background-color]="id ? '#E9FACF' : '#ffdedb'"
        [style.color]="id ? '#55c240' : '#E1393F'"
      >{{ state }}</div>
    </div>
	</mat-dialog-content>

	<mat-dialog-actions align="center">
    <div class="actions">
      <button mat-dialog-close
        class="primary-round-button"
      > Back to site </button>
      <div class="info" mat-dialog-close *ngIf="url">
        <a class="info" (click)="openRecept($event)">Payment information</a>
      </div>
    </div>
  </mat-dialog-actions>
</div>
