/* eslint-disable no-underscore-dangle */
import { Action, createReducer, on } from '@ngrx/store';
import { initialMediaState, IMediaState } from './media.state';
import {
  deleteMyMediaPhotoSuccess,
  deleteMyMediaVideoSuccess,
  loadMyMediaVideosSuccess,
  updateMediaPhotos, updateMediaVideos
} from './media.actions';

const reducer = createReducer(
  initialMediaState,
  on(updateMediaPhotos, (state, { photos }) => ({ ...state, photos })),
  on(updateMediaVideos, (state, { videos }) => ({ ...state, videos })),
  on(loadMyMediaVideosSuccess, (state, { videos }) => ({ ...state, videos })),
  on(deleteMyMediaPhotoSuccess, (state, { id }) => ({ ...state, photos: state.photos.filter(photo => photo._id !== id) })),
  on(deleteMyMediaVideoSuccess, (state, { id }) => ({ ...state, videos: state.videos.filter(video => video._id !== id) })),
);

export const mediaReducer = (state: IMediaState, action: Action) => reducer(state, action);
