<div class="min-profile-card" *ngIf="userData">
  <!-- Favorite Icon -->
  <div class="favorites-block" *ngIf="favorites">
    <mat-icon class="icon-favorites" svgIcon="internal:favorites-icon" aria-hidden="false"></mat-icon>
  </div>

  <div class="avatar">
    <app-secure-image
      appHoverEffect
      *ngIf="userData.user.profilePhoto; else noProfilePhoto"
      [src]="userData.user.profilePhoto"
      [routerLink]="'/my-profile/view/' + userData.user._id + '/about'"
      [useStyle]="{ 'width.px': 50, 'height.px': 50, 'border-radius.px': 50 }"
    ></app-secure-image>

    <ng-template #noProfilePhoto>
      <app-gender-icon
        [gender]="userData.user.gender"
        [useStyle]="{ 'width': '50px', 'height': '50px' }"
      ></app-gender-icon>
    </ng-template>

    <div class="new-online" *ngIf="userData.online"></div>
    <!-- Last visit -->
    <!-- <div class="last-visit" *ngIf="!userData.online && userData.lastLogin">
      Last visit {{userData.lastLogin | timeAgo: 'search'}}
      <div class="triangle"></div>
    </div> -->
  </div>

  <div class="user-short-info">
    <div class="user-name"
      [routerLink]="'/my-profile/view/' + userData.user._id + '/about'"
    >{{ userData.user.name }}</div>
    <p class="user-info">
      {{ userData.user.age }} year, {{ userData.user.location }}
    </p>

    <p class="date"
      *ngIf="userData.dateVisited"
      [class.bottom-yes]="userData.user.verified"
    >
      {{ userData.dateVisited | date: "dd MMM YYYY, HH:mm" }}
    </p>
  </div>

  <!-- verification icon -->
  <!-- <div class="user-info"
    *ngIf="userData.user.verified"
    [class.verified]="!favorites" [class.verified-fav]="favorites">
    <div class="verification-user"></div>
    Verified user
  </div> -->

  <div class="min-actions {{ sentRequestToFavorites && !favorites && !isBlocked ? 'bg-white' : '' }}">
    <mat-icon
      *ngIf="favorites && !isBlocked"
      class="icon-sm-chat"
      svgIcon="internal:icon-sm-chat"
      aria-hidden="false"
      (click)="sendMessage()"
    ></mat-icon>

    <mat-icon
      *ngIf="!sentRequestToFavorites && !favorites && !isBlocked"
      class="icon-sm-chat"
      svgIcon="internal:add-friends-icon"
      aria-hidden="false"
      (click)="addFavourite()"
    ></mat-icon>

    <mat-icon
      *ngIf="sentRequestToFavorites && !favorites && !isBlocked"
      class="icon-sm-chat"
      svgIcon="internal:ok"
      aria-hidden="false"
    ></mat-icon>

    <mat-icon
      *ngIf="isBlocked"
      class="icon-sm-chat"
      svgIcon="internal:delete-1"
      aria-hidden="false"
      (click)="unBlock()"
    ></mat-icon>

  </div>
</div>
