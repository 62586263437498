import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IAppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { uploadProfilePhoto } from '../../../store/user/user.actions';
import { avatarCropConfig } from '../cropper.config';

@Component({
  selector: 'app-new-photo-uploader',
  templateUrl: './new-photo-uploader.component.html',
  styleUrls: ['./new-photo-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewPhotoUploaderComponent {
  cropConfig = avatarCropConfig;

  constructor(
    private dialogRef: MatDialogRef<NewPhotoUploaderComponent>,
    private store: Store<IAppState>
  ) {
  }

  onSaveAction(blobPhoto: Blob) {
    this.store.dispatch(uploadProfilePhoto({ blobPhoto }));
    this.dialogRef.close();
  }
}
