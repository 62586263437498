<div class="invites-dialog">
  <app-close-dialog-button
    appHoverEffect
    mat-dialog-close
  ></app-close-dialog-button>

	<mat-dialog-content>
    <div class="invites-img-content">
      <app-secure-image
        appHoverEffect
        *ngIf="me.profilePhoto"
        [src]="me.profilePhoto"
        [useStyle]="meStyle">
      </app-secure-image>
      <div class="icon-style">
        <mat-icon [svgIcon]="'internal:icon-sm-chat'"></mat-icon>
      </div>
      <app-secure-image
        appHoverEffect
        *ngIf="invites.profilePhoto"
        [src]="invites.profilePhoto"
        [useStyle]="invitesStyle">
      </app-secure-image>
    </div>
    <div class="user-info">
      <p class="name"> {{ invites.name }} </p>
      <p class="info"> {{invites.age }} year, {{ invites.location }}</p>
    </div>
    <p>{{ message }}</p>
	</mat-dialog-content>

	<mat-dialog-actions align="center">
		<div class="action">
      <div class="accept pointer" (click)="acceptDialog()">
        <mat-icon class="accept-invite-chat" aria-hidden="false"
          [svgIcon]="'internal:accept-invite-chat'">
        </mat-icon>
      </div>
      <div class="decline pointer" (click)="declineInvite()">
        <mat-icon class="decline-invite-chat" aria-hidden="false"
          [svgIcon]="'internal:decline-invite-chat'">
        </mat-icon>
      </div>
		</div>
  </mat-dialog-actions>
</div>
