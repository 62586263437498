/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Data, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SecureImageService } from 'src/app/shared/services';
import { ContentService } from 'src/app/shared/services/content.service';
import LazyLoad  from 'vanilla-lazyload';
import { Store } from "@ngrx/store";
import { IAppState } from 'src/app/store/app.state';
import { selectUser } from 'src/app/store/user/user.selectors';
import { LoaderService } from 'src/app/shared/services/loader.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  destroyed$ = new ReplaySubject(1);
  config = { replaceUrl: true };

  // TODO add data type
  user;

  data = {
    sectionfirst: {
      featuredImage: 'app/assets/img/main-first-bg-fin.webp'
    },
    sectionthird: {
      featuredImage: 'app/assets/img/new-third-bg.webp'
    },
    sectionfifth: {
      featuredImage: 'app/assets/img/new-fifth-bg.webp'
    },
    sectioneight: {
      featuredImage: 'app/assets/img/new-eight-bg.webp'
    }
  };
  sections = [
    'sectionfirst', 'sectionsecond',
    'sectionthird', 'sectionfourth',
    'sectionfifth', 'sectionsixth',
    'sectionseventh', 'sectioneight'
  ];

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private contentService: ContentService,
    private secureImageService: SecureImageService,
    private store: Store<IAppState>,
    private loaderService: LoaderService
  ) {
    // this.getData();
  }

  ngOnInit() {
    const lazy = new LazyLoad({
      container: document.getElementById('full-page-scroll'),
      class_loaded: 'ng-lazyloaded',
    });

    this.store
      .select(selectUser)
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroyed$)
      )
      .subscribe((user) => {
        this.user = user;
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.loaderService.FirstPartOfPageContentLoaded.emit(true);
  }

  scrollToTop(scrollerElement: HTMLDivElement) {
    scrollerElement.scrollTop = 0;
  }

  async getData() {
    await this.contentService.getHome('Home')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((content: Data[]) => {
        this.sections.forEach(el => {
          const index = content.findIndex(e => e.slug === el);
          if(index !== -1 && this.data[el]) {
            this.data[el] = content[index];
            // this.data[el].featuredImage = this.secureImageService.loadPhotoLazy(content[index].featuredImage);
            this.secureImageService.loadPhotoLazy(content[index].featuredImage).subscribe((image) => {
              this.data[el].featuredImage = image;
            });
          }
        });
    });
  }

  setBG(featuredImage) {
    return featuredImage ? `url("${featuredImage}")` : null;
    // return featuredImage;
  }

  goLogin() {
    this.router.navigate(['/login'], this.config);
  }

  goProfile() {
    this.router.navigate(['/my-profile'], this.config);
  }

  goRegister() {
    this.router.navigate(['/registration'], this.config);
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
