<app-close-dialog-button
  appHoverEffect
  mat-dialog-close
></app-close-dialog-button>

<section #spinnedTemplate>
  <div class="sort-component">
    <h2 fxLayoutAlign="center">
      Send virtual gift
    </h2>

    <div fxLayoutAlign="end">
      <mat-form-field class="sorted-gifts" appearance="outline">
        <mat-select
          [(value)]="selectedTypeGift"
          (selectionChange)="changeTypeGifts($event)"
        >
          <mat-option
            *ngFor="let typeGift of typeGifts"
            [value]="typeGift"
          >
            {{ typeGift }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-content class="list-gifts">
    <div class="my-4" fxLayout="column">
      <app-gift-item
        *ngFor="let gift of (gifts$ | async)"
        [gift]="gift"
        [canSend]="true"
        [recipient]="recipientId"
      ></app-gift-item>
    </div>
  </div>
</section>

<app-dual-spinner></app-dual-spinner>
