import { IProfile, PROFILE_NODE } from './profile.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { calculateProfileProgress } from '../../shared/helpers/calculate-profile-progress';

const selectProfileFeature = createFeatureSelector<IProfile>(PROFILE_NODE);

export const selectProfileVerification = createSelector(
  selectProfileFeature,
  state => ({ ...state.verified })
);

export const selectProfileVerificationProgress = createSelector(
  selectProfileFeature,
  (state) => calculateProfileProgress(state.verified)
);

export const selectProfileVerificationWithPhoto = createSelector(
  selectProfileFeature,
  state => ({ verified: state.verified, profilePhoto: state.profilePhoto })
);

export const selectProfile = createSelector(
  selectProfileFeature,
  state => state
);

export const selectProfileCoverPhoto = createSelector(
  selectProfileFeature,
  state => state.profileCoverPhoto
);

