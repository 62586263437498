/* eslint-disable no-underscore-dangle */
import { IUserModel, USER_NODE } from './user.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { calculateProfileProgress } from '../../shared/helpers/calculate-profile-progress';

const selectUserFeature = createFeatureSelector<IUserModel>(USER_NODE);

export const selectUser = createSelector(
  selectUserFeature,
  state => state
);

export const selectUserAuth = createSelector(
  selectUser,
  (state): boolean => !!state.token && !!state._id
);

export const selectUserProfilePhoto = createSelector(
  selectUser,
  (state): string => state.profilePhoto
);

export const selectUserVerified = createSelector(
  selectUser,
  (state): boolean => state.showVerify
);

export const selectUserId = createSelector(
  selectUser,
  (state) => state._id
);

export const selectPaymentHistory = createSelector(
  selectUser,
  (state) => state.paymentHistory
);

export const selectVirtualPurchaseHistory = createSelector(
  selectUser,
  (state) => state.virtualPurchaseHistory
);

export const selectUserProfileCoverPhoto = createSelector(
  selectUser,
  (state): string => state.profileCoverPhoto
);

export const selectUserSubscription = createSelector(
  selectUser,
  (state) => state.subscription
);

export const selectUserFavourites = createSelector(
  selectUser,
  (state) => state.favourites
);

export const selectUserBlockeds = createSelector(
  selectUser,
  (state) => state.blockeds
);

export const selectUserSuggestions = createSelector(
  selectUser,
  (state) => state.suggestions
);

export const selectUserVerification = createSelector(
  selectUserFeature,
  (state) => state.verified
);

export const selectUserProfileProgress = createSelector(
  selectUserFeature,
  (state) => calculateProfileProgress(state.verified)
);

export const selectUserOnline = createSelector(
  selectUserFeature,
  (state) => state.isOnline
);

export const selectUserVerificationWithPhoto = createSelector(
  selectUserFeature,
  state => ({ verified: state.verified, profilePhoto: state.profilePhoto })
);
