// import exp = require("constants");

export interface ProfileModel {
  gender: string;
  profilePhoto: string;
  description: string;
  location: Locatity;
  relationshipStatus: RelationshipStatus;
  relationshipTypes: RelationshipTypes;
  relationshipTypesAllowed: RelationshipTypesAllowed;
  intent: RelationshipIntent;
  children: Children;
  education: Education;
  occupation: Array<string | Occupation>;
  languages: Array<string>;
  interests: Array<string | Interests>;
  pets: Array<string | Pets>;
  smoking: Smoking;
  photos: Array<string> | string;
  drinking: Drinking;
  religion: Religion;
  videos: Array<string>;
  verified: Verified;
}

export interface Verified {
  approved: boolean;
  complete: boolean;
  email: boolean;
  percent: number;
  personLink: boolean | string;
  photos: boolean | string | Array<string>;
}

export interface Locatity {
  geo?: {
    type: 'Point';
    coordinates: number[];
  };
  country: string;
  city: string;
  postcode: string;
}

export type RelationshipStatus = 'Single' | 'Separated' | 'Divorced' | 'Widowed' | 'In a Relationship' | 'Married' ;
export const RELATIONSHIP_OPTIONS: RelationshipStatus[] = [
  'Divorced', 'In a Relationship', 'Married', 'Separated', 'Single',   'Widowed'];

export type RelationshipTypes = 'Activity partner' | 'Casual' | 'Dating' | 'Fitness' | 'Friends' | 'Long-term'
| 'Marriage' | 'Penpal';
export const RELATIONSHIP_TYPES_OPTIONS: RelationshipTypes[] = [
  'Activity partner', 'Casual', 'Dating', 'Fitness', 'Friends', 'Long-term', 'Marriage', 'Penpal'
];

export type RelationshipTypesAllowed = 'Straight' | 'Gay' | 'Lesbian' | 'Bisexual' | 'Couple';
export const RELATIONSHIP_TYPES_ALLOWED_OPTIONS: RelationshipTypesAllowed[] = [
  'Bisexual',  'Couple', 'Gay', 'Lesbian', 'Straight'
];

export type RelationshipIntent = 'I want a relationship' | 'I am putting in serious effort to find someone' |
'I am serious and I want to find someone to marry' | 'I am looking for casual dating - No commitment'
| 'I want to date but nothing serious';
export const INTENT_TYPES_OPTIONS: RelationshipIntent[] = [
  'I am looking for casual dating - No commitment',
  'I am serious and I want to find someone to marry',
  'I am putting in serious effort to find someone',
  'I want a relationship',
  'I want to date but nothing serious'
];

export declare type Children = 'None' | 'One' | 'Two' | 'Three' | 'Four' | 'More';
export declare const CHILDREN_OPTIONS: string[];

export type Education = 'Bachelor degree' | 'Master degree' | 'Doctorate'  | 'High school'
| 'College' | 'Diploma'   | 'Other' | 'University';

export const EDUCATION_OPTIONS: Education[] = [
  'Diploma', 'College',  'High school', 'University', 'Doctorate', 'Bachelor degree', 'Master degree', 'Other'
];

export type Occupation =
  'Accounting'
  | 'Admin & Clerical'
  | 'Automotive'
  | 'Banking'
  | 'Biotech'
  | 'Broadcast Journalism'
  | 'Business'
  |
  'Development'
  | 'Construction'
  | 'Consultant'
  | 'Customer Service'
  | 'Design'
  | 'Distribution Shipping'
  | 'Education Teaching'
  |
  'Engineering'
  | 'Entry Level New Grad'
  | 'Executive'
  | 'Facilities'
  | 'Finance'
  | 'Franchise'
  | 'General Business'
  | 'General Labor'
  |
  'Government'
  | 'Grocery'
  | 'Health Care'
  | 'Hotel Hospitality'
  | 'Human Resources'
  | 'Information Technology'
  |
  'Installation Maint Repair'
  | 'Insurance'
  | 'Inventory'
  | 'Legal'
  | 'Legal Admin'
  | 'Management'
  | 'Manufacturing'
  | 'Marketing'
  |
  'Media Journalism Newspaper'
  | 'Nonprofit Social Services'
  | 'Nurse'
  | 'Pharmaceutical'
  | 'Professional Services'
  |
  'Purchasing Procurement'
  | 'QA Quality Control'
  | 'Real Estate'
  | 'Research'
  | 'Restaurant Food Service'
  | 'Retail'
  | 'Sales'
  |
  'Science'
  | 'Skilled Labor Trades'
  | 'Strategy Planning'
  | 'Supply Chain'
  | 'Telecommunications'
  | 'Training'
  |
  'Transportation'
  | 'Warehouse'
  | 'Other';

export const OCCUPATION_OPTIONS: Occupation[] = [
  'Accounting', 'Admin & Clerical', 'Automotive', 'Banking', 'Biotech', 'Broadcast Journalism', 'Business', 'Construction',
  'Consultant', 'Customer Service',
  'Development',  'Design', 'Distribution Shipping', 'Education Teaching',
  'Engineering', 'Entry Level New Grad', 'Executive', 'Facilities', 'Finance', 'Franchise', 'General Business', 'General Labor',
  'Government', 'Grocery', 'Health Care', 'Hotel Hospitality', 'Human Resources', 'Information Technology',
  'Installation Maint Repair', 'Insurance', 'Inventory', 'Legal', 'Legal Admin', 'Management', 'Manufacturing', 'Marketing',
  'Media Journalism Newspaper', 'Nonprofit Social Services', 'Nurse', 'Pharmaceutical', 'Professional Services',
  'Purchasing Procurement', 'QA Quality Control', 'Real Estate', 'Research', 'Restaurant Food Service', 'Retail', 'Sales',
  'Science', 'Skilled Labor Trades', 'Strategy Planning', 'Supply Chain', 'Telecommunications', 'Training',
  'Transportation', 'Warehouse', 'Other'
];

// export type Languages = 'Arabic' | 'Chinese' | 'English' | 'French' | 'German' | 'Hindi' | 'Italian' | 'Japanese' | 'Portuguese' |
//   'Russian' | 'Spanish' | 'Turkish' | 'Vietnamese' | 'Other';
export const LANGUAGES_OPTIONS: string[] = [
  'Bulgarian', 'Chinese', 'Croatian', 'Czech',  'Danish', 'Dutch', 'English', 'Finnish', 'French', 'German', 'Greek',
  'Hebrew', 'Hungarian', 'Italian', 'Japanese', 'Norwegian', 'Polish', 'Portuguese', 'Rumanian', 'Russian', 'Slovene',
  'Spanish', 'Swedish', 'Ukrainian'
];

export type Interests =
  'Music'
  | 'Photo'
  | 'Art'
  | 'Films'
  | 'Nature'
  | 'Animals'
  | 'Travel'
  | 'Sport'
  | 'Transportation';
export const INTERESTS_OPTIONS: Interests[] = [
  'Animals', 'Art', 'Films',  'Music', 'Nature',  'Photo', 'Sport', 'Travel',
];

export type NewInterests = { value: string; icon: string };

export const NEW_INTERESTS: NewInterests[] = [
  { value: 'Animals', icon: 'animals-icon' }, { value: 'Art', icon: 'art-icon' }, { value: 'Films', icon: 'films-icon' },
  { value: 'Music', icon: 'music-icon' }, { value: 'Nature', icon: 'nature-icon' }, { value: 'Photo', icon: 'photo-icon' },
  { value: 'Sport', icon: 'sport-icon' }, { value: 'Travel', icon: 'travel-icon' } , { value: 'Cars', icon: 'transportation-icon' }
];


export type Pets = 'Bird' | 'Cat' | 'Dog' | 'Hamster' | 'Rabbit' | 'Like pets but don\'t have any';
export const PETS_OPTIONS: Pets[] = [
  'Bird', 'Cat', 'Dog', 'Hamster', 'Rabbit',
];

export type Smoking = 'Socially/occasionally' | 'Regularly' | 'Trying to quit' | 'Never';
export const SMOKING_OPTIONS: Smoking[] = [
  'Socially/occasionally', 'Regularly', 'Trying to quit', 'Never'
];

export type Drinking = 'Socially/occasionally' | 'Few times a week' | 'Most days' | 'Never';
export const DRINKING_OPTIONS: Drinking[] = [
  'Socially/occasionally','Few times a week',  'Most days', 'Never'
];

export type Religion =
  'Catholic'
  | 'Anglican'
  | 'Christian'
  | 'Jewish'
  | 'Islamic'
  | 'Buddhist'
  | 'Hindu'
  | 'Non-religious'
  | 'Other';
export const RELIGION_OPTIONS: Religion[] = [
  'Anglican', 'Buddhist',  'Catholic', 'Christian', 'Hindu', 'Islamic', 'Jewish', 'Non-religious', 'Other'
];

export const BLOCK_OPTIONS = [
  'spam', 'censor', 'inadequate', 'fake user', 'scam', 'rude'
];

