import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { NotificationComponent } from '../../core/modules/notification/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class AppNotifierService {
  config: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom'
  };

  constructor(private snackBar: MatSnackBar) {}

  success(message: string) {
    return this.snackBar.openFromComponent(NotificationComponent, {
      ...this.config,
      data: {
        message,
        status: 'success'
      },
      panelClass: 'success'
    });
  }

  error(message: string) {
    return this.snackBar.openFromComponent(NotificationComponent, {
      ...this.config,
      data: {
        message,
        status: 'error'
      },
      panelClass: 'error'
    });
  }

  info(message: string) {
    return this.snackBar.openFromComponent(NotificationComponent, {
      ...this.config,
      data: {
        message,
        status: 'info'
      },
      panelClass: 'info'
    });
  }
}
