import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { withCache } from '@ngneat/cashew';

@Injectable()
export class SecureImageService {
  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer
  ) { }

  /** Caching photo item on 10 minutes with trusted URL*/
  loadPhoto(photoUrl: string): Observable<SafeUrl> {
    return this.http.get(photoUrl, {
        responseType: 'blob',
        ...withCache({
          ttl$: 600000,
          cache$: true,
          key$: photoUrl.split('/').pop()
      })
    }).pipe(map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data))));
  }

  /** Caching photo item on 10 minutes without trusted URL*/
  loadPhotoLazy(photoUrl: string): Observable<string> {
    return this.http.get(photoUrl, {
      responseType: 'blob',
      ...withCache({
        ttl$: 600000,
        cache$: true,
        key$: `${photoUrl.split('/').pop()}_full`
      })
    }).pipe(map(data => URL.createObjectURL(data)));
  }

  /** Caching photo item on 10 minutes with compressed photo quality*/
  loadCompressPhoto(photoUrl: string): Observable<any> {
    return this.http.get(photoUrl+'?quality=low', {
      responseType: 'blob',
      ...withCache({
        ttl$: 600000,
        cache$: true,
        key$: photoUrl.split('/').pop()
      })
    }).pipe(map(data => URL.createObjectURL(data)));
  }
}

// switchMap(data => this.imageCompress.compressFile(URL.createObjectURL(data), 1, 100, 100, 200, 200)) //NgxImageCompressService
