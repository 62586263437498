import { Component, ChangeDetectionStrategy, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-loader-spinner',
  templateUrl: './loader-spinner.component.html',
  styleUrls: ['./loader-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderSpinner {
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'sm';
  @Input() colorMode: 'dark' | 'white' | 'primary' = 'dark';
}
