import { lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { STYLES as CROPPER_STYLES } from '@alyle/ui/image-cropper';

export interface ICropperConfig {
  width: number;
  height: number;
  autoCrop: boolean;
  roundCropper: boolean;
  fill: string;
  type: string;
  minSize: number;
  maxSize: number;
  cropperMaxWidth?: number;
  maintainAspectRatio: boolean;
  containWithinAspectRatio: boolean;
}

export const cropperStyle = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(CROPPER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);
  return {
    cropper: lyl`{
      max-width: 300px
      height: 250px
    }`,
    sliderContainer: lyl`{
      text-align: center
      max-width: 300px
      margin: 14px
      border-radius: 50%
    }`,
    cropArea: lyl`{
      ${cropper.area}, ${cropper.area}::before {
        border: unset
        border-radius: 50%
      }
    }`,
  };
};

export const avatarCropConfig: ICropperConfig = {
  autoCrop: false, // Default `false`
  width: 400,
  height: 400,
  fill: '#56a6e5', // Default transparent if type = png else #000
  type: 'image/png', // Or you can also use `image/jpeg`,
  minSize: 7 * 1000 * 10, // The minimum size of the photo is 70kb
  maxSize: 5 * 1000 * 1000, // The maximum size of the photo is 5Mb
  // cropperMaxWidth: 2000, // width 200px
  roundCropper: true,
  maintainAspectRatio: true,
  containWithinAspectRatio: true,
};

export const photoCropConfig: ICropperConfig = {
  ...avatarCropConfig,
  width: 560,
  height: 400,
  maxSize: 10 * 1000 * 1000, // The maximum size of the photo is 10Mb
  cropperMaxWidth: 2000,
  roundCropper: false,
  maintainAspectRatio: false,
  containWithinAspectRatio: false,
  autoCrop: true,
};

export const wallpaperCropConfig: ICropperConfig = {
  ...avatarCropConfig,
  width: 992,
  height: 323,
  // cropperMaxWidth: 4000,
  roundCropper: false,
  maxSize: 15 * 1000 * 1000, // The maximum size of the photo is 15Mb
};
