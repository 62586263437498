import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.scss']
})
export class MatCarouselSlideComponent {
  @Input() public overlayColor = '#EBF7FD';
  @Input() public hideOverlay = false;
  @Input() public disabled = false;

  @ViewChild(TemplateRef) public templateRef: TemplateRef<any>;

  constructor(public sanitizer: DomSanitizer) {}
}
