import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(country, searchCriteria): any {
    if (!searchCriteria || searchCriteria === '') {
      return true;
    }
    return `${country.name}+${country.dialCode}`.toLowerCase().includes(searchCriteria.toLowerCase());
  }
}
