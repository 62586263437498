<div class="base-page">
  <div class="base-header" routerLink="/" [replaceUrl]="true">
    <mat-icon svgIcon="internal:left" (click)="goHome()"></mat-icon>
    <div class="logo">
      <div class="logo-png"></div>
      <span> BurningHearts </span>
    </div>
  </div>

  <section class="page-content">
    <router-outlet></router-outlet>
  </section>
</div>
