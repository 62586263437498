<!-- <ng-container *ngIf="(dataUrl$ | async) as dataUrl; else withoutStream">
  <img [src]="dataUrl" [ngStyle]="useStyle" alt="">
</ng-container> -->

<ng-container *ngIf="(dataUrl$ | async) as dataUrl; else withoutStream">
  <img class="lazy-load" [lazyLoad]="dataUrl" [ngStyle]="useStyle" appLazyLoad [alt]="'photo'" width="100" height="100" />
</ng-container>

<ng-template #withoutStream>
  <ion-skeleton-text animated [ngStyle]="useStyle" style="width: 100%; height: 100%;"></ion-skeleton-text>
</ng-template>
