import { Action, createReducer, on } from '@ngrx/store';
import * as UsersActions from './user.actions';
import { IUserModel, initialUserState } from './user.state';

const reducer = createReducer(
  initialUserState,
  on(UsersActions.updateUser, (state, { user }) => ({ ...state, ...user })),
  on(UsersActions.resetUser, () => ({ ...initialUserState })),
  on(UsersActions.updatedAt, (state, { updatedAt }) => ({ ...state, updatedAt })),
  on(UsersActions.updateProfilePhoto, (
    state, { profilePhoto }) => ({ ...state, profilePhoto })
  ),
  on(UsersActions.updateProfileCoverPhoto, (
    state, { profileCoverPhoto }) => ({ ...state, profileCoverPhoto })
  ),
  on(UsersActions.updatePaymentHistory, (state, { history }) => ({ ...state, paymentHistory: history })),
  on(UsersActions.updateVirtualPurchaseHistory, (state, { history }) => ({ ...state, virtualPurchaseHistory: history })),
  on(UsersActions.updateUserSubscription, (state, { subscription }) => ({ ...state, subscription })),
  on(UsersActions.updateIncognito, (state, { incognitoStatus }) => ({ ...state, incognitoStatus })),
  on(UsersActions.updateUserFavourites, (state, { favourites }) => ({ ...state, favourites })),
  on(UsersActions.updateUserBlockeds, (state, { blockeds }) => ({ ...state, blockeds })),
  on(UsersActions.updateUserSuggestions, (state, { suggestions }) => ({ ...state, suggestions })),
  on(UsersActions.loadProfileVerificationSuccess, (state, { verified }) => ({ ...state, verified })),
  on(UsersActions.updateProfileVerificationPersonLink, (state, { personLink }) => ({
    ...state, verified: { ...state.verified, personLink }
  })),
  on(UsersActions.updateUserStatus, (state, { isOnline }) => ({ ...state, isOnline }))
);

export const userReducer = (state: IUserModel, action: Action) => reducer(state, action);
