/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CountdownComponent } from 'ngx-countdown';
import {
  selectUser,
  selectUserSubscription,
  selectUserVerification,
  selectUserVerified,
} from '../../../store/user/user.selectors';
import { ISubscription } from '../../../store/user/user.state';
import { IProfileVerification } from '../../../store/profile/profile.state';
import { IAppState } from '../../../store/app.state';
import {
  appNotifierError,
  appNotifierInfo,
  appNotifierSuccess,
} from '../../../store/app-notifier/app-notifier.actions';
import { selectSummaryNotification } from '../../../store/notification/notification.selectors';
import {
  updateUser,
  updateIncognito,
  resetUser,
  toggleUserStatus,
} from '../../../store/user/user.actions';
import { UserService } from '../../../store/user/user.service';
import { ActiveUserService } from '../../services';
import { NewPhotoUploaderComponent } from '../new-photo-uploader/new-photo-uploader.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() isSidebar = true;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  isLogoutLoading = false;

  countdownTimeUnits: Array<[string, number]> = [
    ['Y', 1000 * 60 * 60 * 24 * 365], // years
    ['M', 1000 * 60 * 60 * 24 * 30], // months
    ['D', 1000 * 60 * 60 * 24], // days
    ['H', 1000 * 60 * 60], // hours
    ['m', 1000 * 60], // minutes
    ['s', 1000], // seconds
    ['S', 1], // million seconds
  ];

  visitors = 0;
  likes = 0;
  messages = 0;
  favourites = 0;
  gifts = 0;
  invites = 0;
  other = 0;
  addPhoto = 0;

  showCover = false;
  showDelCover = false;
  destroyed$ = new ReplaySubject(1);
  user;
  loggedIn;
  showVerify: boolean;
  incognitoTime: number;
  started = false;
  subscription: ISubscription;
  startDateIncognito: Date;
  endDateIncognito: Date;

  menuItems = [
    {
      title: 'Activity',
      routerLink: ['/my-profile/activity'],
      svgIcon: 'internal:activity-icon',
      count: this.total,
      w: 22,
      h: 22,
    },
    {
      title: 'Visitors',
      routerLink: ['/my-profile/visitors'],
      svgIcon: 'internal:visitors-icon',
      count: this.visitors,
      w: 22,
      h: 15,
    },
    {
      title: 'Virtual gifts',
      routerLink: ['/my-profile/gifts'],
      svgIcon: 'internal:gift-image',
      count: this.gifts,
      w: 22,
      h: 19,
    },
    {
      title: 'Photo gallery',
      routerLink: ['/my-profile/photos'],
      svgIcon: 'internal:photos-icon',
      w: 22,
      h: 19,
    },
    {
      title: 'Video gallery',
      routerLink: ['/my-profile/videos'],
      svgIcon: 'internal:videos-icon',
      w: 19,
      h: 17,
    },
    {
      title: 'Likes',
      routerLink: ['/my-profile/likes'],
      svgIcon: 'internal:icon-sm-like',
      count: this.likes,
      w: 22,
      h: 20,
    },
    {
      title: 'Wallet',
      routerLink: ['/my-profile/wallet'],
      svgIcon: 'internal:wallet-icon',
      w: 20,
      h: 15,
    },
  ];

  config;
  verificationWithPhoto$: Observable<IProfileVerification> = this.store
    .select(selectUserVerification)
    .pipe();

  get total() {
    return (
      this.visitors +
      this.likes +
      this.messages +
      this.gifts +
      this.invites +
      this.favourites +
      this.addPhoto +
      this.other
    );
  }

  get adminAccess() {
    return this.user?.subscription?.adminAccess;
  }

  get isOnline() {
    return this.user.isOnline;
  }

  constructor(
    private store: Store<IAppState>,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private activeUser: ActiveUserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.listenUser();
    this.listenNotifications();
  }

  listenUser() {
    this.store
      .select(selectUser)
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroyed$)
      )
      .subscribe((user) => {
        this.user = user;
        if (this.user?.subscription) {
          this.config = {
            leftTime: this.user?.subscription.incognitoMin * 60,
            demand: !user.incognitoStatus,
            notify: [120],
            format: 'HH:mm:ss',
            formatDate: ({ date, formatStr }) => {
              let duration = Number(date || 0);
              return this.countdownTimeUnits.reduce((current, [name, unit]) => {
                if (current.indexOf(name) !== -1) {
                  const v = Math.floor(duration / unit);
                  duration -= v * unit;
                  return current.replace(
                    new RegExp(`${name}+`, 'g'),
                    (match: string) => v.toString().padStart(match.length, '0')
                  );
                }
                return current;
              }, formatStr);
            },
          };
        }
        if (this.user.incognitoStatus && this.user.incognitoHistory !== null) {
          const time =
            this.user.subscription.incognitoMin * 60 * 1000 -
            (new Date().getTime() -
              new Date(this.user.incognitoHistory.startDate).getTime());
          this.config.leftTime = time / 1000;
        }
        this.cdr.markForCheck();
      });

    this.store
      .select(selectUserVerified)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((verified) => {
        this.showVerify = verified;
        this.cdr.markForCheck();
      });

    this.store
      .select(selectUserSubscription)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subscription) => {
        this.subscription = subscription;
        this.cdr.markForCheck();
      });

    this.cdr.detectChanges();
  }

  listenNotifications() {
    this.store
      .select(selectSummaryNotification)
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroyed$)
      )
      .subscribe((summary) => {
        this.visitors = summary.visitors;
        this.likes = summary.likedPhoto;
        this.messages = summary.messages;
        this.gifts = summary.gifts;
        this.favourites = summary.favourites;
        this.invites = summary.invites;
        this.addPhoto = summary.addPhoto;
        this.other = summary.other;
        this.menuItems = this.menuItems.map((item) => {
          switch (item.title) {
            case 'Activity':
              return Object.assign(item, { item }, { count: this.total });
            case 'Visitors':
              return Object.assign(item, { item }, { count: this.visitors });
            case 'Likes':
              return Object.assign(item, { item }, { count: this.likes });
            case 'Virtual gifts':
              return Object.assign(item, { item }, { count: this.gifts });
            default:
              return item;
          }
        });
        this.cdr.markForCheck();
      });
  }

  changePhoto() {
    this.dialog.open<NewPhotoUploaderComponent>(NewPhotoUploaderComponent, {
      panelClass: 'new-photo-uploader',
      autoFocus: false,
    });
  }

  pause() {
    this.countdown.pause();
    this.toggleIncognito(false);
    this.store.dispatch(
      updateUser({
        user: Object.assign({}, { ...this.user }, { incognitoHistory: null }),
      })
    );
    this.cdr.detectChanges();
  }

  start() {
    if (this.subscription?.incognitoMin) {
      if (this.started) {
        this.countdown.begin();
      } else {
        this.countdown.resume();
      }
      this.toggleIncognito(true);
      this.store.dispatch(updateIncognito({ incognitoStatus: true }));
    }
  }

  handleEvent(event: any) {
    if (event.action === 'done' && this.user.incognitoStatus) {
      this.toggleIncognito(false);
      this.store.dispatch(appNotifierInfo('Invisible time was expired'));
    }
  }

  toggleIncognito(status) {
    this.userService
      .changeIncognito(
        status,
        null,
        new Date(),
        this.adminAccess
          ? this.subscription?.incognitoMin
          : this.countdown.left / 60 / 1000
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  changeVisible(incognitoStatus: boolean) {
    if (!incognitoStatus && this.adminAccess) {
      this.toggleIncognito(true);
    } else if (incognitoStatus && this.adminAccess) {
      this.toggleIncognito(false);
    } else if (!incognitoStatus && this.subscription?.incognitoMin) {
      this.countdown.begin();
      this.toggleIncognito(true);
      this.store.dispatch(updateIncognito({ incognitoStatus: true }));
    } else {
      this.countdown.pause();
      this.toggleIncognito(false);
      this.store.dispatch(
        updateUser({
          user: Object.assign({}, { ...this.user }),
        })
      );
    }
    this.cdr.detectChanges();
  }

  toggleStatus() {
    this.store.dispatch(toggleUserStatus());
  }

  logOut() {
    this.isLogoutLoading = true;

    this.activeUser
      .logout()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.isLogoutLoading = false;
          this.router.navigate(['/'], { replaceUrl: true }).then(() => {
            this.store.dispatch(appNotifierSuccess('Logout successfully'));
            this.store.dispatch(resetUser());
          });
        },
        (error) => {
          this.isLogoutLoading = false;
          this.router.navigate(['/'], { replaceUrl: true }).then(() => {
            this.store.dispatch(appNotifierError(error.message));
            this.store.dispatch(resetUser());
          });
        }
      );
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
