import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MaterialSharedModule } from '../material-shared.module';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    NotificationComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MaterialSharedModule,
    FlexModule
  ],
})
export class NotificationModule { }
