import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapboxPlacesTypes } from '../interfaces/mapbox-places-types';

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  constructor(
    private httpClient: HttpClient
  ) {}

  /** MAPBOX geocoding*/
  forwardGeocoding(searchTerm: string, dataType: MapboxPlacesTypes, countryCode: string = null): Observable<any> {
    let url = `${ environment.mapbox.url }${ searchTerm }.json?types=${ dataType }`;
    if (countryCode) {
      url = `${ url }&country=${ countryCode }`;
    }
    url = `${ url }&language=en`;
    url = `${ url }&autocomplete=true`;
    url = `${ url }&access_token=${ environment.mapbox.accessToken }`;
    return this.httpClient.get(url).pipe(map((res: any) => res.features));
  }

  reverseGeocoding(longitude: number, latitude: number) {
    let url = environment.mapbox.url;
    url = `${ url }${ longitude },${ latitude }.json`;
    url = `${ url }?language=en`;
    url = `${ url }&types=place`;
    url = `${ url }&access_token=${ environment.mapbox.accessToken }`;
    return this.httpClient.get(url).pipe(map((res: any) => res.features));
  }

  reverseGeocodingPhoton(longitude: number, latitude: number) {
    // photon.komoot.de/reverse?lon=4.639&lat=51.268
    return this.httpClient.get(`https://photon.komoot.de/reverse?lon=${longitude}&lat=${latitude}`);
  }

  /** IPData geocoding */
  ipToGeo() {
    // const { url, accessToken, fields } = environment.ipData;
    // const reqUrl = `${url}?${accessToken}&${fields}`;
    // return this.httpClient.get(reqUrl);
    return this.httpClient.get('/api/geocoding/current');
  }
}
