import { createAction, props } from '@ngrx/store';

export const uploadMessages = createAction(
  '[Messages] Upload messages',
);

export const deleteMessages = createAction(
  '[Messages] Delete selected message',
  props<{ id: string }>()
);
