<div mat-dialog-content>
  <div class="content">
    <div class="error-icon">
    </div>
    <div class="title">
      {{data.title}}
    </div>
    <div class="data-text">
      {{data.text}}
    </div>
    <button class="primary-round-button" mat-dialog-close (click)="goLink()">
      {{data.buttonText}}
    </button>
  </div>
</div>
