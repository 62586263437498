import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { AnimationController, Gesture, Animation, GestureController } from '@ionic/angular';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appSwaper]'
})
export class SwaperDirective implements AfterViewInit {
  @Input() component: HTMLDivElement;
  @Input() item: HTMLDivElement;
  @Input() background: HTMLDivElement;
  @Output() appSwaper = new EventEmitter();
  swipeNotify: Gesture;
  //touchstart$;
  pointerdown$;
  pointerup$;

  constructor(
    private hostElement: ElementRef<HTMLElement>,
    private gestureCtrl: GestureController,
    private animCtrl: AnimationController
  ) { }

  ngAfterViewInit(): void {
    this.pointerdown$ = fromEvent(this.hostElement.nativeElement, 'pointerdown');
    this.pointerup$ = fromEvent(this.hostElement.nativeElement, 'pointerup');

    this.pointerdown$.pipe().subscribe(_ => {
      this.swipe(this.item, this.component, this.background);
    });

    this.pointerup$.pipe().subscribe(_ => {
      if(this.swipeNotify){
        this.swipeNotify.destroy();
      };
    });
  }

  swipe(item: HTMLDivElement, element: HTMLDivElement, bckg: HTMLDivElement){
    const winWidth = window.innerWidth;
    const style = element.style;

    const deleteAnimation: Animation = this.animCtrl.create()
      .addElement(item)
      .duration(300)
      .easing('ease-out')
      .fromTo('opacity', '1', '0')
      .fromTo('height', '80px', '0px')
      .fromTo('marginBottom', '20px', '0px')
      .fromTo('border', '1px', '0px')
      .onFinish(e => {
        item.style.display = 'none';
        item.remove();
        this.appSwaper.emit();
      });

    this.swipeNotify = this.gestureCtrl.create({
      el: element,
      gestureName: 'swipeX',
      direction: 'x',
      disableScroll: true,
      onStart: ev => {
        style.transition = '';
        bckg.style.opacity = '1';
      },
      onMove: ev => {
        if (ev.deltaX > 0) {
          style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
          style.boxShadow='rgb(0 0 0 / 12%) 0px 4px 16px';
          if (ev.deltaX > 100) {
            bckg.classList.add('swiped');
            bckg.style.transform = `translate3d(${ev.deltaX-100}px, 0, 0)`;
          } else {
            bckg.classList.remove('swiped');
            bckg.style.transform = `translate3d(0, 0, 0)`;
          }
        } else if(ev.deltaX < 0) {
          style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
          style.boxShadow='rgb(0 0 0 / 12%) 0px 4px 16px';
          if(ev.deltaX < -100){
            bckg.classList.add('swiped');
            bckg.style.transform = `translate3d(${ev.deltaX+100}px, 0, 0)`;
          } else {
            bckg.classList.remove('swiped');
            bckg.style.transform = `translate3d(0, 0, 0)`;
          }
        }
      },
      onEnd: ev => {
        item.style.transition = '.1s linear';
        bckg.style.opacity = '0';
        if (ev.deltaX > 100) {
          bckg.childNodes.forEach((e: HTMLDivElement) => e.style.opacity = '0');
          item.style.transform = `translate3d(${winWidth/2}px, 0, 0)`;
          deleteAnimation.play();
        } else if(ev.deltaX < -100){
          bckg.childNodes.forEach((e: HTMLDivElement) => e.style.opacity = '0');
          item.style.transform = `translate3d(-${winWidth/2}px, 0, 0)`;
          deleteAnimation.play();
        } else {
          style.transition = '.1s linear';
          style.transform = `translate3d(0, 0, 0)`;
          style.boxShadow='none';
        }
      }
    });
    this.swipeNotify.enable(true);
  }
}
