<section fxLayout="row" fxLayoutAlign="center center" class="mb-24">
  <div class="content-media-uploader">
    <mat-icon
      [svgIcon]="isPhoto ? 'internal:photos-icon' : 'internal:videos-icon'"
      class="plus-v"
      aria-hidden="false"
    ></mat-icon>

    <span>{{ isPhoto ? "Add photo maximum 10Mb" : "Add video maximum 30 sec" }}</span>
  </div>
</section>
