import { createAction } from '@ngrx/store';

export const appNotifierSuccess = createAction(
  '[App-Notifier] Success notification',
  (msg: string = 'Operation success') => ({ msg })
);

export const appNotifierError = createAction(
  '[App-Notifier] Error notification',
  (msg: string = 'Internal server error') => ({ msg })
);

export const appNotifierInfo = createAction(
  '[App-Notifier] Info notification',
  (msg: string = 'Information notification') => ({ msg })
);
