<div class="form-inline form-inline-country">
  <label class="label"> <span style="margin-right: 9px;"><i  class="edit-icon3 icon-eico3 edit-icon"></i></span>{{ title }} </label>
  <div class="jqselect jq-selectbox dropdown">
    <input
      type="text"
      class="jq-selectbox__select"
      [(ngModel)]="selectedAddress"
      (ngModelChange)="changeAddress($event)"
      [disabled]="isDisabled"
      [placeholder]="'Select ' + title"
    />
    <div
      *ngIf="addresses.length > 0"
      class="jq-selectbox__dropdown dropdown-container"
    >
      <ul class="dropdown__ul">
        <li *ngFor="let address of addresses" (click)="onSelect(address)">
          {{ address.placeName }}
        </li>
      </ul>
    </div>
  </div>

</div>
