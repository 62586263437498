<ion-header [translucent]="true">
  <div class="app-header" [ngStyle]="useStyle" *ngIf="!title; else isDialog">
    <div class="menu-section">
      <ion-menu-button *ngIf="!onlyLogo; else backBtn">
      <!-- TODdO refactor hardcoded colors  -->
        <mat-icon
          (click)="openSideMenu()"
          [style.color]="isSideMenuOpened ? '#2b8cfc' : '#333'"
          svgIcon="internal:menu"
        ></mat-icon>
      </ion-menu-button>
    </div>
    <app-logo
      style="width: 100%"
      size="sm"
      [weidth]="500"
      [isOnlyLogo]="onlyLogo"
      [isSideMenuOpened]="isSideMenuOpened"
    ></app-logo>
    <div class="bell-icon">
      <mat-icon
        [style.opacity]="onlyLogo ? 0 : 1"
        svgIcon="internal:bell-icon"
        [routerLink]="['/my-profile/activity']"
      ></mat-icon>
      <span
        [style.opacity]="onlyLogo ? 0 : 1"
        *ngIf="total"
        class="mat-badge-content"
      ></span>
    </div>
  </div>
</ion-header>

<ng-template #isDialog>
  <div class="app-header" [ngStyle]="useStyle">
    <span class="btn-back" mat-dialog-close>
      <mat-icon svgIcon="internal:left"></mat-icon>
    </span>
    <span class="title">{{ title }}</span>
    <mat-icon
      svgIcon="internal:bell-icon"
      [routerLink]="['/my-profile/activity']"
      (click)="dialog.closeAll()"
    ></mat-icon>
    <span *ngIf="total" class="mat-badge-content"></span>
  </div>
</ng-template>

<ng-template #backBtn>
  <span class="btn-back" (click)="goBack()">
    <mat-icon svgIcon="internal:left"></mat-icon>
  </span>
</ng-template>
