import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { Gesture, GestureController } from '@ionic/angular';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements AfterViewInit {
  swipe: Gesture;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { status: 'error' | 'success' | 'info'; message: string },
    public snackBarRef: MatSnackBarRef<NotificationComponent>,
    private gestureCtrl: GestureController
  ) { }

  ngAfterViewInit() {
    const element = document.querySelector('.mat-snack-bar-container') as HTMLElement;
    const style = element.style;

    this.swipe = this.gestureCtrl.create({
      el: element,
      gestureName: 'swipeNotification',
      threshold: 0,
      direction: 'x',
      blurOnStart: true,
      disableScroll: true,
      onStart: ev => {
        style.transition = '';
      },
      onMove: ev => {
        if(ev.deltaX < 0) {
          style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
        }
      },
      onEnd: ev => {
        if(ev.deltaX < -100){
          style.display = 'none';
          this.snackBarRef.dismiss();
        } else {
          style.transition = '.1s linear';
          style.transform = `translate3d(0, 0, 0)`;
        }
      }
    });
    this.swipe.enable(true);
  }
}
