<div class="notification-container" fxLayout="row" fxLayoutAlign="start start">
  <div
    fxLayout="row"
    fxLayoutAlign="flex-start center"
    fxLayoutGap="8px"
    fxFlexFill
  >
    <div class="notification-icon">
      <ng-container [ngSwitch]="data.status">
        <ng-container *ngSwitchCase="'success'">
          <mat-icon svgIcon="notification:check"></mat-icon>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
          <mat-icon svgIcon="notification:cancel"></mat-icon>
        </ng-container>

        <ng-container *ngSwitchCase="'info'">
          <mat-icon
            style="height: 100px; width: 54px; fill: #ffcf62"
            svgIcon="internal:info-material"
          ></mat-icon>
        </ng-container>
      </ng-container>
    </div>

    <section fxLayout="column">
      <div class="title" *ngIf="data.status !== 'info'">
        {{ data.status | titlecase }}
      </div>
      <div class="description">{{ data.message }}</div>
    </section>
  </div>
</div>
