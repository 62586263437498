/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  AfterViewInit, Component,
  ContentChildren, EventEmitter,
  Input, OnDestroy, Output,
  QueryList
} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatCarouselSlideComponent } from './carousel-slide/carousel-slide.component';
import { Router } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { PhotoModel } from 'src/app/shared/models/photo.model';
import { VideoModel } from 'src/app/shared/models/video.model';
import { selectProfile } from 'src/app/store/profile/profile.selectors';
import { IAppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';

interface InputData {
  current: SafeUrl;
  userId: string;
  mediaIdx: number;
  mediaScope: PhotoModel[] | VideoModel[];
  type: 'photo' | 'video';
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class MatCarouselComponent implements AfterViewInit, OnDestroy {
  canLike: boolean;
  user$ = this.store.select(selectProfile);
  photoStyle = {
    width: '100%',
    height: '100%',
    'object-fit': 'cover',
    'border-radius': '10px'
  };

  @ContentChildren(MatCarouselSlideComponent) public slidesList: QueryList<MatCarouselSlideComponent>;

  @Output() pauseVideo = new EventEmitter();
  @Output() like = new EventEmitter();
  @Input() media: InputData;
  @Input() public set slides(value: number) { this.slides$.next(value); }
  @Input() public maintainAspectRatio = false;
  @Input() public proportion = 25;
  @Input() public slideHeight = '100%';
  @Input() setActiveSlide: number;
  @Input() slidesLoop = false;

  public get slideOpt() { return { initialSlide: this.setActiveSlide, loop: this.slidesLoop }; };
  private slides$ = new BehaviorSubject<number>(null);
  private destroyed$ = new Subject<never>();

  constructor(
    private router: Router,
    private store: Store<IAppState>
  ) {
    this.canLike = [
      '/my-profile/photos',
      '/my-profile/videos',
      '/my-profile/photo-on-confirmation'
    ].includes(this.router.url);
  }

  getFrame(event: Event) {
    const video = event.target as HTMLVideoElement;
    video.currentTime = video.duration * 0.01;
  }

  public ngAfterViewInit(): void {
    this.slides$
      .pipe(
        takeUntil(this.destroyed$),
        filter(value => value && value < this.slidesList.length)
      ).subscribe(value => this.resetSlides(value));
  }

  private resetSlides(slides: number): void {
    this.slidesList.reset(this.slidesList.toArray().slice(0, slides));
  }

  slideChange(event) {
    const gallery = document.querySelector('.mini-galerry');
    const x = gallery.scrollWidth / event.el.swiper.imagesLoaded * event.el.swiper.realIndex;
    gallery.scroll(x, 0);

    // document.querySelector('.photo-item-active')
    //   .scrollIntoView({
    //     inline: this.setActiveSlide > event.el.swiper.realIndex ? 'end' : 'start'
    //   });

    this.setActiveSlide = event.el.swiper.realIndex;
    this.pauseVideo.emit();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}


