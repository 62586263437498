import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() size: 'sm' | 's' | 'm' | 'l' = 's';
  @Input() weidth = 600;
  @Input() isOnlyLogo = true;
  @Input() isSideMenuOpened = false;

  constructor(public router: Router){}

  get iconSize(): number {
    switch (this.size) {
      case 'sm':
        return 30;
      case 's':
        return 43;
      case 'm':
        return 56;
      case 'l':
        return 60;
    }
  }

  get textSize(): number {
    switch (this.size) {
      case 'sm':
        return 16;
      case 's':
        return 18;
      case 'm':
        return 23;
      case 'l':
        return 27;
    }
  }
}
