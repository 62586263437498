/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActiveUserService } from '../../shared/services';
import { IAppState } from '../app.state';
import { IProfile } from './profile.state';
import { updateProfile } from './profile.actions';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private httpClient: HttpClient,
    private activeUserService: ActiveUserService,
    private store: Store<IAppState>
  ) {}

  get myID() {
    return this.activeUserService.user._id;
  }

  getMyProfile() {
    return this.httpClient.get(`/api/profiles/${ this.activeUserService.user._id }`).pipe(
      tap((profile: IProfile) => {
        this.store.dispatch(updateProfile({ profile }));
      })
    );
  }

  getProfile(id: string) {
    return this.httpClient.get<IProfile>(`/api/profiles/${ id }`);
  }

  changeEmail(updatedEmail: string, password: string) {
    return this.httpClient.post('/api/change-email', { updatedEmail, password });
  }

  verifyEmailCode(code: string) {
    return this.httpClient.get(`/api/change-email/${code}`);
  }

  blockProfile(id: string, declined?: true, reason?: string) {
    return this.httpClient.post(`/api/profiles/${ id }/block`, { declined, reason });
  }

  acceptFavorite(notification) {
    return this.httpClient.post(`/api/profiles/${ notification.user._id }/accepted`, { type: notification.type });
  }

  declineFavorite(notification) {
    return this.httpClient.post(`/api/profiles/${ notification.user._id }/decline`, null);
  }
}

