<ion-content>
  <div
    class="full-page-wrapper new-main"
    id="full-page-scroll"
    #scrollerElement
  >
    <section id="first">
      <header #header class="animated fadeInDown" id="new-header">
        <div class="main-page-container">
          <div class="logo pointer">
            <app-logo size="sm"></app-logo>
          </div>
          <div class="login">
            <button
              *ngIf="user._id === null"
              class="login-button"
              (click)="goLogin()"
            >
              Log in
            </button>
            <button
              *ngIf="user._id !== null"
              class="profile-button"
              (click)="goProfile()"
            >
              Go to Profile
            </button>
          </div>
        </div>
      </header>

      <!-- [style.background-image]="setBG(data['sectionfirst']?.featuredImage)" -->
      <div
        class="section-background lazy"
        data-bg="app/assets/img/main-first-bg-fin.webp"
      ></div>
      <div class="main-page-container">
        <div class="first-content">
          <!-- *ngIf="data['sectionfirst']; else sectionFirst" [innerHTML]="data['sectionfirst'].html" -->
          <div style="width: 100%" #sectionfirst>
            <ng-container *ngTemplateOutlet="sectionFirst"></ng-container>
          </div>
          <!--  -->
          <div class="registration">
            <button class="primary-round-button" (click)="goRegister()">
              Registration
            </button>
          </div>
        </div>
      </div>
    </section>

    <section id="second" class="section-bg">
      <div class="main-page-container">
        <ng-container *ngTemplateOutlet="sectionSecond"></ng-container>
        <!--  -->
        <!-- <div style="width: 100%;" *ngIf="data['sectionsecond']; else sectionSecond" [innerHTML]="data['sectionsecond'].html"></div> -->
        <!--  -->
      </div>
    </section>

    <!-- [style.background-image]="setBG(data['sectionthird']?.featuredImage)" -->
    <section
      id="third"
      class="section-bg lazy"
      data-bg="app/assets/img/new-third-bg.webp"
    >
      <div class="main-page-container">
        <div class="third-content">
          <ng-container *ngTemplateOutlet="sectionThird"></ng-container>
          <!--  -->
          <!-- <div style="width: 100%;" *ngIf="data['sectionthird']; else sectionThird" [innerHTML]="data['sectionthird'].html"></div> -->
          <!--  -->
          <div class="discover">
            <button class="primary-round-button" (click)="goRegister()">
              Discover now
            </button>
          </div>
        </div>
      </div>
    </section>

    <section id="fourth" class="section-bg">
      <div class="main-page-container">
        <ng-container *ngTemplateOutlet="sectionFourth"></ng-container>
        <!--  -->
        <!-- <div style="width: 100%;" *ngIf="data['sectionfourth']; else sectionFourth" [innerHTML]="data['sectionfourth'].html"></div> -->
        <!--  -->
      </div>
    </section>

    <!-- [style.background-image]="setBG(data['sectionfifth']?.featuredImage)" -->
    <section
      id="fifth"
      class="section-bg lazy"
      data-bg="app/assets/img/new-fifth-bg.webp"
    >
      <div class="main-page-container">
        <ng-container *ngTemplateOutlet="sectionFifth"></ng-container>
        <!--  -->
        <!-- <div
        style="width: 100%;
          display: flex;
          justify-content: flex-end;"
        *ngIf="data['sectionfifth']; else sectionFifth" [innerHTML]="data['sectionfifth'].html">
      </div> -->
        <!--  -->
      </div>
    </section>

    <section id="sixth">
      <div class="main-page-container">
        <ng-container *ngTemplateOutlet="sectionSixth"></ng-container>
        <!--  -->
        <!-- <div
        style="width: 100%;"
        *ngIf="data['sectionsixth']; else sectionSixth" [innerHTML]="data['sectionsixth'].html">
      </div> -->
        <!-- <mat-icon class="quotation-mark" svgIcon="internal:quotes" aria-hidden="false"></mat-icon> -->
        <!--  -->
      </div>
    </section>

    <section id="seventh">
      <div class="main-page-container">
        <ng-container *ngTemplateOutlet="sectionSeventh"></ng-container>
        <!--  -->
        <!-- <div
        style="width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;"
        *ngIf="data['sectionseventh']; else sectionSeventh" [innerHTML]="data['sectionseventh'].html">
      </div> -->
        <!--  -->
      </div>
    </section>

    <!-- [style.background-image]="setBG(data['sectioneight']?.featuredImage)" -->
    <section
      id="eight"
      class="section-bg lazy"
      data-bg="app/assets/img/new-eight-bg.webp"
    >
      <div class="main-page-container">
        <div class="eight-content">
          <ng-container *ngTemplateOutlet="sectionEight"></ng-container>
          <!--  -->
          <!-- <div
          style="width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;"
          *ngIf="data['sectioneight']; else sectionEight" [innerHTML]="data['sectioneight'].html">
        </div> -->
          <!--  -->
          <div class="reg">
            <button class="primary-round-button" (click)="goRegister()">
              Registration
            </button>
          </div>
        </div>
      </div>
    </section>

    <div id="footer">
      <div
        class="main-page-container"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="footer-menu">
          <ul>
            <li>
              <a [replaceUrl]="true" routerLink="/page/terms-and-conditions"
                >Terms & Conditions</a
              >
            </li>
            <li>
              <a [replaceUrl]="true" routerLink="/page/privacy-policy"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a [replaceUrl]="true" routerLink="/page/cookies-policy"
                >Cookie Policy</a
              >
            </li>
            <li><a [replaceUrl]="true" routerLink="/page/about">About</a></li>
            <li>
              <a [replaceUrl]="true" routerLink="/page/contact">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="logo-text pointer" routerLink="/">
          BurningHearts - <span>All rights reserved</span>
        </div>
        <div #header class="animated fadeInDown" id="new-header-footer">
          <div class="main-page-container">
            <div
              class="logo pointer footer__logo-wrapper"
              (click)="scrollToTop(scrollerElement)"
            >
              <app-logo size="sm" class="footer__logo-container"></app-logo>
              <mat-icon
                svgIcon="internal:arrow-upward"
                class="math-icon-arrow-upward"
              ></mat-icon>
            </div>
            <!-- <div class="login">
              <button class="login-button" (click)="goLogin()">Log in</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<!-- NG templates -->
<ng-template #sectionFirst>
  <div class="title">Choose your soul mate</div>
  <div class="text">
    That you would <br />
    understand each other
  </div>
</ng-template>

<ng-template #sectionSecond>
  <div class="description-list">
    <div class="item">
      <div class="item-image wish-image"></div>
      <div class="item-title">Big experience</div>
      <div class="item-text">
        We have excellent experience how to bring <br />
        happiness and success to people since 1997.
      </div>
    </div>
    <div class="item">
      <div class="item-image happiness-image"></div>
      <div class="item-title">Many people</div>
      <div class="item-text">
        Thanks to us there is now a long list <br />
        of happy women and men.
      </div>
    </div>
    <div class="item">
      <div class="item-image success-image"></div>
      <div class="item-title">Extensive geography</div>
      <div class="item-text">
        With our website you can meet peoplefrom <br />
        different countries and continents.
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sectionThird>
  <div class="title title-30">BurningHearts</div>
  <div class="text">
    The managing of BurningHearts, the premium worldwide international dating
    company, has excellent experience how to bring happiness and success to
    people since 1997. With our unique opportunities we proudly offer you
    splendid high-quality services. Through our elite self dating possibility we
    have a long list of happy women and men.
  </div>
  <div class="text">
    We are ranked among the top online international private dating world
    organizations in the United States of America, Canada, West Europe,
    Australia, New Zeeland and Japan.
  </div>
</ng-template>

<ng-template #sectionFourth>
  <div class="fourth-content">
    <div class="title title-30">
      Are you ready for <br />
      your new life?
    </div>
    <div class="text">
      You probably know from first-hand experience that it is generally
      difficult to find genuine contacts in the modern world. It’s also a fact
      that 20% of meetings between women and men start on the internet! Our
      website helps you to open a new chapter in your life! Our unique dating
      opportunities can bring you happiness in just a few months.
    </div>
    <div class="text">
      What do you need to do? Complete your registration on our brand new
      website. Do not hesitate. Just do it. We are sure it will change your life
      in the way you’re hoping. So join now and very soon you will find your
      happiness and success! Reach out to your special someone with
      BurningHearts! <a routerLink="/registration">Register now!</a>
    </div>
  </div>
</ng-template>

<ng-template #sectionFifth>
  <div class="fifth-content">
    <div class="title title-30">
      Use our website <br />
      on all devices
    </div>
    <div class="text">
      We care about your convenience. <br />
      With a comfortable design for mobile <br />
      and tablets you can communicate <br />
      and meet everywhere and always. <br />
    </div>
  </div>
</ng-template>

<ng-template #sectionSixth>
  <div class="sixth-content">
    <div class="heart-image"></div>
    <div class="big-title">
      Being happy is when <br />
      your desires are fulfilled.
    </div>
    <mat-icon
      class="quotation-mark"
      svgIcon="internal:quotes"
      aria-hidden="false"
    ></mat-icon>
  </div>
</ng-template>

<ng-template #sectionSeventh>
  <div class="seventh-content">
    <div class="steps-main-page">
      <div class="steps-item">
        <div class="image monitor-image"></div>
        <div class="steps-item-box">
          <div class="number-step">Step 1.</div>
          <div class="title-step">Registration</div>
          <div class="description-step">
            Create your profile in <br />
            a few minutes.
          </div>
        </div>
      </div>
      <div class="steps-item">
        <div class="image search-image"></div>
        <div class="steps-item-box">
          <div class="number-step">Step 2.</div>
          <div class="title-step">Watch photos and videos</div>
          <div class="description-step">
            Find your preferences easy <br />
            on our client base.
          </div>
        </div>
      </div>
      <div class="steps-item">
        <div class="image communications-image"></div>
        <div class="steps-item-box">
          <div class="number-step">Step 3.</div>
          <div class="title-step">Communications</div>
          <div class="description-step">
            Send messages to communicate <br />
            with interested members.
          </div>
        </div>
      </div>
      <div class="steps-item">
        <div class="image gift-image"></div>
        <div class="steps-item-box">
          <div class="number-step">Step 4.</div>
          <div class="title-step">Virtual gifts</div>
          <div class="description-step">
            Send or receive virtual gifts to <br />
            maximize your happiness.
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sectionEight>
  <div class="title">Singles now online</div>
  <div class="text">
    Complete your registration and <br />
    look who is now online to contact <br />
    your interesting choices. <br />
  </div>
</ng-template>
