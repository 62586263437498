/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
} from 'ngx-image-cropper';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { Store } from '@ngrx/store';
import { avatarCropConfig, ICropperConfig } from '../cropper.config';
import { DualSpinnerService } from '../dual-spinner/dual-spinner.service';
import { IAppState } from '../../../store/app.state';
import { appNotifierError } from '../../../store/app-notifier/app-notifier.actions';

@Component({
  selector: 'app-image-cropper-wrapper',
  templateUrl: './image-cropper-wrapper.component.html',
  styleUrls: ['./image-cropper-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperWrapperComponent implements AfterViewInit {
  @ViewChild('cropper', { static: true }) cropper: ImageCropperComponent;
  @ViewChild('spinnedTemp') spinnedTemplate: ElementRef<HTMLElement>;

  @Input() isSaveLoading = false;
  @Input() cropConfig: ICropperConfig = avatarCropConfig;
  @Input() photoUrl = '';

  @Output() onSave = new EventEmitter<Blob>();

  imageChangedEvent: any = '';
  scale = 1;
  rotation = 0;
  minScale = 0.0001;
  maxScale = 4;
  errorMsg: string;
  croppedImage: any = '';
  cropperReady = false;
  transform: ImageTransform = {};

  constructor(
    private dualSpinnerService: DualSpinnerService,
    private store: Store<IAppState>
  ) {}

  ngAfterViewInit(): void {
    if (!!this.photoUrl) {
      this.dualSpinnerService.startComponentLoading(this.spinnedTemplate);
    }
  }

  fileChangeEvent(event: Event): void {
    this.dualSpinnerService.startComponentLoading(this.spinnedTemplate);
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  zoomIn() {
    if (this.scale <= this.maxScale) {
      this.scale += 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  zoomOut() {
    if (this.scale > this.minScale) {
      this.scale -= 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale,
      };
    }
  }

  rotateLeft() {
    this.rotation -= 90;
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  rotateRight() {
    this.rotation += 90;
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  scaleChanged(event: MatSliderChange) {
    this.scale = event.value;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.transform = {};
    this.cropper.resetCropperPosition();
  }

  imageLoadFalse() {
    this.cropperReady = false;
    this.imageChangedEvent = '';
    this.croppedImage = '';
  }

  imageLoaded(e) {
    const size = base64ToFile(e.original.base64).size;
    if (
      e.original.size.width < this.cropConfig.width ||
      e.original.size.height < this.cropConfig.height
    ) {
      this.imageLoadFalse();
      this.errorMsg = `The minimum size of the photo is ${
        this.cropConfig.width }x${ this.cropConfig.height
      }px. Please upload another photo.`;
    }
    else if (size <= this.cropConfig.minSize) {
      this.imageLoadFalse();
      this.errorMsg = `The minimum size of the photo is ${
        this.cropConfig.minSize / 10 ** 3
      }Kb. Please upload another photo.`;
    } else if (size >= this.cropConfig.maxSize) {
      this.imageLoadFalse();
      this.errorMsg = `The maximum size of the photo is ${
        this.cropConfig.maxSize / 10 ** 6
      }Mb. Please upload another photo.`;
    } else {
      this.cropperReady = true;
      this.errorMsg = null;
    }
  }

  onCropperReady() {
    this.dualSpinnerService.finishComponentLoading(this.spinnedTemplate);
    this.resetImage();
  }

  loadImageFailed() {
    this.store.dispatch(appNotifierError('Image is not a valid'));
    this.dualSpinnerService.finishComponentLoading(this.spinnedTemplate);
    this.cropperReady = false;
  }

  onSavePhoto() {
    this.cropper.crop();
    this.onSave.emit(base64ToFile(this.croppedImage));
  }
}
