<div fxLayout="column">
  <div class="dialog-title" fxLayoutAlign="center center">
    <div
      [style.background-color]="data.params?.activeColor"
      class="color-icon-container"
    >
      <div class="icon-container">
        <mat-icon
          [svgIcon]="data.params.icon"
          [style.width.px]="data.params.w"
          [style.height.px]="data.params.h"
          class="coins-icon"
          aria-hidden="false"
        ></mat-icon>
      </div>
    </div>
  </div>

  <div mat-dialog-content fxLayout="column" fxLayoutGap="40px">
    <mat-label class="mat-dialog-content-title">
      {{data.state ? ' Want to buy ' + data.title + '?' : data.title }}
    </mat-label>

    <p [style.color]="darkColor"
      [style.background-color]="data.params?.activeColor"
    > {{ data.subtitle }} for {{ data.amount }} {{ data.currency }} </p>

    <div
      *ngIf="!data.state"
      [style.color]="darkColor"
      [style.background-color]="data.params?.activeColor"
    > {{ data.description }} </div>
  </div>

  <div mat-dialog-actions fxLayout="column" fxLayoutGap="10px">
    <button
      *ngIf="data.state"
      [mat-dialog-close]="true"
      class="primary-round-button"
    > Continue </button>

    <button
      mat-button mat-dialog-close
      color="primary"
      class="secondary-button"
    >
    {{ data.state ? 'No, thanks' : 'Close' }}
    </button>
  </div>
</div>

<app-close-dialog-button [useStyle]="styles" mat-dialog-close></app-close-dialog-button>
