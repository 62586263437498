import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/app.state';
import { selectUser } from '../../store/user/user.selectors';
import { filter } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InfoValidComponent } from '../modals/info-valid/info-valid.component';
import { NewPhotoUploaderComponent } from '../components/new-photo-uploader/new-photo-uploader.component';

@Injectable()
export class ValidProfileGuard implements CanActivate {
  valid = false;
  visible: boolean;
  inActive: boolean;
  profilePhoto: boolean;
  verified: boolean;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store<IAppState>
  ) {
    this.store.select(selectUser)
      .pipe(
        filter(v => !!v),
      )
      .subscribe(
        user => {
          // eslint-disable-next-line no-underscore-dangle
          if (!user._id) {
            this.valid = true;
          } else {
            this.valid = user.valid;
            this.visible = user.visible;
            this.profilePhoto = !!user.profilePhoto;
            this.verified = user.showVerify;
            this.inActive = user.inActive;
          }
        },
        // eslint-disable-next-line no-console
        () => console.error('There was an error loading the user')
      );
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.valid && this.visible && this.profilePhoto && !this.verified && !this.inActive) {
      return true;
    }
    if (!this.valid) {
      this.dialog.open(InfoValidComponent, {
        hasBackdrop: true,
        maxWidth: '90vw',
        data: {
          title: 'Profile not complete',
          text: 'You must complete your profile first before you can access the rest of the site',
          buttonText: 'Let\'s go complete your profile',
          linkRedirect: '/my-profile/edit'
        }
      }).afterClosed().subscribe(() => {
        this.router.navigate(['my-profile', 'edit']);
      });
    } else if (!this.profilePhoto) {
      this.dialog.open(InfoValidComponent, {
        hasBackdrop: true,
        maxWidth: '90vw',
        data: {
          title: 'Profile not complete',
          text: 'Please upload an avatar photo and at least one photo on your personal page',
          buttonText: 'Let\'s go upload',
          linkRedirect: '/my-profile/edit/settings'
        }
      }).afterClosed().subscribe(() => {
        this.dialog.open(NewPhotoUploaderComponent, { panelClass: 'new-photo-uploader', autoFocus: false });
      });
    } else if (this.verified) {
      this.dialog.open(InfoValidComponent, {
        hasBackdrop: true,
        maxWidth: '90vw',
        data: {
          title: 'Your person not verified',
          text: 'You must verified your profile first before you can access the rest of the site',
          buttonText: 'Let\'s go verified',
          linkRedirect: '/my-profile/edit/settings'
        }
      }).afterClosed().subscribe(() => {
        this.router.navigate(['my-profile', 'edit', 'settings']);
      });
    } else if (!this.visible) {
      this.dialog.open(InfoValidComponent, {
        hasBackdrop: true,
        maxWidth: '90vw',
        data: {
          title: 'Your profile inactive',
          text: 'Hide profile from search and disable messaging. This option will not cancel your membership',
          buttonText: 'OK',
          linkRedirect: '/my-profile/edit/settings'
        }
      }).afterClosed().subscribe(() => {
        this.router.navigate(['my-profile', 'edit', 'settings']);
      });
    } else if (this.inActive) {
      this.dialog.open(InfoValidComponent, {
        hasBackdrop: true,
        maxWidth: '90vw',
        data: {
          title: 'Your profile paused',
          text: 'Hide profile from search and disable messaging. You can reactivate it in you settings',
          buttonText: 'OK',
          linkRedirect: '/my-profile/edit/settings'
        }
      }).afterClosed().subscribe(() => {
        this.router.navigate(['my-profile', 'edit', 'settings']);
      });
    }
    return false;
  }
}
