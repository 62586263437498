import { Component, Input, OnInit } from '@angular/core';
import { allData} from './allCountries';
import { parsePhone, phoneNumberValidator } from './mat-tel-input-validator';

@Component({
  selector: 'app-mat-tel-input',
  templateUrl: './mat-tel-input.component.html',
  styleUrls: ['./mat-tel-input.component.scss']
})
export class MatTelInputComponent implements OnInit {
  @Input() form;
  @Input() enableSearch = false;
  @Input() searchPlaceholder = 'Search ...';
  @Input() inputPlaceholder;
  @Input() cssClass;
  @Input() enablePlaceholder = true;
  @Input() preferredCountries = [];
  @Input() onlyCountries = [];
  @Input() preferredCountriesInDropDown = [];
  phoneNumber: string;
  selectedCountry;
  searchCriteria;
  allCountries = [];

  constructor() {
    this.fetchCountryData();
  }

  get formatedPhone(): string {
    return `+${this.selectedCountry.dialCode}`;
  }

  ngOnInit() {
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach(iso2 => {
          const preferredCountry = this.allCountries.filter((c) => c.iso2 === iso2).shift();
          this.preferredCountriesInDropDown.push(preferredCountry);
      });
    }
    if (this.onlyCountries.length) {
        this.allCountries = this.allCountries.filter(c => this.onlyCountries.includes(c.iso2));
        this.selectedCountry = this.allCountries[0];
        this.phoneNumber = this.formatedPhone;
    } else if (this.form.get('phone').value && this.preferredCountries.length) {
      this.phoneNumber = this.form.get('phone').value;
      this.selectedCountry = this.allCountries.find(c => c.iso2 === this.preferredCountries[0]);
    } else {
      if (this.preferredCountriesInDropDown.length) {
          this.selectedCountry = this.preferredCountriesInDropDown[0];
          this.phoneNumber = this.formatedPhone;
      } else {
          this.selectedCountry = this.allCountries[0];
          this.phoneNumber = this.formatedPhone;
      }
    }
  }

  fetchCountryData() {
    allData.forEach(c => {
        const country = {
            name: c[0].toString(),
            iso2: c[1].toString(),
            dialCode: c[2].toString(),
            priority: +c[3] || 0,
            areaCodes: c[4] || undefined,
            flagClass: c[1].toString().toUpperCase(),
            placeHolder: ''
        };
        if (this.enablePlaceholder) {
            country.placeHolder = country.iso2.toUpperCase();
        }
        this.allCountries.push(country);
    });
  }

  onPhoneNumberChange() {
    if(this.phoneNumber && this.phoneNumber[0] !== '+') {
      this.phoneNumber = '+' + this.phoneNumber;
    }
    try {
      const numberInstance = parsePhone(this.phoneNumber.toString(), this.selectedCountry.iso2.toUpperCase());
      if (numberInstance && numberInstance.isValid()) {
        if (this.selectedCountry.iso2 !== numberInstance.country) {
          this.selectedCountry = this.getCountry(numberInstance.country);
        }
      }
    } catch (e) {
      console.log(e);
    }

    this.form.get('phone').setValue(this.phoneNumber);
    phoneNumberValidator(this.form.get('phone'));
  }

  onCountrySelect(country) {
    this.selectedCountry = country;
    this.phoneNumber = this.formatedPhone;
    this.onPhoneNumberChange();
  }

  getCountry(code) {
    return this.allCountries.find(c => c.iso2 === code.toLowerCase()) || {
        name: 'UN',
        iso2: 'UN',
        dialCode: undefined,
        priority: 0,
        areaCodes: undefined,
        flagClass: 'UN',
        placeHolder: ''
    };
  }

  onInputKeyPress(event) {
    const pattern = /[0-9+\- ]/;
    if (!pattern.test(event.key)) {
        event.preventDefault();
    }
  }
}
