import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appHoverEffect]'
})
export class HoverEffectDirective {
  @HostBinding('style.opacity') opacity: string;

  @HostListener('mouseenter')
  onHover() {
    this.opacity = '0.7';
  }

  @HostListener('mouseleave')
  onLeave() {
    this.opacity = null;
  }

}
