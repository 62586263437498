import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IRadioButtonOption } from '../../models/radio-button-group';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],

  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonGroupStandaloneComponent {
  @Input() radioGroupDataObjects: IRadioButtonOption[]; // receives an array of data objects
  @Output() dataObjectSelected: EventEmitter<IRadioButtonOption> =
    new EventEmitter<IRadioButtonOption>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    // prevent view and controller mismatch after close RadioButtonGroupStandaloneComponent in one parrent and open it in the second parrent component
    if (this.radioGroupDataObjects?.length > 1) {
      this.setSelectedState(this.radioGroupDataObjects[0]?.radioButtonId);
    }
  }

  setSelectedState(selectedDataObjectId: string): void {
    this.radioGroupDataObjects.forEach((radioGroupDataObject: IRadioButtonOption) => {
      radioGroupDataObject.selected = false;
      if (selectedDataObjectId === radioGroupDataObject.radioButtonId) {
        radioGroupDataObject.selected = true;
      }
    });

    this.cdr.detectChanges();
  }

  onDataObjectSelected(selectedDataObject: IRadioButtonOption): void {
    this.setSelectedState(selectedDataObject.radioButtonId);
    // @warn remove console logs
    // console.log('on data object selected > selectedDataObject', selectedDataObject, '\n\n');
    // console.log('on data object selected > (input) radio group data objects', this.radioGroupDataObjects, '\n\n\n\n');
    this.dataObjectSelected.emit(selectedDataObject);
  }
}
