<mat-card
  class="mat-elevation-z0 gift-card mb-16"
  *ngIf="gift"
  [class.canSend]="canSend && directionLabel === 'from'"
>
  <div class="image-gif mr-16">
    <app-secure-image
      [src]="gift?.image"
      [useStyle]="{
        'object-fit': 'contain',
        'padding': '0',
        'width.px': 60,
        'height.px': 60
      }">
    </app-secure-image>
  </div>

  <mat-card-content class="mt-4">
    <h4>{{ gift?.title }}</h4>
    <ng-container *ngIf="directionLabel">
      <h6 class="mt-8 mb-4">{{ directionLabel }}
        <a [routerLink]="directionLink">{{ directionName }}</a>
      </h6>
    </ng-container>
  </mat-card-content>

  <mat-card-footer *ngIf="canSend">
    <div class="icon-activity">
      <mat-icon
        (click)="sendGift()"
        class="icon-sm-gift"
        svgIcon="internal:icon-sm-gift"
      ></mat-icon>
    </div>
  </mat-card-footer>
</mat-card>
