import { Action, createReducer, on } from '@ngrx/store';
import { initialProfileState, IProfile } from './profile.state';
import {
  updateProfile,
  updateProfilePhoto,
  updateProfileVerification,
  updateProfileVerificationPersonLink
} from './profile.actions';

const reducer = createReducer(
  initialProfileState,
  on(updateProfile, (state, { profile }) => ({ ...profile })),
  on(updateProfileVerification, (state, { verification }) => ({ ...state, verified: verification })),
  on(updateProfileVerificationPersonLink, (state, { personLink }) => ({
    ...state, verified: { ...state.verified, personLink }
  })),
  on(updateProfilePhoto, (state, { profilePhoto }) => ({ ...state, profilePhoto }))
);

export const profileReducer = (state: IProfile, action: Action) => reducer(state, action);
